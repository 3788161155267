/* Generated from Java with JSweet 3.0.0-SNAPSHOT - http://www.jsweet.org */
import { Range } from './Range'

// namespace com.dicomlab.jdent.data {
export class MinRange {
  /* private */ value = 0

  /* private */ dataMin = 0

  /* private */ dataMax = 0

  /* private */ moving = false

  public constructor(value?: any, datamin?: any, datamax?: any, moving?: any) {
    if (
      (typeof value === 'number' || value === null) &&
      (typeof datamin === 'number' || datamin === null) &&
      (typeof datamax === 'number' || datamax === null) &&
      (typeof moving === 'boolean' || moving === null)
    ) {
      ;(() => {
        this.dataMin = datamin
        this.dataMax = datamax
        this.setValue(value)
        this.setMoving(moving)
      })()
    } else if (
      ((value != null && value instanceof MinRange) || value === null) &&
      datamin === undefined &&
      datamax === undefined &&
      moving === undefined
    ) {
      ;(() => {
        this.set$com_dicomlab_jdent_data_MinRange(value)
      })()
    } else if (
      ((value != null && value instanceof Range) || value === null) &&
      datamin === undefined &&
      datamax === undefined &&
      moving === undefined
    ) {
      ;(() => {
        this.set$com_dicomlab_jdent_data_Range(value)
      })()
    } else if (value === undefined && datamin === undefined && datamax === undefined && moving === undefined) {
      ;(() => {
        this.dataMin = 0.0
        this.dataMax = 100.0
        this.value = 30.0
        this.moving = false
      })()
    } else {
      throw new Error('invalid overload')
    }
  }

  public getValue(): number | undefined {
    return this.value
  }

  public setValue(value: number) {
    if (this.dataMin && this.dataMax) {
      this.value = Math.min(Math.max(this.dataMin, value), this.dataMax)
    } else {
      this.value = value
    }
  }

  public getDataMin(): number | undefined {
    return this.dataMin
  }

  public getDataMax(): number | undefined {
    return this.dataMax
  }

  /**
   * @return {boolean} the moving
   */
  public getMoving(): boolean {
    return this.moving || false
  }

  /**
   * @param {boolean} moving
   * the moving to set
   */
  public setMoving(moving: boolean) {
    this.moving = moving
  }

  public set$com_dicomlab_jdent_data_MinRange(r: MinRange) {
    this.set$float$float$float$boolean(r.value, r.dataMin, r.dataMax, r.moving)
  }

  public set$float$float$float$boolean(value: number, datamin: number, datamax: number, moving: boolean) {
    this.value = value
    this.dataMin = datamin
    this.dataMax = datamax
    this.moving = moving
  }

  public set(value?: any, datamin?: any, datamax?: any, moving?: any): any {
    if (
      (typeof value === 'number' || value === null) &&
      (typeof datamin === 'number' || datamin === null) &&
      (typeof datamax === 'number' || datamax === null) &&
      (typeof moving === 'boolean' || moving === null)
    ) {
      return <any>this.set$float$float$float$boolean(value, datamin, datamax, moving)
    }
    if (
      ((value != null && value instanceof MinRange) || value === null) &&
      datamin === undefined &&
      datamax === undefined &&
      moving === undefined
    ) {
      return <any>this.set$com_dicomlab_jdent_data_MinRange(value)
    }
    if (
      ((value != null && value instanceof Range) || value === null) &&
      datamin === undefined &&
      datamax === undefined &&
      moving === undefined
    ) {
      return <any>this.set$com_dicomlab_jdent_data_Range(value)
    }
    throw new Error('invalid overload')
  }

  /**
   *
   * @param {*} obj
   * @return {boolean}
   */
  public equals(obj: any): boolean {
    const o: MinRange = <MinRange>obj
    return (
      this.value === o.value && this.dataMin === o.dataMin && this.dataMax === o.dataMax && this.moving === o.moving
    )
  }

  /**
   *
   * @return {string}
   */
  public toString(): string {
    return `( ${this.value} ) ---- ( Data min ${this.dataMin} Data max ${this.dataMax})`
  }

  public set$com_dicomlab_jdent_data_Range(r: Range) {
    this.set$float$float$float$boolean(r.getMin(), r.getDataMin(), r.getDataMax(), r.getMoving())
  }
}
//  MinRange["__class"] = "com.dicomlab.jdent.data.MinRange";

// }
