import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Slider from '@mui/material/Slider'
import * as M from 'models'
import * as A from 'actions'

function ConfigureISOLevel() {
  const volume = useSelector((state: M.AppState) => state.case.volume)
  const dispatch = useDispatch()

  const setISO = (_event: any, newValue: number | number[]) => {
    const val = newValue
    if (typeof val === 'number') {
      dispatch(A.Objects.setVolumeISOLevel(true, val))
    }
  }

  const onMouseUp = (_event: any) => {
    // console.log('ConfigureISOLevel keyUp ' + volume.mRange.value);
    dispatch(A.Objects.setVolumeISOLevel(false, volume ? volume.mRange.value : 0))
  }
  if (!volume || !volume.range) return null
  return (
    <Slider
      valueLabelDisplay='off'
      aria-labelledby='range-slider'
      min={volume.range.min}
      max={volume.range.min + (volume.range.max - volume.range.min) / 2}
      value={volume.mRange.value}
      onChange={setISO}
      onMouseUp={onMouseUp}
    />
  )
}

export default ConfigureISOLevel
