export const notEmpty = (value: string) => {
  return typeof value !== 'undefined' && value.length ? null : 'messages.not-empty'
}

export const notEmptyString = (value: string) => {
  return typeof value === 'string' && value.length > 0
}

export const validEmail = (value: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

export const verifyText = (value: string) => {
  return Boolean(value && typeof value === 'string' && value.length >= 3)
}

export const verifyCode = (value: string) => {
  return value.length ? /^[a-zA-Z0-9_-]+$/.test(value) && value.length >= 3 && value.length < 20 : true
}

export const verifyTimestamp = (value: any) => {
  return Boolean(value && typeof value === 'object' && value.seconds)
}

export const verifyDateString = (value: string) => {
  if (value && typeof value === 'string' && value.length === 10) {
    return value.match('[1-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]')
  }
  return false
}

// function that returns true if value is email, false otherwise
export const verifyUKPostal = (value: string) => {
  const UKPostalRex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i

  return UKPostalRex.test(value)
}

// function that returns true if value can be a firstname lastname, false otherwise
export const verifySimpleFullName = (value: string) => {
  const nameRex = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/

  return value && typeof value === 'string' && value.length >= 3 && nameRex.test(value)
}

// function that returns true if value can be a first or last name, false otherwise
export const verifyNamePart = (value: string) => {
  const nameRex = /^[a-zA-Z\s]+$/

  return value && typeof value === 'string' && value.length >= 2 && nameRex.test(value)
}

// function that returns true if value is phone, false otherwise
export const verifyPhone = (value: string) => {
  const phoneRex = /^[ 0-9+-]+$/
  // /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
  return value && typeof value === 'string' && value.length >= 10 && phoneRex.test(value)
}

// function that returns true if value is email, false otherwise
export const verifyEmail = (value: string) => {
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}

// function that verifies if a string has a given length or not
export const verifyLength = (value: string, length: number) => {
  if (value?.length >= length) {
    return true
  }
  return false
}

export const verifyPassword = (value: string) => {
  const numeric = /[0-9]/
  const letter = /[a-z]/
  const capital = /[A-Z]/
  return (
    typeof value === 'string' &&
    value.length >= 8 &&
    value.match(numeric) &&
    value.match(letter) &&
    value.match(capital)
  )
}

export const verifyOTC = (value: string) => {
  return typeof value === 'string' && value.match(/^[A-Z]{1,6}-[0-9]{1,6}-[A-Z]{4}-[0-9]{4}-[0-9]{1,4}$/)
}

const countryVatRegexes: Record<string, RegExp> = {
  aut: new RegExp('^((AT)?U[0-9]{8})$'),
  bel: new RegExp('^((BE)?0[0-9]{9})$'),
  bgr: new RegExp('^((BG)?[0-9]{9,10})$'),
  hrv: new RegExp('^((HR)?[0-9]{11})$'),
  cyp: new RegExp('^((CY)?[0-9]{8}[A-Z])$'),
  cze: new RegExp('^((CZ)?[0-9]{8,10})$'),
  deu: new RegExp('^((DE)?[0-9]{9})$'),
  dnk: new RegExp('^((DK)?[0-9]{8})$'),
  est: new RegExp('^((EE)?[0-9]{9})$'),
  grc: new RegExp('^((EL)?[0-9]{9})$'),
  esp: new RegExp('^(ES[A-Z][0-9]{7}(?:[0-9]|[A-Z]))$'),
  fin: new RegExp('^((FI)?[0-9]{8})$'),
  fra: new RegExp('^((FR)?[0-9A-Z]{2}[0-9]{9})$'),
  gbr: new RegExp('^((GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3}))$'),
  hun: new RegExp('^((HU)?[0-9]{8})$'),
  irl: new RegExp('^((IE)?[0-9]{7}[A-Z]{1,2}|(IE)?[0-9][A-Z][0-9]{5}[A-Z])$'),
  ita: new RegExp('^((IT)?[0-9]{11})$'),
  ltu: new RegExp('^((LT)?([0-9]{9}|[0-9]{12}))$'),
  lux: new RegExp('^((LU)?[0-9]{8})$'),
  lva: new RegExp('^((LV)?[0-9]{11})$'),
  mlt: new RegExp('^((MT)?[0-9]{8})$'),
  nld: new RegExp('^((NL)?[0-9]{9}B[0-9]{2})$'),
  plw: new RegExp('^((PL)?[0-9]{10})$'),
  prt: new RegExp('^((PT)?[0-9]{9})$'),
  rou: new RegExp('^((RO)?[0-9]{2,10})$'),
  swe: new RegExp('^((SE)?[0-9]{12})$'),
  svn: new RegExp('^((SI)?[0-9]{8})$'),
  svk: new RegExp('^((SK)?[0-9]{10})$'),
}

// function that returns true if value is valid vat number and at least 6 char length or not
export const verifyVatNumber = (value: string, countryCode?: string) => {
  const vatRex = new RegExp(
    '^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(HR)?[0-9]{11}|(CY)?[0-9]{8}[A-Z]|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL)?[0-9]{9}|ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]{7}[A-Z]{1,2}|(IE)?[0-9][A-Z][0-9]{5}[A-Z]|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$'
  )

  if (countryCode && Object.keys(countryVatRegexes).includes(countryCode)) {
    return value && typeof value === 'string' && value.length >= 6 && countryVatRegexes[countryCode].test(value)
  }

  return value && typeof value === 'string' && value.length >= 6 && vatRex.test(value)
}

// function that verifies if value contains only numbers
export const verifyNumber = (value: string) => {
  const numberRex = new RegExp('^[0-9]+$')

  return numberRex.test(value)
}

// verifies if value is a valid URL
export const verifyUrl = (value: string) => {
  try {
    new URL(value)
    return true
  } catch (_) {
    return false
  }
}
