function abs(x: number) {
  // "use asm";
  x |= 0
  return x < (0 | 0) ? -x | 0 : x | 0
}

function backFilterPaeth(data, offset, w, _i, _j) {
  // "use asm";
  w |= 0
  offset |= 0
  let k = 1 | 0
  for (; k < w; k = (k + 1) | 0) {
    const raw = data[offset + k]
    const priorRow = data[offset - w + k]
    const priorPixel = data[(offset + k - 1) | 0]
    const priorRowPixel = data[(offset - w + k - 1) | 0]
    const p = priorPixel + priorRow - priorRowPixel
    const pa = abs(p - priorPixel) | 0
    const pb = abs(p - priorRow) | 0
    const pc = abs(p - priorRowPixel) | 0
    const v0 = pa <= pb && pa <= pc ? priorPixel : pb <= pc ? priorRow : priorRowPixel
    data[offset + k] = raw + v0
  }
}

function backFilterAverage(data, offset, w) {
  // "use asm";
  w |= 0
  offset |= 0
  let k = 1 | 0
  for (; k < w; k = (k + 1) | 0) {
    const raw = data[offset + k] | 0
    const priorRow = data[offset - w + k] | 0
    const priorPixel = data[(offset + k - 1) | 0] | 0
    data[offset + k] = raw + (((priorPixel + priorRow) / 2) | 0)
  }
}

function backFilterUp(data, offset, w) {
  // "use asm"
  w |= 0
  offset |= 0
  let k = 0 | 0
  for (; k < w; k = (k + 1) | 0) {
    const raw = data[offset + k] | 0
    const prior = data[offset - w + k] | 0
    data[offset + k] = raw + prior
  }
}

function backFilterSub(data, offset, w) {
  // "use asm"
  w |= 0
  offset |= 0
  let k = 1 | 0
  for (; k < w; k = (k + 1) | 0) {
    let val = data[offset + k] | 0
    val += data[(offset + k - 1) | 0] | 0
    data[offset + k] = val
  }
}

export { backFilterSub, backFilterUp, backFilterAverage, backFilterPaeth, abs }
