import * as THREE from 'three'
import { Range } from './Range'
import { MinRange } from './MinRange'

export function v3FromString(v: string): THREE.Vector3 {
  const v3 = v.split(' ').map((i) => Number(i))
  return new THREE.Vector3(v3[0], v3[1], v3[2])
}
export function v3ToString(v: THREE.Vector3): string {
  return `${v.x} ${v.y} ${v.z}`
}
export function colorToString(v: THREE.Color): string {
  return `${v.r * 255} ${v.g * 255} ${v.b * 255}`
}

export function colorFromString(v: string): THREE.Color {
  const v3 = v.split(' ').map((i) => Number(i))
  return new THREE.Color(v3[0] / 255.0, v3[1] / 255.0, v3[2] / 255.0)
}

export function m4FromString(v: string): THREE.Matrix4 {
  const rv = new THREE.Matrix4()
  v.split(' ').forEach((v, i) => (rv.elements[i] = Number(v)))
  rv.transpose()
  return rv
}

export function m4ToString(v: THREE.Matrix4): string {
  const v2 = new THREE.Matrix4().copy(v).transpose()
  return v2.elements.map((e) => String(e)).join(' ')
}

export function boolFromString(v: string, defaultVal?: boolean): boolean {
  if (v === undefined) return defaultVal ? defaultVal : false
  return v.toLowerCase() === 'true'
}
export function rangeFromXMLJS(v: any): Range {
  return new Range(
    Number(v.min[0]),
    Number(v.max[0]),
    Number(v.dataMin[0]),
    Number(v.dataMax[0]),
    boolFromString(v.moving[0])
  )
}
export function minRangeFromXMLJS(v: any): MinRange {
  return new MinRange(Number(v.value[0]), Number(v.dataMin[0]), Number(v.dataMax[0]), boolFromString(v.moving[0]))
}
