import moment from 'moment'
import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const customFormat = (value: string | Date, format: string): string => {
  if (value instanceof Date) {
    return moment(value).format(format)
  }

  if (format === 'uppercase') {
    return value.toUpperCase()
  }
  if (format === 'capital') {
    const words = value.split(' ')
    const capitalized = words.map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    return capitalized.join(' ')
  }
  return value
}

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: process.env.REACT_APP_I18N_DEBUG === 'true',
      lng: 'en',

      fallbackLng: 'en-DEVEL',
      interpolation: {
        escapeValue: false,
        format: customFormat,
      },
      supportedLngs: ['en-DEVEL', 'en', 'hu', 'it', 'de', 'fr'],
      saveMissing: false,
      crossDomain: true,
      backend: {
        loadPath: `${process.env.REACT_APP_NG_API}/v1/ppc/tr/sgjs/{{lng}}`,
        // addPath: `${process.env.REACT_APP_NG_API}/v1/ppc/tr/sgjs/{{lng}}`,
      },
      keySeparator: '/',
      react: {
        useSuspense: false,
      },

      requestOptions: {
        // used for fetch, can also be a function (payload) => ({ method: 'GET' })
        // mode: 'cors',
        // credentials: 'same-origin',
        cache: 'default',
      },
    } as InitOptions,
    (e, _t) => {
      if (!e) {
        // console.log('i18n up')
      } else {
        //console.error(e)
      }
    }
  )

i18n.on('languageChanged', (lng) => {
  moment.locale(lng)
})

export default i18n
