import { Pass, DCLDRenderPipeline, RenderTarget, ITextureInput } from 'renderer/Pipeline'
import { Vector2, Texture } from 'three'
import { VolumeRenderShader } from 'shaders/VolumeRenderShader'
import * as THREE from 'three'
import { DicomVolume } from 'models'

export class VolumeQuadDraw extends Pass {
  camera: THREE.OrthographicCamera

  geometry: THREE.PlaneBufferGeometry

  uniforms: any

  material: THREE.ShaderMaterial

  mesh: THREE.Mesh

  scene: THREE.Scene

  clearBackground: boolean

  volumeTexture: Texture

  environmentMapTexture: Texture

  inputMap: any

  output?: string

  public isoLevel?: number

  public volumeTextureSize: THREE.Vector3

  public screenSize?: THREE.Vector2

  public isoColor?: THREE.Color

  public cameraPosition?: THREE.Vector3

  public lightPosition?: THREE.Vector3

  public diffuse?: THREE.Vector4

  public specular?: THREE.Vector4

  public ambient?: THREE.Vector4

  public attenuation?: THREE.Vector3

  public shininess?: number

  public modelMatrix?: THREE.Matrix4

  public backToFrontDir?: THREE.Vector3

  public originalCamera?: THREE.OrthographicCamera

  public acceleratorTexture?: THREE.Texture

  public cbPrerender?: () => void

  public hasAccelerator: boolean

  public volumeDirScale: number
  public renderInHighRes: boolean
  constructor(
    volumeTexture: THREE.Texture,
    hasAccelerator: boolean,
    public volume: DicomVolume,
    volumeDirScale: number,
    volumeTextureSize: THREE.Vector3,
    environmentMapTexture: THREE.Texture,
    renderTarget?: string,

    cbPrerender?: () => void
  ) {
    super()
    this.volumeTextureSize = volumeTextureSize
    this.volumeDirScale = volumeDirScale
    this.hasAccelerator = hasAccelerator
    this.cbPrerender = cbPrerender
    this.volumeTexture = volumeTexture
    this.environmentMapTexture = environmentMapTexture
    this.renderInHighRes = true
    this.inputMap = {
      front_color: 'front_color',
      backDir_color: 'backDir_color',
      // front_depth: 'front_depth',
      shape_depth: 'shape_depth',
      shape_color: 'shape_color',
    }
    const quadSize = 1
    const quadPos = new Vector2(0, 0)
    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1)
    this.geometry = new THREE.PlaneBufferGeometry(2 * quadSize, 2 * quadSize)

    const shader = VolumeRenderShader
    this.uniforms = shader.uniforms
    this.clearBackground = true
    this.output = renderTarget
    let defines = {}

    if (!this.hasAccelerator) {
      defines = { NO_ACCELATOR_TEXTURE: 1 }
    } else if (this.volumeDirScale !== undefined) {
      defines = { HAS_ACCELERATOR_TEXTURE_SCALE: 1 }
      const roundUp = (x, y): number => {
        return Math.trunc((x + y - 1) / y) * y
      }
      const accelSize = new THREE.Vector3(
        roundUp(this.volumeTextureSize.x, this.volumeDirScale),
        roundUp(this.volumeTextureSize.y, this.volumeDirScale),
        roundUp(this.volumeTextureSize.z, this.volumeDirScale)
      )
      this.uniforms.uAcceleratorTextureScaleRatio.value = new THREE.Vector3(
        this.volumeTextureSize.x / accelSize.x,
        this.volumeTextureSize.y / accelSize.y,
        this.volumeTextureSize.z / accelSize.z
      )
    } else {
      defines = {}
    }
    if (volume.compressionMethod === 1) {
      defines['COMPRESSEDF16'] = 1
    }

    this.material = new THREE.ShaderMaterial({
      defines,
      uniforms: this.uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
      depthTest: false,
      depthWrite: false,
    })
    this.mesh = new THREE.Mesh(this.geometry, this.material)
    this.mesh.position.set((quadPos.x * 2 - 1 + quadSize) * 1, (quadPos.y * 2 - 1 + quadSize) * 1, 0)
    this.scene = new THREE.Scene()
    this.scene.add(this.mesh)
  }

  public render(pipeLine: DCLDRenderPipeline, input: ITextureInput, _target: RenderTarget): void {
    if (!this.volumeTexture) {
      return
    }
    if (!this.screenSize) {
      return
    }
    if (this.cbPrerender) {
      this.cbPrerender()
    }
    //        let invModelMatrix =  new THREE.Matrix4();
    //      invModelMatrix.getInverse(this.modelMatrix);

    for (const [k, v] of Object.entries({
      tFrontPos: { value: input.front_color },
      // TODO , backPoss_color nem kell?
      tDirVectors: { value: input.backDir_color },
      tShapeDepth: { value: input.shape_depth },
      tShapeColor: { value: input.shape_color },
      tVolumeTex: { value: this.volumeTexture },
      tEnvMap: { value: this.environmentMapTexture },
      isoLevel: { value: this.isoLevel },
      volumeTextureSize: { value: this.volumeTextureSize },
      ScreenSize: { value: this.screenSize },
      IsoColor: { value: this.isoColor },
      CameraPosition: { value: this.cameraPosition },
      LightPosition: { value: this.lightPosition },
      Diffuse: { value: this.diffuse },
      Specular: { value: this.specular },
      Ambient: { value: this.ambient },
      Attenuation: { value: this.attenuation },
      Shininess: { value: this.shininess },
      OModelMatrix: { value: this.modelMatrix },
      OViewMatrix: { value: this.originalCamera?.matrixWorldInverse },
      // "OProjectionMatrix": { value: this.originalCamera.projectionMatrix},
      uNear: { value: this.originalCamera?.near },
      uFarMinusNear: { value: this.originalCamera ? this.originalCamera.far - this.originalCamera.near : undefined },
      tAcceleratorTexture: { value: this.acceleratorTexture },
      BackToFrontDir: { value: this.backToFrontDir },
      uResolution: { value: this.renderInHighRes ? 0 : 1 },
      uResolutonPow: { value: this.renderInHighRes ? 1 : 2 },
    })) {
      this.uniforms[k] = v
    }
    if (this.volume.compressionMethod === 1) {
      this.uniforms.uFloatBase = { value: this.volume.floatBase }
      this.uniforms.uFloatDiv = { value: this.volume.floatDiv }
      this.uniforms.uTargetBase = { value: this.volume.targetBase }
    }
    //  this.uniforms['tDiffuse'].value = input.front_color;

    const { autoClearDepth } = pipeLine.renderer
    const { autoClearColor } = pipeLine.renderer
    pipeLine.setAutoClearProps(pipeLine.renderer.autoClear, true, false, pipeLine.renderer.autoClearStencil)
    pipeLine.renderer.render(this.scene, this.camera)
    pipeLine.setAutoClearProps(
      pipeLine.renderer.autoClear,
      autoClearColor,
      autoClearDepth,
      pipeLine.renderer.autoClearStencil
    )
  }
}
