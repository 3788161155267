import * as M from 'models'
import * as THREE from 'three'

const initialState: M.VisualConfig = {
  sliceMDColor: new THREE.Color(0xff0000),
  sliceOVColor: new THREE.Color(0x00ff00),
  sliceFRColor: new THREE.Color(0x0000ff),
  sliceScreenBGColor: new THREE.Color(0x000000),
  panoramaScreenBGColor: new THREE.Color(0x000000),
  sliceSelectedColor: new THREE.Color(0x78b4e2).multiplyScalar(1.0), //new THREE.Color(0xff00ff),
  implantCollider: new THREE.Color(0x8d825a),

  impressionColor: new THREE.Color(0x656353),
  antagonistIntersectionColor: new THREE.Color(0x635334),
  biteIntersectionColor: new THREE.Color(0x603f33),

  implantAxisColor: new THREE.Color(0x93ff47).multiplyScalar(0.7), // new THREE.Color(0x858585),
  sleeveColor: new THREE.Color(0xc36c3b),
  selectedImplantColor: new THREE.Color(0x93ff47).multiplyScalar(1.3), //new THREE.Color(0xcba6ea),
  selectedImplantAxisColor: new THREE.Color(0x93ff47).multiplyScalar(1.3), //new THREE.Color(0xbe47ff),
  hooveredImplantColor: new THREE.Color(0x93ff47), // 0x3AFFC0
  planWarningColor: new THREE.Color(0xff7f00), // 0x3AFFC0
  planErrorColor: new THREE.Color(0xff0000),
  sliceMarkerOnPanorama: 5,
}

export default (state = initialState, action: any): M.VisualConfig => {
  switch (action.type) {
    default:
      return state
  }
}
