/* eslint-disable react-redux/no-unused-prop-types */
import React from 'react'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(3),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '26px',
      lineHeight: '30px',
      letterSpacing: '-0.26px',
      color: 'rgba(88, 88, 88, 0.870588)',
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  const onClickTitle = (event) => {
    /* console.log('----------------');
        console.log('caseInfoTitleClick'); */
    // event.preventDefault();
    event.stopPropagation()
    return false
  }
  return (
    <MuiDialogTitle className={classes.root} {...other} onClick={onClickTitle}>
      <Typography variant='h4' component='span' className={props.classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={(event) => {
            event.stopPropagation()
            onClose()
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export interface IDialogLayoutProps extends WithStyles<typeof styles> {
  open: boolean
  title: string
  withoutCloseIcon?: boolean | undefined
  fullScreen: boolean | undefined
  onClose
  onClick
  actions
  children: React.ReactNode
}

export function DialogLayoutComponent(props: IDialogLayoutProps): JSX.Element {
  return (
    <Dialog
      fullScreen={false}
      onClose={props.onClose}
      aria-labelledby='customized-dialog-title'
      open={props.open}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <DialogTitle id='customized-dialog-title' onClose={props.onClose}>
        {props.title}
      </DialogTitle>
      <DialogContent
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        {props.children}
      </DialogContent>
      <DialogActions>{props.actions}</DialogActions>
    </Dialog>
  )
}

export const DialogLayout = withStyles(styles)(DialogLayoutComponent)
