import * as THREE from 'three'
import { WebGLRenderer } from 'three'

export interface ITextureInput {
  [input: string]: THREE.Texture
}

export class RenderTarget {
  constructor(public target: THREE.WebGLRenderTarget, public autoResize: boolean = true) {}
}
export type FPass = (pipeLine: DCLDRenderPipeline, input: ITextureInput, target: RenderTarget) => void
export interface IMap<T> {
  [input: string]: T
}
export class Pass {
  name?: string

  inputMap?: IMap<string>

  output?: string

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public render(_pipeLine: DCLDRenderPipeline, _input: ITextureInput, _target: RenderTarget): void {}
}

export class DCLDRenderPipeline {
  public renderer: WebGLRenderer

  public targets = new Map<string, RenderTarget>()

  public passes: Pass[] = []

  public actPassName?: string

  constructor(_renderer: WebGLRenderer) {
    this.renderer = _renderer
  }

  public addRenderTarget(name: string, renderTarget: THREE.WebGLRenderTarget, autoScreenSize: boolean): void {
    this.targets.set(name, new RenderTarget(renderTarget, autoScreenSize))
  }

  public removePass(pass: Pass): void {
    this.passes = this.passes.filter((v) => v !== pass)
  }

  public addPass(pass: Pass, name?: string): void {
    pass.name = name
    this.passes.push(pass)
  }

  public setAutoClearProps(
    autoClear: boolean,
    autoClearColor: boolean,
    autoClearDepth: boolean,
    autoClearStencil: boolean
  ): void {
    this.renderer.autoClear = autoClear
    this.renderer.autoClearColor = autoClearColor
    this.renderer.autoClearDepth = autoClearDepth
    this.renderer.autoClearStencil = autoClearStencil
  }

  public render(): void {
    for (const pass of this.passes) {
      this.actPassName = pass.name
      const passInput: ITextureInput = {}
      for (const [k, v] of Object.entries(pass.inputMap || {})) {
        const input = k.split('_')
        const rt = this.targets.get(input[0])
        if (rt) {
          switch (input[1].toLowerCase()) {
            case 'color':
              passInput[v] = rt.target.texture
              break
            case 'depth':
              passInput[v] = rt.target.depthTexture
              break
            default:
            // console.log(`unknwon render pass input ${k}`)
          }
        }
      }
      const lastRenderTarget = this.renderer.getRenderTarget()

      const outputName = pass.output
      let renderTarget

      if (!outputName) {
        renderTarget = null
        this.renderer.setRenderTarget(null) // render to the framebuffer
      } else {
        renderTarget = this.targets.get(outputName)
        if (!renderTarget) {
          // console.log(`render target ${outputName} not found, skipping pass ${pass.name}`)
          continue
        }
        this.renderer.setRenderTarget(renderTarget.target) // render to the RT
      }
      // this.renderer.context.copyTexSubImage2D()
      pass.render(this, passInput, renderTarget)
      this.renderer.setRenderTarget(lastRenderTarget)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public reset(_renderTarget: WebGLRenderer): void {}

  public setSize(width: number, height: number): void {
    for (const [_k, v] of this.targets) {
      if (v.autoResize) {
        // ez csak a rendertarget meretet es a depth texture meretet allitja....
        v.target.setSize(width, height)
      }
    }
  }
}
