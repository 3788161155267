export enum ViewDirection {
  NONE,
  FRONT,
  BACK,
  LEFT,
  RIGHT,
  TOP,
  BOTTOM,
}

export class VolumePanel {
  lastViewDirection: ViewDirection = ViewDirection.NONE
}
