// import "./home.scss";
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as A from 'actions'
import * as THREE from 'three'
import { Renderer } from 'renderer'
import { Vector3, Vector2 } from 'three'
import { DCLDOrbitControls, ControlMethod } from 'utils/DCLDOrbitControls'
import { DCLDRenderPipeline } from 'renderer/Pipeline'
import { BackAndFrontPassShader } from 'shaders/BackAndFrontPass'
import { SceneDraw, MultiPassSceneDraw } from 'renderer/SceneDraw'
import { QuadDraw } from 'renderer/QuadDraw'
import { DicomVolume } from 'models/case/DicomVolume'
import * as M from 'models'
import { UpdateObjectFromMatrix } from 'utils/Math'
import { ISize } from 'utils/ISize'
import { IObjMap } from 'utils/IObjMap'
import VolumeDown from '@mui/icons-material/BrightnessLow'
import VolumeUp from '@mui/icons-material/BrightnessHigh'
import { Box, Fab } from '@mui/material'
import { convertToNumber } from 'utils/HalfFloat'
import MenuIcon from '@mui/icons-material/Menu'
import LayersIcon from '@mui/icons-material/Layers'

import ConfigureISOLevel from '../UI/VolumeTools/ConfigureISOLevel'
import * as C from './Consts'
import { VolumeQuadDraw } from './VolumeQuadDraw'

import { ScreenShotButton } from 'components/UI/VolumeTools/Screenshot'
import { CustomGL } from './CustomGL'

const RELOAD_OBJS = true // (module as any).hot // ha a hot module reloaded engedelyezve van csak akkor => prodban gyorsabb lesz
const RELOAD_SHADERS = true

const kUseLowResForRotation = false
const kHighResDragTimeout = 200
const kImpressions = ['impression', 'gImpression', 'antagonist', 'bite']
const cubeFaces = [
  // front
  0, 1, 2, 2, 3, 0,
  // right
  1, 5, 6, 6, 2, 1,
  // back
  7, 6, 5, 5, 4, 7,
  // left
  4, 0, 3, 3, 7, 4,
  // bottom
  4, 5, 1, 1, 0, 4,
  // top
  3, 2, 6, 6, 7, 3,
]
interface VolumePanelState {
  size: ISize

  controlMethod: number
}

const initialState: VolumePanelState = { size: { width: 0, height: 0 }, controlMethod: ControlMethod.NONE }

export interface IVolumePanelComponentProps {
  customObjects: IObjMap<M.Custom>
  slice: M.Slice
  volume: M.Volume
  volumeStatic: M.VolumeStatic
  visual: M.VisualConfig
  assets: M.Assets
  editorConfig: M.EditorConfig
  volumePanel: M.VolumePanel
  zoom: M.Zoom
}

function getCursor(controlMethod: number): string {
  switch (controlMethod) {
    case ControlMethod.ROTATE:
      return 'pointer'
    case ControlMethod.PAN:
      return 'move'
    case ControlMethod.NONE:
    case ControlMethod.ZOOM:
    case ControlMethod.ZOOM_PAN:
    case ControlMethod.ZOOM_ROTATE:
    default:
      return 'auto'
  }
}

class VolumePanelComponent extends React.Component<
  IVolumePanelComponentProps &
    A.IObjectsAction &
    A.IAssetsAction &
    A.IZoomAction &
    A.ICaseAction &
    A.IVolumePanelAction & { size: ISize },
  VolumePanelState
> {
  // public childRef;

  MIN_X = 100

  MIN_Y = 10

  // Volume related data
  visibleRange?: THREE.Vector2

  volume: DicomVolume = new DicomVolume()

  brickMesh?: THREE.Mesh

  // cropBox?: THREE.Mesh;
  volumeQuadDraw?: VolumeQuadDraw

  envTexture?: THREE.CubeTexture

  bgTexture?: THREE.Texture

  sizeInPixel: Vector2 = new Vector2()

  ambientLight?: THREE.AmbientLight

  pointLight?: THREE.PointLight

  depthTextures: THREE.Texture[] = []

  colorTextures: THREE.Texture[] = []

  frontPassPositionMaterial?: THREE.ShaderMaterial

  blackShaderMaterial?: THREE.ShaderMaterial

  backPassDirMaterial?: THREE.ShaderMaterial

  pipeline?: DCLDRenderPipeline

  private scene: THREE.Scene

  private camera?: THREE.OrthographicCamera

  private controls?: DCLDOrbitControls

  private controlMethod

  private bgClearPass?: QuadDraw
  private customObjects?: CustomGL

  private frustumSize = 100

  private wasLoadFinishEventSent = false

  private canvasRef
  private defaultLookatPos?: THREE.Vector3
  private renderInHighRes = true
  ZNEAR = 0

  ZFAR = 400

  constructor(props: any) {
    super(props)
    this.canvasRef = React.createRef()
    this.scene = new THREE.Scene()
    this.state = initialState
  }

  getNDCPos(event): THREE.Vector2 {
    const pointer = event
    const rect = (this.canvasRef.current as HTMLCanvasElement).getBoundingClientRect()

    let y = (pointer.clientY - rect.top) / rect.height
    let x = (pointer.clientX - rect.left) / rect.width
    x = x * 2 - 1
    y = (1 - y) * 2 - 1
    return new THREE.Vector2(x, y)
  }

  setupEnvironment() {
    if (this.camera !== undefined) {
      return
    }

    /* let archCenter = this.props.arch.getCenter();
            let frontPos = this.props.arch.getPointAt(0.5);
            let lookatPos = new THREE.Vector3().lerpVectors(archCenter, frontPos, 0.5)
            */
    this.defaultLookatPos = this.props.volume.cropLower
      ? new THREE.Vector3().lerpVectors(this.props.volume.cropLower, this.props.volume.cropUpper, 0.5)
      : new THREE.Vector3(0, 0, 0)
    // this.props.arch.getCenter();

    const width = Math.max(this.props.size.width, this.MIN_X)
    const height = Math.max(this.props.size.height, this.MIN_Y)
    const aspect = width / height
    this.sizeInPixel = new Vector2(width * window.devicePixelRatio, height * window.devicePixelRatio)

    this.camera = new THREE.OrthographicCamera(
      (this.frustumSize * aspect) / -2,
      (this.frustumSize * aspect) / 2,
      this.frustumSize / 2,
      this.frustumSize / -2,
      this.ZNEAR,
      this.ZFAR
    )
    this.camera.position.copy(new THREE.Vector3().copy(this.defaultLookatPos).sub(new Vector3(0, 200, 0)))
    this.camera.up.set(0, 0, 1)
    //this.camera.screenSpacePanning = true;
    this.scene.add(this.camera)
    this.camera.updateMatrix()
    this.camera.updateMatrixWorld()
    /*this.ambientLight = new THREE.AmbientLight(0x404040)
        this.pointLight = new THREE.PointLight(0xffff00)
*/
    //this.ambientLight.color = new THREE.Color(0.45, 0.45, 0.45).multiplyScalar(1)
    //this.pointLight.color = new THREE.Color(0.6, 0.6, 0.6).multiplyScalar(1)
    this.ambientLight = new THREE.AmbientLight(new THREE.Color(0.45, 0.45, 0.45).multiplyScalar(1))
    this.pointLight = new THREE.PointLight(new THREE.Color(0.6, 0.6, 0.6).multiplyScalar(1))

    this.camera.add(this.pointLight)
    this.camera.add(this.ambientLight)
    this.controls = new DCLDOrbitControls(this.camera, this.canvasRef.current as HTMLCanvasElement)
    this.controls.target = this.defaultLookatPos
    this.controls.update()
    ;(this.controls as any).addEventListener('change', () => this.camChanged())
    ;(this.controls as any).addEventListener('start', (ev) => this.startCamChange(ev))
    ;(this.controls as any).addEventListener('end', (ev) => this.endCamChange(ev))

    this.ambientLight.layers.set(C.LAYER_SHAPE)
    this.pointLight.layers.set(C.LAYER_SHAPE)

    this.initMaterials()
    this.init()
    this.initPipeline()
  }

  private createRenderTarget(
    size: THREE.Vector2,
    depthBuffer = true,
    stencilBuffer = false,
    textureIndex: number,
    dataType: THREE.TextureDataType,
    pixelFormat: THREE.PixelFormat
  ): THREE.WebGLRenderTarget {
    const rv = new THREE.WebGLRenderTarget(size.x, size.y, {
      depthTexture: this.depthTextures[textureIndex],
      depthBuffer,
      stencilBuffer,
      generateMipmaps: false,
    })

    const texture = new THREE.Texture(
      undefined,
      undefined,
      THREE.ClampToEdgeWrapping,
      THREE.ClampToEdgeWrapping,
      THREE.NearestFilter,
      THREE.NearestFilter,
      pixelFormat || THREE.RGBAFormat,
      dataType || THREE.FloatType,
      undefined
    )
    texture.image = { width: size.x, height: size.y }

    texture.magFilter = THREE.NearestFilter
    texture.minFilter = THREE.NearestFilter
    texture.flipY = false
    texture.generateMipmaps = false

    this.colorTextures[textureIndex] = texture
    rv.texture = this.colorTextures[textureIndex]

    return rv
  }

  private createDepthTexture(sizeInPixel, stencil: boolean) {
    const ctr = THREE.DepthTexture.prototype
    const obj = Object.create(ctr)
    obj.constructor(
      sizeInPixel.x,
      sizeInPixel.y,
      undefined,
      undefined, // t.m
      undefined,
      undefined, // w
      undefined,
      undefined, // filter
      undefined, // an
      stencil ? THREE.DepthStencilFormat : THREE.DepthFormat
    )
    return obj
  }

  private initPipeline() {
    const { renderer } = Renderer.get()
    this.pipeline = new DCLDRenderPipeline(renderer)
    const { sizeInPixel } = this
    if (!this.props.volume.cropLower) {
      this.bgClearPass = new QuadDraw(this.bgTexture, new THREE.Vector2(0, 0), 1, false, undefined, true, false, -1.0)

      this.pipeline.addPass(
        new SceneDraw(this.scene, this.camera, undefined, C.LAYER_SHAPE, {
          renderer: {
            autoClearDepth: true,
            autoClearColor: false,
          },
          scene: {
            background: new THREE.Color(0.5, 0.5, 0.5),
          },
        })
      )
      this.pipeline.addPass(this.bgClearPass)

      return
    }
    for (let k = 0; k < C.DEPTH_COUNT; k++) {
      const dtxt = this.createDepthTexture(sizeInPixel, false)
      this.depthTextures.push(dtxt)
    }
    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_clipping_stencil.html
    this.pipeline.addRenderTarget(
      'shape',
      this.createRenderTarget(sizeInPixel, true, false, C.DEPTH_SHAPE, THREE.UnsignedByteType, THREE.RGBFormat),
      true
    )

    this.pipeline.addRenderTarget(
      'front',
      this.createRenderTarget(sizeInPixel, true, false, C.DEPTH_FRONTBACK, THREE.HalfFloatType, THREE.RGBAFormat),
      true
    )

    this.pipeline.addRenderTarget(
      'backDir',
      this.createRenderTarget(sizeInPixel, true, false, C.DEPTH_FRONTBACK, THREE.HalfFloatType, THREE.RedFormat),
      true
    )

    if (this.camera) {
      this.pipeline.addPass(
        new SceneDraw(this.scene, this.camera, 'shape', C.LAYER_SHAPE, {
          renderer: {
            autoClearDepth: true,
            autoClearColor: false,
          },
          scene: {
            background: new THREE.Color(0.5, 0.5, 0.5),
          },
        })
      )

      if (this.bgTexture) {
        this.bgClearPass = new QuadDraw(this.bgTexture, new THREE.Vector2(0, 0), 1, false, 'shape', true, false, -1.0)
        this.pipeline.addPass(this.bgClearPass)
      }

      this.pipeline.addPass(
        new MultiPassSceneDraw(
          this.scene,
          this.camera,
          [
            C.LAYER_SHAPE,
            this.blackShaderMaterial,
            () => {
              Renderer.get().renderer.setClearColor(0x776655, 0)
            },
            C.LAYER_VOLUME,
            this.frontPassPositionMaterial,
            undefined,
          ],
          'front',
          {
            renderer: {
              autoClearDepth: true,
            },
          }
        ),
        'frontPass'
      )

      if (this.pipeline) {
        this.pipeline.addPass(
          new MultiPassSceneDraw(
            this.scene,
            this.camera,
            [
              /*  C.LAYER_SHAPE, this.blackShaderMaterial, () => {
                                  // Renderer.get().renderer.clearColor();
                                  }, */
              C.LAYER_VOLUME,
              this.backPassDirMaterial,
              () => {
                if (this.pipeline && this.backPassDirMaterial) {
                  const frontTarget = this.pipeline.targets.get('front')
                  if (frontTarget) {
                    const txt = frontTarget.target.texture
                    this.backPassDirMaterial.uniforms.tEntryPoints = { value: txt }
                    this.backPassDirMaterial.uniforms.ScreenSizeReciproc = {
                      value: new THREE.Vector2(1.0 / txt.image.width, 1.0 / txt.image.height),
                    }
                    this.backPassDirMaterial.depthFunc = THREE.GreaterDepth
                    Renderer.get().renderer.state.buffers.depth.setClear(0)
                  }
                }
              },
            ],
            'backDir',
            {
              renderer: {
                autoClearDepth: true,
                autoClearColor: true,
              },
              scene: {
                background: new THREE.Color(0, 0.0, 0), // new THREE.Color(0.2, 0.6, 0.6)
              },
            }
          ),
          'backDirLengthPass'
        )
      }
    }

    if (this.volume) {
      this.initVolumeQuadDrawPass()
    }

    // update camrea's and interactor's target
  }

  private initVolumeQuadDrawPass() {
    if (this.volumeQuadDraw && this.pipeline) {
      this.pipeline.removePass(this.volumeQuadDraw)
    }
    if (!this.volume?.volumeTexture || !this.pipeline || !this.envTexture) {
      return
    }

    this.volumeQuadDraw = new VolumeQuadDraw(
      this.volume.volumeTexture,
      this.volume.volumeDirTexture.length !== 0,
      this.volume,
      this.volume.volumeDirScale || 1,
      new THREE.Vector3(this.volume.width, this.volume.height, this.volume.imageCount),
      this.envTexture,
      undefined,
      () => {
        // calculate lookahead Dir in the brickmesh local space
        if (!this.camera || !this.brickMesh || !this.volumeQuadDraw) return
        this.camera.updateMatrixWorld()
        const ccc = this.camera
        const ahead2 = new THREE.Vector3(0, 0, -1).transformDirection(ccc.matrixWorld)

        const worldToLocal = new THREE.Matrix4()
        this.brickMesh.updateMatrixWorld()
        // this.cropBox.updateMatrixWorld();
        worldToLocal.getInverse(this.brickMesh.matrixWorld)
        let localDir = ahead2.transformDirection(worldToLocal)
        localDir = localDir.normalize()
        const tr = new THREE.Vector3()
        const rt = new THREE.Quaternion()
        const sc = new THREE.Vector3()

        this.camera.matrix.decompose(tr, rt, sc)
        this.volumeQuadDraw.backToFrontDir = localDir
        this.volumeQuadDraw.cameraPosition = tr
        this.volumeQuadDraw.lightPosition = tr
        this.volumeQuadDraw.modelMatrix = this.brickMesh.matrixWorld
        this.volumeQuadDraw.originalCamera = this.camera
        this.volumeQuadDraw.renderInHighRes = this.renderInHighRes || !kUseLowResForRotation

        /*console.log('ahead(ws): '+JSON.stringify(ahead2));dfsdfsdf
                console.log('controltarget: '+JSON.stringify(this.controls.target));
                console.log('cam pos: '+JSON.stringify(this.camera.position));
                console.log('cam matrix: '+JSON.stringify(this.camera.matrix));
                console.log('light pos: '+JSON.stringify(tr));*/

        const absDir = new THREE.Vector3(Math.abs(localDir.x), Math.abs(localDir.y), Math.abs(localDir.z))
        let mainDir = -1
        let mainLen
        if (absDir.x > absDir.y) {
          if (absDir.x > absDir.z) {
            mainDir = 0
            mainLen = localDir.x
          } else {
            mainDir = 2
            mainLen = localDir.z
          }
        } else if (absDir.y > absDir.z) {
          mainDir = 1
          mainLen = localDir.y
        } else {
          mainDir = 2
          mainLen = localDir.z
        }
        const dirTextureIndex = mainDir * 2 + (mainLen > 0 ? 0 : 1)
        this.volumeQuadDraw.acceleratorTexture = this.volume.volumeDirTexture[dirTextureIndex]
      }
    )

    this.pipeline.addPass(this.volumeQuadDraw)
  }

  public init() {
    const envTexture = new THREE.CubeTexture()
    for (let k = 0; k < 6; k++) {
      const image = this.props.assets.custom[`TXT_ENV_${k}`]
      envTexture.images[k] = image
      envTexture.needsUpdate = true
    }
    this.envTexture = envTexture
    this.bgTexture = this.props.assets.custom.TXT_VOLUMEBG as THREE.Texture
    this.invalidate()
  }

  private updateColorConfig() {
    if (this.volumeQuadDraw) {
      this.volumeQuadDraw.isoColor = new THREE.Color('#DDCFB5').multiplyScalar(1)
      // new THREE.Vector3(0.89, 0.855, 0.788)
      // new THREE.Vector3(0.996, 0.882, 0.816)
      /*
                        this.volumeQuadDraw.diffuse = new THREE.Vector4(0.8, 0.8, 0.8, 1)
                        this.volumeQuadDraw.specular = new THREE.Vector4(0.6, 0.6, 0.6, 0)
                        this.volumeQuadDraw.ambient = new THREE.Vector4(0.45, 0.45, 0.45, 0).multiplyScalar(1)
            */
      this.volumeQuadDraw.diffuse = new THREE.Vector4(1, 1, 1, 1).multiplyScalar(0.55)
      this.volumeQuadDraw.specular = new THREE.Vector4(1, 1, 1, 0).multiplyScalar(1)
      this.volumeQuadDraw.ambient = new THREE.Vector4(1, 1, 1).multiplyScalar(0.4)

      //this.ambientLight = new THREE.AmbientLight( new THREE.Color(0.45, 0.45, 0.45).multiplyScalar(1))
      //this.pointLight = new THREE.PointLight(new THREE.Color(0.6, 0.6, 0.6).multiplyScalar(1))

      this.volumeQuadDraw.attenuation = new THREE.Vector3(1.0, 0.2, 0.02)
      this.volumeQuadDraw.shininess = 50
      /*if (this.ambientLight) {
                this.ambientLight.color = new THREE.Color(0.45, 0.45, 0.45).multiplyScalar(1)
                this.pointLight.color = new THREE.Color(0.6, 0.6, 0.6).multiplyScalar(1)
            }*/
    }
  }

  private setVolume(volume: DicomVolume) {
    this.volume = volume
    if (this.volume === undefined) {
      if (this.brickMesh) {
        this.scene.remove(this.brickMesh)
        // this.scene.remove(this.cropBox)
      }
      return
    }
    this.setupEnvironment()
    this.initBrickModel()
    this.initVolumeQuadDrawPass()
    /*    this.pipeline.addPass(new QuadDraw('front_color', new THREE.Vector2(0, 0), 0.25))
        this.pipeline.addPass(new QuadDraw('backDir_color', new THREE.Vector2(0.25, 0), 0.25))
        this.pipeline.addPass(new QuadDraw('shape_depth', new THREE.Vector2(0.5,0),0.25))
        this.pipeline.addPass(new QuadDraw('shape_color', new THREE.Vector2(0.75,0),0.25))
      */
    if (this.volumeQuadDraw && this.volume.volumeTexture && this.camera) {
      this.volumeQuadDraw.volumeTexture = this.volume.volumeTexture
      this.volumeQuadDraw.volumeTextureSize = new THREE.Vector3(volume.width, volume.height, volume.imageCount)
      this.volumeQuadDraw.screenSize = this.sizeInPixel
      this.volumeQuadDraw.isoLevel = (this.props.volume.mRange.value + 32768) / 65536.0
      this.volumeQuadDraw.cameraPosition = this.camera.position
      this.volumeQuadDraw.lightPosition = this.camera.position
      //this.camera.position // todo
      this.updateColorConfig()
      // todo ezeket a color config-bol illetve a scene-ben levo fenyek beallitasaibol vegye

      if (this.brickMesh) {
        this.brickMesh.updateMatrixWorld()
        // this.cropBox.updateMatrix();
        this.volumeQuadDraw.modelMatrix = this.brickMesh.matrixWorld
        this.volumeQuadDraw.originalCamera = this.camera
      }
    }
  }

  private initBrickModel() {
    if (this.brickMesh) {
      this.scene.remove(this.brickMesh)
      this.brickMesh = undefined
      // this.scene.remove(this.cropBox)
    }
    if (!this.props.volume.range) return
    this.visibleRange = new Vector2(this.props.volume.range.min + 32768, this.props.volume.range.max + 32768)
    /*    if (!this.volume.brickVolumeVertices) {
                    let brick = new BrickedVolume(this.volume);
                    brick.computeTriangles(this.visibleRange.x, this.visibleRange.y, C.RENDERMODE);
                    this.volume.brickVolumeVertices = brick.getVertices();
                    this.volume.brickVolumeIndices = brick.getIndices(C.RENDERMODE);
                } */
    this.updateCropState()
  }
  private initMaterials() {
    this.frontPassPositionMaterial = new THREE.ShaderMaterial({
      uniforms: BackAndFrontPassShader.uniforms,
      vertexShader: BackAndFrontPassShader.vertexShader,
      fragmentShader: BackAndFrontPassShader.frontPosFragmentShader,
      depthTest: true,
      depthWrite: true,
    })

    this.backPassDirMaterial = new THREE.ShaderMaterial({
      uniforms: {
        ScreenSizeReciproc: { value: new THREE.Vector2(0, 0) },
        tEntryPoints: { value: null },
      },
      vertexShader: BackAndFrontPassShader.vertexShader,
      fragmentShader: BackAndFrontPassShader.backDirFragmentShader,
      depthTest: true,
      depthWrite: true,
      side: THREE.BackSide,
    })

    this.blackShaderMaterial = new THREE.ShaderMaterial({
      vertexShader: BackAndFrontPassShader.vertexShader,
      fragmentShader: BackAndFrontPassShader.backAndFrontBlackShapeFragmentShader,
      depthTest: true,
      depthWrite: true,
    })

    const width = Math.max(this.props.size.width, this.MIN_X)
    const height = Math.max(this.props.size.height, this.MIN_Y)
    // const aspect = width / height
    this.sizeInPixel = new Vector2(width * window.devicePixelRatio, height * window.devicePixelRatio)

    // const shapeMaterial = new THREE.MeshStandardMaterial({
    //   color: new THREE.Color(0.8, 0.5, 0.5),
    // })
  }

  startCamChange(ev) {
    if (this.state.controlMethod !== ev.method) {
      this.setState((prevState) => ({ ...prevState, controlMethod: ev.method }))
    }
  }

  private lastTimeOutFunc

  endCamChange(_ev) {
    if (this.state.controlMethod !== ControlMethod.NONE) {
      this.setState((prevState) => ({ ...prevState, controlMethod: ControlMethod.NONE }))
    }
    if (this.lastTimeOutFunc) {
      clearTimeout(this.lastTimeOutFunc)
      this.renderInHighRes = true
      this.invalidate()
    }
  }

  camChanged() {
    this.renderInHighRes = false
    this.invalidate()
    if (this.lastTimeOutFunc) {
      clearTimeout(this.lastTimeOutFunc)
    }
    this.lastTimeOutFunc = setTimeout(() => {
      this.renderInHighRes = true
      this.invalidate()
    }, kHighResDragTimeout)
  }

  renderGL() {
    // console.log('render in '+this.renderInHighRes)
    // console.log('VPR')
    // nincs betoltve

    if (!this.camera) {
      this.invalidate()
      return
    }

    // nicsenek a helper texturak betoltve
    if (!this.envTexture) {
      this.invalidate()
      return
    }

    if (RELOAD_OBJS) {
      this.reloadObjects()
    }
    this.updateColorConfig()

    if (this.bgClearPass) {
      this.bgClearPass.inputTexture = this.bgTexture
    }
    if (this.brickMesh) {
      this.brickMesh.visible = this.props.volume.visible
    }

    // this.cropBox.visible = this.props.volume.visible;
    Renderer.get().render(this.canvasRef.current as HTMLCanvasElement, (r) => {
      r.renderer.setClearColor(0x234567)
      r.renderer.clear(true, true, true)
      if (this.pipeline) {
        this.pipeline.render()
      }
      if (!this.wasLoadFinishEventSent) {
        // console.log('NOOOOOOOOOOOOOOW');
        this.wasLoadFinishEventSent = true
        if (window.parent) {
          window.parent.postMessage(
            {
              session: window['viewerSession'],
              msg: 'SGJS_Ready',
            },
            '*'
          )
          //window.parent.postMessage('SGJS_Ready', '*')
        }
      }
    })
  }

  private inRender = false

  private postRender = false

  invalidate() {
    if (this.inRender) {
      this.postRender = true
      return
    }
    this.inRender = true
    requestAnimationFrame(() => {
      this.inRender = false
      this.renderGL()
      if (this.postRender) {
        this.postRender = false
        this.invalidate()
      }
    })
  }

  private reloadObjects() {
    // 'impression', 'gImpression',
    const objs = ['nerves', 'objects', 'teeth', 'slice', 'customObjects']
    for (const k of objs) {
      if (this[k]) {
        this[k].dispose()
      }
    }
    /* let factory = {
            'impression': this.props.impression,
            'gImpression': this.props.gImpression,
            'antagonist': this.props.antagonist,
            'bite': this.props.bite,
        } */
    // this.impression = new MouldGL(this.scene)
    // this.gImpression = new MouldGL(this.scene)

    this.customObjects = new CustomGL(this.scene)
    // this.impression.update({} as M.Mould, this.props.impression);
    // this.gImpression.update({} as M.Mould, this.props.gImpression);

    this.customObjects.update({}, this.props.customObjects, this.props.editorConfig, this.props.editorConfig)
  }
  componentDidUpdateCustomObjects(prevProps, _prevState) {
    if (
      !this.customObjects ||
      (prevProps.customObjects === this.props.customObjects && prevProps.editorConfig === this.props.editorConfig)
    ) {
      return
    }
    if (
      this.customObjects.update(
        prevProps.customObjects,
        this.props.customObjects,
        prevProps.editorConfig,
        this.props.editorConfig
      )
    ) {
      this.invalidate()
    }
  }
  componentDidMount() {
    this.setupEnvironment()
    this.componentDidUpdate({}, {})
    // todo update camera
    this.reloadObjects()
    this.invalidate()
  }

  componentDidUpdateVolume(prevProps, _prevState) {
    if (prevProps.volume !== this.props.volume) {
      if (this.volumeQuadDraw) {
        this.volumeQuadDraw.isoLevel = (this.props.volume.mRange.value + 32768) / 65536.0
      }
      this.invalidate()
    }

    if ((RELOAD_SHADERS || prevProps.volumeStatic !== this.props.volumeStatic) && this.props.volumeStatic.volume) {
      this.setVolume(this.props.volumeStatic.volume)
      this.invalidate()
    }
    if (
      prevProps?.editorConfig?.croppedVisible !== this.props.editorConfig.croppedVisible &&
      this.props.volume.mRange !== undefined
    ) {
      this.updateCropState()
      this.invalidate()
    }
  }

  componentDidUpdateSize(prevProps, _prevState) {
    if (prevProps?.size?.width === this.props.size.width && prevProps?.size?.height === this.props.size.height) {
      return
    }
    // console.log('inv');
    // resize esemeny tortent
    const width = Math.max(this.props.size.width, this.MIN_X)
    const height = Math.max(this.props.size.height, this.MIN_Y)
    const aspect = width / height
    this.sizeInPixel = new Vector2(width * window.devicePixelRatio, height * window.devicePixelRatio)

    if (this.camera && this.pipeline && this.controls) {
      const size = this.sizeInPixel
      this.camera.left = (this.frustumSize * aspect) / -2
      this.camera.right = (this.frustumSize * aspect) / 2
      this.camera.top = this.frustumSize / 2
      this.camera.bottom = this.frustumSize / -2
      this.camera.updateProjectionMatrix()
      this.controls.update()
      for (let dt = 0; dt < C.DEPTH_COUNT; ++dt) {
        if (this.depthTextures[dt]) {
          const newTexture = this.createDepthTexture(size, false)

          // new THREE.DepthTexture(size.x, size.y);
          for (const [_k, v] of this.pipeline.targets) {
            if (v.autoResize) {
              if (v.target.depthTexture.uuid === this.depthTextures[dt].uuid) {
                v.target.depthTexture = newTexture
              }
            }
          }
          this.depthTextures[dt].dispose()
          this.depthTextures[dt] = newTexture
        }
      }
      this.pipeline.setSize(size.x, size.y)
      if (this.volumeQuadDraw) {
        this.volumeQuadDraw.screenSize = size
      }
      this.invalidate()
    }
  }

  componentDidUpdateVolumePanel(prevProps, _prevState) {
    if (
      !this.controls ||
      prevProps.volumePanel === this.props.volumePanel ||
      this.props.volumePanel.lastViewDirection === M.ViewDirection.NONE
    ) {
      return
    }
    switch (this.props.volumePanel.lastViewDirection) {
      // Interactive spherical coordinate helper
      // https://mathinsight.org/spherical_coordinates
      case M.ViewDirection.FRONT:
        this.controls.setAzimuthalAngle(0)
        this.controls.setPolarAngle(Math.PI / 2)
        break
      case M.ViewDirection.BACK:
        this.controls.setAzimuthalAngle(Math.PI)
        this.controls.setPolarAngle(Math.PI / 2)
        break
      case M.ViewDirection.LEFT:
        this.controls.setAzimuthalAngle(Math.PI / 2)
        this.controls.setPolarAngle(Math.PI / 2)
        break
      case M.ViewDirection.RIGHT:
        this.controls.setAzimuthalAngle((3 * Math.PI) / 2)
        this.controls.setPolarAngle(Math.PI / 2)
        break
      case M.ViewDirection.TOP:
        this.controls.setPolarAngle(0)
        this.controls.setAzimuthalAngle(0)
        break
      case M.ViewDirection.BOTTOM:
        this.controls.setPolarAngle(Math.PI)
        this.controls.setAzimuthalAngle(0)
        break
      default:
    }
    this.controls.update(true)
  }

  componentDidUpdate(prevProps, prevState) {
    this.componentDidUpdateSize(prevProps, prevState)
    this.componentDidUpdateCustomObjects(prevProps, prevState)
    this.componentDidUpdateVolume(prevProps, prevState)
    this.componentDidUpdateVolumePanel(prevProps, prevState)
  }

  private bricks

  updateCropState() {
    if (this.props?.editorConfig?.croppedVisible === undefined) {
      return
    }
    const v = this.volume
    if (!v) {
      return
    }
    const completeCube = this.volume.cubeMesh
    if (completeCube) {
      if (this.brickMesh) {
        this.scene.remove(this.brickMesh)
      }
      // todo setup geometry
      const bg = new THREE.BoxBufferGeometry(1, 1, 1)
      for (let k = 0; k < bg.attributes.position.array.length; k++) {
        ;(bg.attributes.position.array as any)[k] += 0.5
      }
      //   const shapeMaterial = new THREE.MeshStandardMaterial({
      //     color: new THREE.Color(0.8, 0.5, 0.5),
      //   })
      this.brickMesh = new THREE.Mesh(bg, this.frontPassPositionMaterial)
      //this.brickMesh = new THREE.Mesh(bg, shapeMaterial)
      this.brickMesh.layers.set(C.LAYER_VOLUME)
      UpdateObjectFromMatrix(this.brickMesh, this.props.volume.transform)
      const s2 = this.brickMesh.scale
      s2.multiply(
        new THREE.Vector3(
          this.volume.width * this.volume.voxelSize.x,
          this.volume.height * this.volume.voxelSize.y,
          this.volume.imageCount * this.volume.voxelSize.z
        )
      )
      this.brickMesh.scale.copy(s2)
      this.scene.add(this.brickMesh)

      return
    }
    const wBCount = Math.ceil(v.width / DicomVolume.BRICK_LENGTH)
    const hBCount = Math.ceil(v.height / DicomVolume.BRICK_LENGTH)
    const dBCount = Math.ceil(v.imageCount / DicomVolume.BRICK_LENGTH)
    let bricks

    if (!this.bricks) {
      if (this.volume.brickArray) {
        bricks = this.volume.brickArray
      } else {
        if (v.data) {
          bricks = new Uint16Array(wBCount * hBCount * dBCount)
          const getBrickMaxfp16 = (d: number, h: number, w: number): number => {
            let max = -1000000
            if (v.data) {
              let dS: number = d * DicomVolume.BRICK_LENGTH
              let hS: number = h * DicomVolume.BRICK_LENGTH
              let wS: number = w * DicomVolume.BRICK_LENGTH
              dS = dS === 0 ? 0 : dS - 1
              hS = hS === 0 ? 0 : hS - 1
              wS = wS === 0 ? 0 : wS - 1
              let dE: number = (d + 1) * DicomVolume.BRICK_LENGTH
              dE = dE >= v.imageCount ? v.imageCount : dE + 1
              let hE: number = (h + 1) * DicomVolume.BRICK_LENGTH
              hE = hE >= v.height ? v.height : hE + 1
              let wE: number = (w + 1) * DicomVolume.BRICK_LENGTH
              wE = wE >= v.width ? v.width : wE + 1
              const layerSize = v.width * v.height

              for (let z = dS; z < dE; z++) {
                for (let y = hS; y < hE; y++) {
                  for (let x = wS; x < wE; x++) {
                    const s = convertToNumber(v.data[z * layerSize + y * v.width + x]) * 65536.6
                    max = Math.max(max, s)
                  }
                }
              }
            }
            return max
          }
          let p = 0
          for (let z = 0; z < dBCount; z++) {
            for (let y = 0; y < hBCount; y++) {
              for (let x = 0; x < wBCount; x++, p++) {
                const b = getBrickMaxfp16(z, y, x)
                bricks[p] = b
              }
            }
          }
        }
      }
      this.bricks = bricks
    } else {
      bricks = this.bricks
    }

    const minVal = this.props.volume.mRange.value + 32768

    const vertices: number[] = []
    const verticesIdx = new Map<number, number>()
    const faces: number[] = []

    let bBLower = new THREE.Vector3().set(0, 0, 0)
    let bBUpper = new THREE.Vector3().set(1, 1, 1)

    if (this.props.editorConfig.croppedVisible) {
      const m = new THREE.Matrix4().copy(this.props.volume.transform)
      const m2 = new THREE.Matrix4().makeScale(this.volume.width, this.volume.height, this.volume.imageCount)
      m.multiply(m2)
      // let it = new THREE.Matrix4().copy(this.brickMesh.matrix)
      const it = m
      const itinv = new THREE.Matrix4().getInverse(it)
      bBLower = new THREE.Vector3().copy(this.props.volume.cropLower).applyMatrix4(itinv)
      bBUpper = new THREE.Vector3().copy(this.props.volume.cropUpper).applyMatrix4(itinv)
    }

    const x0 = Math.floor((bBLower.x * v.width) / DicomVolume.BRICK_LENGTH)
    const x1 = Math.ceil((bBUpper.x * v.width) / DicomVolume.BRICK_LENGTH)

    const y0 = Math.floor((bBLower.y * v.height) / DicomVolume.BRICK_LENGTH)
    const y1 = Math.ceil((bBUpper.y * v.height) / DicomVolume.BRICK_LENGTH)

    const z0 = Math.floor((bBLower.z * v.imageCount) / DicomVolume.BRICK_LENGTH)
    const z1 = Math.ceil((bBUpper.z * v.imageCount) / DicomVolume.BRICK_LENGTH)

    for (let z = z0; z < z1; z++) {
      for (let y = y0; y < y1; y++) {
        for (let x = x0; x < x1; x++) {
          const p = x + y * wBCount + z * wBCount * hBCount
          if (bricks[p] >= minVal) {
            //boxCnt++
            const mx = Math.max((x * DicomVolume.BRICK_LENGTH) / v.width, bBLower.x)
            const my = Math.max((y * DicomVolume.BRICK_LENGTH) / v.height, bBLower.y)
            const mz = Math.max((z * DicomVolume.BRICK_LENGTH) / v.imageCount, bBLower.z)
            const maxx = Math.min(Math.min((x + 1) * DicomVolume.BRICK_LENGTH, v.width) / v.width, bBUpper.x)
            const maxy = Math.min(Math.min((y + 1) * DicomVolume.BRICK_LENGTH, v.height) / v.height, bBUpper.y)
            const maxz = Math.min(Math.min((z + 1) * DicomVolume.BRICK_LENGTH, v.imageCount) / v.imageCount, bBUpper.z)

            const cube = [
              // front
              mx,
              my,
              maxz,
              maxx,
              my,
              maxz,
              maxx,
              maxy,
              maxz,
              mx,
              maxy,
              maxz,
              // back
              mx,
              my,
              mz,
              maxx,
              my,
              mz,
              maxx,
              maxy,
              mz,
              mx,
              maxy,
              mz,
            ]
            const v2: number[] = []
            for (let v = 0; v < cube.length; v += 3) {
              const hash = cube[v] * 12345 + cube[v + 1] * 93487 + cube[v + 2]
              let vidx = verticesIdx.get(hash)
              if (vidx === undefined) {
                vidx = vertices.length / 3
                vertices.push(cube[v])
                vertices.push(cube[v + 1])
                vertices.push(cube[v + 2])
                verticesIdx[hash] = vidx
              }
              v2.push(vidx)
            }
            for (let fidx = 0; fidx < cubeFaces.length; fidx += 3) {
              faces.push(v2[cubeFaces[fidx]])
              faces.push(v2[cubeFaces[fidx + 1]])
              faces.push(v2[cubeFaces[fidx + 2]])
            }
          }
        }
      }
    }
    // console.log('vcnt:' + vertices.length)
    // console.log('fcnt:' + faces.length)
    // console.log('box:' + boxCnt)
    if (this.brickMesh) {
      this.scene.remove(this.brickMesh)
    }
    // todo setup geometry
    const bg = new THREE.BufferGeometry()
    bg.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
    bg.setIndex(new THREE.Uint32BufferAttribute(faces, 1))

    this.brickMesh = new THREE.Mesh(bg, this.frontPassPositionMaterial)
    this.brickMesh.layers.set(C.LAYER_VOLUME)
    UpdateObjectFromMatrix(this.brickMesh, this.props.volume.transform)
    const s2 = this.brickMesh.scale
    s2.multiply(new THREE.Vector3(this.volume.width, this.volume.height, this.volume.imageCount))
    this.brickMesh.scale.copy(s2)
    this.scene.add(this.brickMesh)
  }

  render() {
    //const [t] = useTranslation()
    //const editorConfig = useSelector((state: M.AppState) => state.case.editorConfig) as M.EditorConfig
    const calcWidth = Math.max(this.props.size.width, this.MIN_X)
    const calcHeight = Math.max(this.props.size.height, this.MIN_Y)
    this.sizeInPixel = new Vector2(calcWidth * window.devicePixelRatio, calcHeight * window.devicePixelRatio)
    // console.log("www2:"+JSON.stringify(this.sizeInPixel));
    return (
      <>
        <div
          id='drawer-container'
          style={{
            backgroundColor: '#000000',
            height: calcHeight,
            width: calcWidth,
            overflow: 'hidden',
            cursor: getCursor(this.state.controlMethod),
          }}
        >
          <canvas
            id='volumePanel'
            ref={this.canvasRef}
            width={this.sizeInPixel.x}
            height={this.sizeInPixel.y}
            style={{ width: calcWidth, height: calcHeight }}
          />
        </div>
        {this.props.editorConfig.appMode !== M.EAppMode.VIEWER && (
          <>
            <div
              style={{
                position: 'absolute',
                left: calcWidth - 52,
                top: 0,
                width: 100,
                zIndex: 20,
                marginTop: '5px',
              }}
            >
              <Fab
                color='secondary'
                aria-label='menu'
                size='medium'
                onClick={() => {
                  this.props.setPanelOpen('menu', true)
                }}
              >
                <MenuIcon />
              </Fab>
            </div>
            <div
              style={{
                position: 'absolute',
                left: calcWidth - 44,
                top: 100,
                width: 100,
                zIndex: 20,
                marginTop: '5px',
              }}
            >
              <Fab
                color='primary'
                aria-controls='FinishEdit-menu'
                aria-haspopup='true'
                size='small'
                onClick={() => {
                  this.props.setPanelOpen('settings', true)
                }}
              >
                <LayersIcon />
              </Fab>
            </div>
            {this.props.editorConfig.appMode === M.EAppMode.PROTHETICS_PREPARE && (
              <div
                style={{
                  position: 'absolute',
                  left: calcWidth - 44,
                  top: 200,
                  width: 100,
                  zIndex: 20,
                  marginTop: '5px',
                }}
              >
                <ScreenShotButton />
              </div>
            )}
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 200,
                zIndex: 20,
                marginTop: '5px',
              }}
            ></div>
          </>
        )}

        {this.props.zoom.screenLayout !== M.EScreenLayout.LEGACY && (
          // !this.props.zoom.panoramaZoomed &&
          <>
            <div
              style={{
                position: 'absolute',
                left: 80,
                top: calcHeight - 50,
                width: '100%',
                zIndex: 20,
              }}
            >
              <Box display='flex' justifyContent='center' flexDirection='row' alignContent='center'>
                <VolumeDown color='primary' />
                <ConfigureISOLevel />
                <VolumeUp color='primary' />
              </Box>
            </div>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: M.AppState): IVolumePanelComponentProps => {
  const rv = {
    customObjects: state.case.customObjects,
    volumeStatic: state.case.volumeStatic,
    volume: state.case.volume,
    slice: state.case.slice,
    visual: state.case.visualConfig,
    editorConfig: state.case.editorConfig,
    volumePanel: state.volumePanel,
    zoom: state.zoom,
    assets: state.assets,
  }
  for (const k of kImpressions) {
    rv[k] = state.case[k]
  }
  return rv as IVolumePanelComponentProps
}

// eslint-disable-next-line react-redux/mapDispatchToProps-returns-object
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...A.Objects, ...A.Assets, ...A.VolumePanel, ...A.Zoom, ...A.Case }, dispatch)
// {<Paper><Button onClick={() => this.updateCropState()}>sdfsdf</Button></Paper>}
export const VolumePanel = connect(mapStateToProps, mapDispatchToProps)(VolumePanelComponent)
