import * as THREE from 'three'
// class Views;
import { WEBGL } from 'three/examples/jsm/WebGL.js'

export class Renderer {
  public renderer: THREE.WebGLRenderer

  //  private views : Views[] = []
  public internalCanvas

  public context

  constructor() {
    if (WEBGL.isWebGL2Available() === false) {
      // console.log('WEBGL2 is not available')
    }
    this.internalCanvas = document.createElement('canvas')
    this.context = this.internalCanvas.getContext('webgl2', { alpha: false })
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      context: this.context,
      canvas: this.internalCanvas,
    })
    this.renderer.setPixelRatio(1)
  }

  render(canvas: HTMLCanvasElement, rendercallback: (r: Renderer) => void) {
    if (!canvas) {
      return
    }
    const { width } = canvas
    const { height } = canvas

    if (width > this.renderer.domElement.width || height > this.renderer.domElement.height) {
      // console.log(`renderer resized to ${width}:${height}`)
      this.renderer.setSize(
        Math.max(Math.floor(width + 0.9999), this.renderer.domElement.width),
        Math.max(Math.floor(height + 0.9999), this.renderer.domElement.height)
      )
    }
    const viewport = new THREE.Vector4()
    const scissorArea = new THREE.Vector4()
    const scissorTest = this.renderer.getScissorTest()
    //this.renderer.setClearColor(0xffffff)
    this.renderer.setClearColor(0xff00ff)
    this.renderer.clear(true, true, true)

    this.renderer.getViewport(viewport)
    this.renderer.getScissor(viewport)
    this.renderer.setScissorTest(true)
    this.renderer.setViewport(0, 0, width, height)
    this.renderer.setScissor(0, 0, width, height)
    // this.renderer.setSize(width,height);
    rendercallback(this)
    this.renderer.setScissorTest(scissorTest)
    this.renderer.setViewport(viewport)
    this.renderer.setScissor(scissorArea)

    // copy as 2D from the FB to the canvas
    const context = canvas.getContext('2d')

    if (context) {
      context.drawImage(
        this.renderer.domElement,
        0,
        this.renderer.domElement.height - height,
        width,
        height,
        // 0, 0, this.renderer.domElement.width, this.renderer.domElement.height,
        // 0, 0, width, height
        0,
        0,
        width,
        height
      )
    }
  }

  public static instance: Renderer

  static get(): Renderer {
    if (!Renderer.instance) {
      Renderer.instance = new Renderer()
    }
    return Renderer.instance
  }
}
