/** * Imports ** */
import { Matrix4 } from 'three'
import { DicomVolume } from 'models/case/DicomVolume'
import { Range } from 'utils/Range'
import { Loader } from 'utils/Loader'
import * as THREE from 'three'

const Filters = require('utils/SVVPostProcess')
// let zlib = require('third-party/node-zlib.js')
const zlib = require('browserify-zlib')
/* interface IDCLDHeader {
        imageCount: volume.imageCount,
        height: volume.height,
        width: volume.width,
        voxelSize: {...volume.voxelSize},
        pixelSpacing: {...volume.pixelSpacing},
        center: {...volume.center},
        bbLower: {...volume.bBLower},
        bbUpper: {...volume.bBUpper},
        diameter: volume.diameter,
        orientation: volume.orientation,
        transform: volume.transform.elements,
        range: {...volume.range}
} */

export class SlicedVolumeLoader {
  private filterTypes: Uint8Array | undefined

  public volume: DicomVolume = new DicomVolume()

  private loader: Loader | undefined

  public loadFromBuffer(
    floatBase: number,
    targetBase: number,
    floatDiv: number,
    buffer: Int16Array,
    min: number,
    max: number,
    w: number,
    h: number,
    c: number,
    voxelSize: number
  ) {
    this.volume.dataInFloat16 = true
    this.volume.rawF16 = true
    this.volume.floatBase = floatBase
    this.volume.targetBase = targetBase
    this.volume.floatDiv = floatDiv
    this.volume.compressionMethod = 1
    this.volume.data = buffer as any as Uint16Array
    this.volume.imageCount = c
    this.volume.height = h
    this.volume.width = w
    this.volume.voxelSize = new THREE.Vector3(voxelSize, voxelSize, voxelSize)
    this.volume.setPixelSpacing(this.volume.voxelSize) //new THREE.Vector3(1, 1, 1 )
    this.volume.center = new THREE.Vector3((w * voxelSize) / 2, (h * voxelSize) / 2, (c * voxelSize) / 2)
    this.volume.setBBLower(new THREE.Vector3(0, 0, 0))
    this.volume.setBBUpper(new THREE.Vector3(w, h, c))
    this.volume.diameter = Math.sqrt(w * w + h * h + c * c)
    this.volume.orientation = [1, 0, 0, 0, 1, 0]
    this.volume.transform = new Matrix4()
    this.volume.range = new Range(min, max, min, max, false)
    this.volume.transform = new THREE.Matrix4().identity()
    //volume.transform.transpose()
    this._initMatrices()
  }

  _initMatrices() {
    const { volume } = this
    if (!volume) {
      return
    }
    const scaleM = new THREE.Matrix4().makeScale(volume.width, volume.height, volume.imageCount)
    // a volumen matrix a bblower-ben indul es nem normalizalt a volumen meretre
    const volumeRealWorldMatrix = new THREE.Matrix4()
    volumeRealWorldMatrix.multiplyMatrices(volume.transform, scaleM)
    const worldToVolumeSpace = new THREE.Matrix4()
    worldToVolumeSpace.getInverse(volumeRealWorldMatrix)
    volume.volumeSpaceToWorld = volumeRealWorldMatrix
    volume.worldToVolumeSpace = worldToVolumeSpace
  }

  async extractPixelData(
    frameIndex = 0,
    url: string,
    loader: any,
    buffer: Uint16Array,
    offset: number
  ): Promise<number> {
    // X
    const { volume } = this
    const idx = url.lastIndexOf('/')
    url = url.substring(0, idx + 1) + `000${frameIndex}`.slice(-4)
    const data = await loader.fetch(url)

    // const d8 = new Uint8Array(data.buffer);
    // var plain = new Int8Array(zlib.inflateSync(d8));
    const plain = new Int8Array(zlib.inflateSync(Buffer.from(data.buffer)))
    const i = frameIndex | 0
    const w = volume.width | 0
    const h = volume.height | 0

    const rbData = plain

    if (this.filterTypes) {
      for (let j = 0; j < (volume.height | 0); j++) {
        // Filter type
        const typeMSB = this.filterTypes[i * (h * 2) + j]
        if (typeMSB === 0) {
          // Do nothing
        } else if (typeMSB === 1) {
          Filters.backFilterSub(rbData, j * w, w)
        } else if (typeMSB === 2) {
          Filters.backFilterUp(rbData, j * w, w)
        } else if (typeMSB === 3) {
          Filters.backFilterAverage(rbData, j * w, w)
        } else if (typeMSB === 4) {
          Filters.backFilterPaeth(rbData, j * w, w, i, j)
        } else {
          //console.log('xx')
        }

        // Filter type
        const typeLSB = this.filterTypes[i * (h * 2) + h + j]
        if (typeLSB === 0) {
          // Do nothing
        } else if (typeLSB === 1) {
          Filters.backFilterSub(rbData, w * h + j * w, w)
        } else if (typeLSB === 2) {
          Filters.backFilterUp(rbData, w * h + j * w, w)
        } else if (typeLSB === 3) {
          Filters.backFilterAverage(rbData, w * h + j * w, w)
        } else if (typeLSB === 4) {
          Filters.backFilterPaeth(rbData, w * h + j * w, w, i, j)
        } else {
          //console.log('xx')
        }
      }
    }
    // let retData = new Int16Array(volume.width * volume.height);
    // Convert to shorts
    const level = (h * w) | 0
    for (let ii = 0 | 0; ii < level; ii++) {
      const msbIndex = ii
      const lsbIndex = h * w + ii
      const dataIndex = ii
      const v = (rbData[msbIndex] << 8) + (rbData[lsbIndex] & 0xff)
      buffer[dataIndex + offset] = v + 32768
    }
    this.volume.addLevelToBricks(buffer, offset, frameIndex)
    return frameIndex
  }
}
