import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import * as M from 'models'
import * as A from 'actions'

import { useTranslation } from 'react-i18next'

// import ViewFront from 'static/images/icons/view-front.png'
// import ViewBack from 'static/images/icons/view-back.png'
// import ViewLeft from 'static/images/icons/view-left.png'
// import ViewRight from 'static/images/icons/view-right.png'
// import ViewTop from 'static/images/icons/view-top.png'
// import ViewBottom from 'static/images/icons/view-bottom.png'

const drawerWidth = 260

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    viewButton: {
      color: theme.palette.primary.light,
    },
  })
)
export default function SetttingsDrawer(props: { open: boolean; openFunc: any }): JSX.Element {
  const classes = useStyles()
  const state = useSelector((state: M.AppState) => state.case) as M.CaseState
  const dispatch = useDispatch()

  const [t] = useTranslation()

  const getTranslatedText = (name: string): string => {
    return t(`sgjs.${name}`)
  }

  let layers = [[M.EObjectGroup.CROPPEDVISIBLE, state.editorConfig.croppedVisible, getTranslatedText('cropped')]]
  if (state.volume) {
    layers = layers.concat([[M.EObjectGroup.VOLUME, state.volume.visible, getTranslatedText('volume')]])
  }
  if (state.slice) {
    layers = layers.concat([[M.EObjectGroup.SLICE, state.slice.visible, getTranslatedText('slice')]])
  }

  const handleDrawerClose = () => {
    props.openFunc(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, v: M.EObjectGroup) => {
    dispatch(A.Objects.setObjectGroupVisible(v, event.target.checked))
  }

  //   const handleViewDirectionChange = (direction: M.ViewDirection) => {
  //     dispatch(A.VolumePanel.setViewDirection(direction))
  //   }

  return (
    <>
      <Drawer
        className={classes.drawer}
        anchor='right'
        open={props.open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton color='primary' onClick={handleDrawerClose}>
            <Typography color='textPrimary'>{getTranslatedText('layersTitle')}</Typography>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {layers.map((l) => (
            <ListItem key={l[0] as string}>
              <FormControlLabel
                control={
                  <Switch
                    checked={l[1] as boolean}
                    onChange={(e) => handleChange(e, l[0] as M.EObjectGroup)}
                    name={l[2] as string}
                  />
                }
                label={l[2]}
              />
            </ListItem>
          ))}

          {/* ['TopDown', 'Front2Back', 'RightLeft'].map((text, index) => (
                        <ListItem button key={text}>
                            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    )) */}
        </List>
        <Divider />
        <div className={classes.drawerHeader}>
          <IconButton color='primary' onClick={handleDrawerClose}>
            <Typography color='textPrimary'>{getTranslatedText('viewsTitle')}</Typography>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        {/* <Grid container spacing={1} style={{ padding: 8, width: '100%' }} direction='column'>
          <Grid container item direction='row' justify='space-evenly' alignContent='center'>
            <Grid item>
              <Fab className={classes.viewButton} onClick={() => handleViewDirectionChange(M.ViewDirection.FRONT)}>
                <ViewFront />
              </Fab>
            </Grid>
            <Grid item>
              <Fab className={classes.viewButton} onClick={() => handleViewDirectionChange(M.ViewDirection.BACK)}>
                <ViewBack />
              </Fab>
            </Grid>
          </Grid>
          <Grid container item justify='space-evenly' direction='row' alignContent='center'>
            <Grid item>
              <Fab className={classes.viewButton} onClick={() => handleViewDirectionChange(M.ViewDirection.LEFT)}>
                <ViewLeft />
              </Fab>
            </Grid>
            <Grid item>
              <Fab className={classes.viewButton} onClick={() => handleViewDirectionChange(M.ViewDirection.RIGHT)}>
                <ViewRight />
              </Fab>
            </Grid>
          </Grid>
          <Grid container item justify='space-evenly' direction='row' alignContent='center'>
            <Grid item>
              <Fab className={classes.viewButton} onClick={() => handleViewDirectionChange(M.ViewDirection.TOP)}>
                <ViewTop />
              </Fab>
            </Grid>
            <Grid item>
              <Fab className={classes.viewButton} onClick={() => handleViewDirectionChange(M.ViewDirection.BOTTOM)}>
                <ViewBottom />
              </Fab>
            </Grid>
          </Grid>
        </Grid> */}
      </Drawer>
    </>
  )
}

// <Divider />
