import { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Button,
  IconButton,
  MenuItem,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 25,
    backgroundColor: theme.palette.common.white,
  },
  iconButton: {
    border: ({ open }) => `2px solid ${open ? 'white' : 'transparent'}`,
    borderRadius: 25,
    padding: 5,
    color: ({ open }) => (open ? theme.palette.primary.main : 'white'),
    backgroundColor: ({ open }) => (open ? theme.palette.primary.light : 'transparent'),

    '&:hover': {
      borderColor: 'white',
      backgroundColor: ({ open }) => (open ? theme.palette.primary.light : 'transparent'),
    },
  },
  panel: {
    padding: 4,
    marginTop: 4,
  },
  panelContainer: {
    padding: 0,
  },
}))

const Dropdown = ({
  icon: Icon,
  title,
  children: content,
  marker,
  center,
  onOpen,
  onClose,
  togglerProps,
  popperProps,
  paperProps,
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const classes = useStyles({ open })

  const handleToggle = (e) => {
    e.stopPropagation()
    setOpen((prevOpen) => {
      if (prevOpen && onClose) {
        onClose()
      }
      if (!prevOpen && onOpen) {
        onOpen()
      }
      return !prevOpen
    })
  }

  return !(title || Icon) ? null : (
    <>
      {Icon && !title ? (
        <IconButton classes={{ root: classes.iconButton }} ref={anchorRef} onClick={handleToggle}>
          <Icon />
        </IconButton>
      ) : (
        <Button
          variant='contained'
          className={classes.button}
          ref={anchorRef}
          onClick={handleToggle}
          startIcon={Icon && <Icon />}
          endIcon={marker && <ExpandMoreIcon />}
          {...togglerProps}
        >
          {title}
        </Button>
      )}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-end'
        transition
        disablePortal
        style={{ zIndex: 100000 }}
        {...popperProps}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: `${center ? 'center' : 'left'} top` }}>
            <Paper className={classes.panel} {...paperProps}>
              <ClickAwayListener onClickAway={handleToggle}>
                <MenuList
                  classes={{ root: classes.panelContainer }}
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onClick={handleToggle}
                >
                  {content}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

Dropdown.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.element,
  title: PropTypes.string,
  marker: PropTypes.bool,
  center: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  togglerProps: PropTypes.object,
  popperProps: PropTypes.object,
  paperProps: PropTypes.object,
}

const useListItemStyles = makeStyles((theme) => ({
  root: {
    padding: '12px 6px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  selected: {
    pointerEvents: 'none',
    color: 'white',
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}))

const useListItemIconStyles = makeStyles(() => ({
  icon: {
    color: 'white',
    minWidth: 0,
    paddingRight: 6,
  },
}))

const DropdownItem = ({ icon: Icon, label, selected, disabled, ...rest }) => {
  const classes = useListItemStyles()
  const iconClasses = useListItemIconStyles()

  return (
    <MenuItem alignItemsFlexStart classes={classes} disabled={disabled} selected={selected} {...rest}>
      {Icon && (
        <ListItemIcon classes={{ root: iconClasses.icon }}>
          <Icon color={selected ? 'white' : 'primary'} style={{ fontSize: 24 }} />
        </ListItemIcon>
      )}
      {label && <Typography variant='inherit'>{label}</Typography>}
    </MenuItem>
  )
}

DropdownItem.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
}

const DropdownSelect = ({ data, value, onChange, placeholder, ...rest }) => {
  const selectedItem = data.find(({ value: rowValue, id }) => rowValue === value || id === value)

  return (
    <Dropdown icon={selectedItem?.icon} title={selectedItem?.label || placeholder} {...rest}>
      {data.map(({ id, value: rowValue, label: rowLabel, icon: rowIcon }) => (
        <DropdownItem
          key={id}
          alignItemsFlexStart
          icon={rowIcon}
          label={rowLabel}
          selected={selectedItem?.id === id}
          onClick={() => {
            if (onChange && selectedItem?.value !== rowValue) {
              onChange(rowValue)
            }
          }}
        />
      ))}
    </Dropdown>
  )
}

DropdownSelect.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
      icon: PropTypes.func,
    })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  marker: PropTypes.bool,
}

export { Dropdown, DropdownItem }
export default DropdownSelect
