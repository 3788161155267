import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { NORMAL_BLUR, DEFAULT_TRANSITION } from './constants'
import { ReactComponent as DicomLabLogo } from 'static/assets/dicomLAB_logo_dark.svg'

const BACKDROP_GRADIENT = 'linear-gradient(180deg, #000000B0 0%, #00000059 28%, #0000005C 100%)'
const BG_COVER_WEIGHT_HORIZONTAL = '20%'

const useStyles = makeStyles(() => {
  return {
    backdrop: {
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: BACKDROP_GRADIENT,
        opacity: 0,
        transition: `opacity ${DEFAULT_TRANSITION.replace('0.3s', '0.9s')}`,
      },
    },
    bg: {
      pointerEvents: 'none',
      position: 'fixed',
      left: `calc(${-2 * NORMAL_BLUR}px)`,
      top: `calc(${-2 * NORMAL_BLUR}px)`,
      height: `calc( 100vh + ${4 * NORMAL_BLUR}px )`,
      width: `calc( 100vw + ${4 * NORMAL_BLUR}px )`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: BG_COVER_WEIGHT_HORIZONTAL,
      backgroundSize: 'cover',
      filter: 'none',
    },
  }
})

const Background = (props) => {
  const classes = useStyles()

  return (
    <Box id='background' className={classes.backdrop} {...props}>
      <DicomLabLogo className={classes.bg} />
    </Box>
  )
}

export default Background
