import { themingActions } from '../actions/ThemingActions'

const initialState = {
  selectedTheme: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case themingActions.SET_TENANT_THEMES:
      return {
        ...state,
        selectedTheme: state.selectedTheme ? state.selectedTheme : action.themes[0],
      }
    case themingActions.SET_THEME:
      return {
        ...state,
        selectedTheme: action.theme,
      }
    default:
      return state
  }
}
