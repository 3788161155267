import { InternalWindowMessage } from 'models/InternalWindowMessage'

export const internalWindowMessageActions = {
  SEND_MESSAGE: 'SEND_MESSAGE',
}

export interface IInternalWindowMessageActions {
  sendMessage(req: InternalWindowMessage)
}

export const impl: IInternalWindowMessageActions = {
  sendMessage:
    (msg: InternalWindowMessage): any =>
    (dispatch: any) => {
      dispatch({
        type: internalWindowMessageActions.SEND_MESSAGE,
        msg,
        data: { disableUndo: true },
      })
    },
}
