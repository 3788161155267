import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { ListItemIcon, ListItemText, Typography, Radio, Box } from '@mui/material'
import { ToggleButton, ToggleButtonGroup } from '@mui/lab'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ({ simple }) => (simple ? 'transparent' : theme.palette.grey[100]),
    borderWidth: '2px !important',
    borderColor: ({ simple }) => (simple ? 'transparent' : theme.palette.grey[200]),
    borderStyle: 'solid',
    borderRadius: '12px !important',
    margin: 18,
    flexGrow: 1,
    maxWidth: 'calc( 50% - 36px )',
    alignItems: 'flex-start',

    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[300],
    },

    '& .MuiListItemText-root': {
      margin: 17,
    },

    '& .controlLabel': {
      color: theme.palette.primary.dark,
      '& .MuiTypography-root': {
        color: theme.palette.common.black,
        fontWeight: 500,

        '& .hl': {
          color: theme.palette.grey[500],
        },
      },
    },

    '& .MuiListItemText-secondary': {
      textTransform: 'none',
      fontSize: 14,
      whiteSpace: 'break-spaces',
      color: theme.palette.common.black,
    },

    '&$selected': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,

      '& + .MuiToggleButton-root.Mui-selected': {
        border: `2px solid ${theme.palette.primary.main}`,
        margin: 18,
      },

      '& .controlLabel': {
        '& .MuiRadio-root': {
          color: theme.palette.primary.dark,
          pointerEvents: 'none',
        },
        '& .MuiTypography-root': {
          color: theme.palette.primary.dark,
        },
        '& .hl': {
          color: theme.palette.primary.main,
        },
      },

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.main,
      },
    },

    '&$disabled': {
      opacity: '50%',
    },
  },

  selected: {},
  disabled: {},
}))

const useIconWrapStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    width: ({ inset }) => (inset ? 130 : 80),
    height: ({ inset }) => (inset ? 156 : 80),
    margin: 12,
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: ({ inset }) => (inset ? 'flex-start' : 'center'),
    borderRadius: 12,
    boxShadow: '0px 4px 9px #00000014',

    '& .MuiSvgIcon-root': {
      minHeight: 60,
    },

    '& .MuiTypography-root': {
      textTransform: 'none',
    },
  },
}))

const useGroupStyles = makeStyles({
  root: {
    position: 'relative',
    minHeight: 100,
    flexGrow: 1,
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
})

const useDisplayItemStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: ({ hasText }) => (hasText ? 'flex-start' : 'center'),

    '& .MuiTypography-root': {
      textTransform: 'none',
      textAlign: ({ center }) => (center ? 'center' : 'left'),
    },
  },
})

const CustomRadioDisplayItem = ({ icon, lines, lineTextSize, center, linePadding, lineMarker }) => {
  const classes = useDisplayItemStyles({ hasText: Boolean(lines), center })
  const { t } = useTranslation()
  const iconWrapClasses = useIconWrapStyles()
  const linesArray = lines ? (Array.isArray(lines) ? lines : [lines]) : []

  return (
    <Box classes={classes} style={{ width: center ? '100%' : 'inherit' }}>
      {icon && <ListItemIcon classes={iconWrapClasses}>{icon}</ListItemIcon>}
      <Box display='flex' flexDirection='column' alignSelf='center' style={{ width: center ? '100%' : 'inherit' }}>
        {linesArray.map((descLine) => (
          <Typography style={{ fontSize: lineTextSize || undefined, padding: linePadding || undefined }} key={descLine}>
            {lineMarker || ''} {descLine?.params ? t(descLine.key, descLine.params) : t(descLine)}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}

CustomRadioDisplayItem.propTypes = {
  icon: PropTypes.element,
  lines: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  center: PropTypes.bool,
  lineMarker: PropTypes.string,
  lineTextSize: PropTypes.string,
  linePadding: PropTypes.string,
}

const CustomRadioInsetDisplayItem = ({ icon, lines }) => {
  const classes = useIconWrapStyles({ inset: true })
  const { t } = useTranslation()
  const linesArray = Array.isArray(lines) ? lines : [lines]

  return (
    <Box classes={classes}>
      {icon}
      {linesArray.map((descLine) => (
        <Typography key={descLine}>{t(descLine)}</Typography>
      ))}
    </Box>
  )
}

CustomRadioInsetDisplayItem.propTypes = {
  icon: PropTypes.element,
  lines: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

const CustomRadio = ({
  value,
  selected,
  simple,
  icon,
  label,
  desc,
  disabled: disabledRoot,
  displayItems,
  inlineDisplayItems,
  children,
  ...rest
}) => {
  const classes = useStyles({ simple })
  const iconClasses = useIconWrapStyles()
  const { t } = useTranslation()

  const disabled = value === undefined || disabledRoot
  const TemplateComp = inlineDisplayItems ? CustomRadioInsetDisplayItem : CustomRadioDisplayItem

  return (
    <ToggleButton
      classes={classes}
      disabled={disabled}
      value={value || ''}
      selected={selected}
      component={Box}
      {...rest}
    >
      <Box flexGrow={1}>
        <Box className='controlLabel' display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
          {value !== undefined && !icon && <Radio checked={selected} value={value} style={{ pointerEvents: 'none' }} />}
          {icon && <ListItemIcon classes={iconClasses}>{icon}</ListItemIcon>}
          <ListItemText
            disableTypography
            style={{ margin: 0, textAlign: 'left', flexGrow: 1 }}
            primary={typeof label === 'string' ? <Typography>{t(label)}</Typography> : label}
            secondary={typeof desc === 'string' ? <Typography>{t(desc)}</Typography> : desc}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: inlineDisplayItems ? 'row' : 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {displayItems &&
            displayItems.map(({ icon: lineIcon, lines, lineMarker, lineTextSize, linePadding, center }, index) => (
              <TemplateComp
                key={index}
                icon={lineIcon}
                lines={lines}
                center={center}
                lineMarker={lineMarker}
                lineTextSize={lineTextSize}
                linePadding={linePadding}
                inset={inlineDisplayItems}
              />
            ))}
        </Box>
        <Box display='flex' flexGrow={1} flexWrap='no-wrap' flexDirection='column'>
          {children}
        </Box>
      </Box>
    </ToggleButton>
  )
}

CustomRadio.propTypes = {
  value: PropTypes.any,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  simple: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  desc: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  inlineDisplayItems: PropTypes.bool,
  displayItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      lines: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      inset: PropTypes.bool,
    })
  ),
  children: PropTypes.any,
}

const CustomRadioGroup = ({ value, onChange, children, defaultTo, ...rest }) => {
  const classes = useGroupStyles()

  useEffect(() => {
    if (['', null].includes(value) && defaultTo !== undefined) {
      onChange(defaultTo)
    }
  }, [value, defaultTo, onChange])

  return (
    <ToggleButtonGroup
      classes={classes}
      exclusive
      value={value}
      onChange={(e, val) => {
        if (val !== null) {
          onChange(val)
        }
      }}
      {...rest}
    >
      {children}
    </ToggleButtonGroup>
  )
}

CustomRadioGroup.propTypes = {
  value: PropTypes.any,
  defaultTo: PropTypes.any,
  onChange: PropTypes.func,
  children: PropTypes.any,
}

export { CustomRadioGroup, CustomRadioDisplayItem, CustomRadioInsetDisplayItem }
export default CustomRadio
