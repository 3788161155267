import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createStyles } from '@mui/styles'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'

import Typography from '@mui/material/Typography'

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import * as M from 'models'
import * as A from 'actions'

import { useTranslation } from 'react-i18next'

const drawerWidth = 260

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    viewButton: {
      color: theme.palette.primary.light,
    },
  })
)
export default function ViewerUI(props: { open: boolean; openFunc: any }): JSX.Element {
  const classes = useStyles()
  const state = useSelector((state: M.AppState) => state.case) as M.CaseState
  const dispatch = useDispatch()

  const [t] = useTranslation()

  const getTranslatedText = (name: string): string => {
    return t(`sgjs.${name}`)
  }

  const handleDrawerClose = () => {
    props.openFunc(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
    dispatch(A.Objects.setObjectVisible(uuid, event.target.checked))
  }

  //   const handleViewDirectionChange = (direction: M.ViewDirection) => {
  //     dispatch(A.VolumePanel.setViewDirection(direction))
  //   }

  return (
    <>
      <Drawer
        className={classes.drawer}
        anchor='left'
        open={props.open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton color='primary' onClick={handleDrawerClose}>
            <Typography color='textPrimary'>{getTranslatedText('layersTitle')}</Typography>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {Object.keys(state.customObjects).map((l) => (
            <ListItem key={l}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.customObjects[l].visible}
                    onChange={(e) => handleChange(e, l)}
                    name={state.customObjects[l].name}
                  />
                }
                label={`${state.customObjects[l].name}`}
              />
            </ListItem>
          ))}

          {/* ['TopDown', 'Front2Back', 'RightLeft'].map((text, index) => (
                        <ListItem button key={text}>
                            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    )) */}
        </List>
        <Divider />
      </Drawer>
    </>
  )
}

// <Divider />
