import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Box } from '@mui/material'
import { PAGE_MIN_WIDTH, PAGE_MAX_WIDTH } from './constants'

const CenterGridContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
`
const CenterGridItem = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: ${PAGE_MIN_WIDTH / 2}px;
  width: ${({ cols }) => PAGE_MAX_WIDTH / cols}px;
  padding: 5px;
`

const CenterGridLayout = ({ cols, items, template: Template }) => {
  return (
    <CenterGridContainer>
      {items.map((props) => (
        <CenterGridItem key={props.id} cols={cols}>
          <Template {...props} />
        </CenterGridItem>
      ))}
    </CenterGridContainer>
  )
}

CenterGridLayout.propTypes = {
  cols: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  template: PropTypes.func.isRequired,
  id: PropTypes.any,
}

CenterGridLayout.defaultProps = {
  cols: 3,
}

export default CenterGridLayout
