export class CopyShader {
  public static uniforms = {
    tDiffuse: { value: null },
    opacity: { value: 1.0 },
  }

  public static vertexShader = `   varying vec2 vUv;
        void main() {
        	vUv = uv;
        	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
        `

  public static fragmentShader = `
        uniform float opacity;
        uniform sampler2D tDiffuse;
        varying vec2 vUv;
        void main() {
        	vec4 texel = texture2D( tDiffuse, vUv );
        	gl_FragColor = opacity * texel;
        }
        `

  public static defines = undefined
}
