import * as THREE from 'three'
import vs from './VolumeRenderShader.vs'
import fs from './VolumeRenderShader.fs'
// const vs = require('./VolumeRenderShader.vs').default
// const fs = require('./VolumeRenderShader.fs').default

export class VolumeRenderShader {
  public static uniforms = {
    tFrontPos: { value: null },
    tShapeColor: { value: null },
    tDirVectors: { value: null },
    tShapeDepth: { value: null },
    tVolumeTex: { value: null },
    tEnvMap: { value: null },
    isoLevel: { value: 1.0 },
    uAcceleratorTextureScaleRatio: { value: new THREE.Vector3(0, 0, 0) },
    volumeTextureSize: { value: new THREE.Vector3(0, 0, 0) },
    ScreenSize: { value: new THREE.Vector2(0, 0) },
    IsoColor: { value: new THREE.Vector3(0, 0, 0) },
    CameraPosition: { value: new THREE.Vector3(0, 0, 0) },
    LightPosition: { value: new THREE.Vector3(0, 0, 0) },
    Diffuse: { value: new THREE.Vector4(0, 0, 0, 0) },
    Specular: { value: new THREE.Vector4(0, 0, 0, 0) },
    Ambient: { value: new THREE.Vector4(0, 0, 0, 0) },
    Attenuation: { value: new THREE.Vector3(0, 0, 0) },
    BackToFrontDir: { value: new THREE.Vector3(0, 0, 0) },
    OModelMatrix: { value: new THREE.Matrix4() },
    InvModelMatrix: { value: new THREE.Matrix4() },
    InverseViewProjection: { value: new THREE.Matrix4() },
    IM: { value: new THREE.Matrix4() },
    Shininess: { value: 1.0 },
    uFloatBase: { value: 1.0 },
    uFloatDiv: { value: 1.0 },
    uTargetBase: { value: 1.0 },
    uResolution: { value: 0.0 },
    uResolutonPow: { value: 1.0 },
  }

  public static vertexShader = vs

  public static fragmentShader = fs

  public static defines = null // ['#version 300 es'];
}
