export const PAGE_MIN_WIDTH = 320
export const PAGE_MAX_WIDTH = 1120
export const PAGE_ADMIN_MIN_WIDTH = 320
export const PAGE_ADMIN_MAX_WIDTH = 1600
export const NORMAL_BLUR = 4
export const DEFAULT_TRANSITION = '0.3s ease-out'

export const COLOR = {
  NEUTRAL: '#eeb95d',
  RED: '#e56767',
  BLUE: '#438ac9',
  GREEN: '#55b559',
}
