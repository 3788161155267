export enum EScreenLayout { // goes to Zoom
  LEGACY = 1,
  E3DFOCUS = 2,
}

export class Zoom {
  // screenLayout: M.EScreenLayout.E3DFOCUS,
  // panoramaZoomed: false
  screenLayout: EScreenLayout = EScreenLayout.LEGACY

  panoramaZoomed = false

  settings = true

  menu = true
  loadingProgressVisible = false
  loadingProgressPcnt = 0
  loadingProgressText = ''
  //measureLength : number | undefined
}
