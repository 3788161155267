import { combineReducers } from 'redux'

import loading from './Loading'
import volume from './Volume'
import volumeStatic from './VolumeStatic'

import customObjects from './CustomObjects'

import visualConfig from './VisualConfig'

import editorConfig from './EditorConfig'
import preview from './Preview'

export default combineReducers({
  loading,
  volume,
  volumeStatic,
  customObjects,
  visualConfig,
  editorConfig,
  preview,
})
