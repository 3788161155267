import * as THREE from 'three'

export function UpdateObjectFromMatrix(o: THREE.Object3D, m: THREE.Matrix4) {
  const tr = new THREE.Vector3()
  const rotQ = new THREE.Quaternion()
  const sc = new THREE.Vector3()
  // let m2 = new THREE.Matrix4();
  // m2.copy(m).transpose();
  m.decompose(tr, rotQ, sc)
  //const rotE = new THREE.Euler().setFromQuaternion(rotQ) // use default Euler Order
  o.rotation.setFromQuaternion(rotQ)
  o.position.copy(tr)
  o.scale.copy(sc)
}
export function clamp(val: number, min: number, max: number): number {
  return Math.max(Math.min(val, max), min)
}
