/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-types */
import { Typography, Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { getColorFromTheme, PPCTheme } from './colorUtils'
import React, { ReactElement } from 'react'

declare interface WithSideLabelStyleInput {
  autoWidth?: boolean
  width?: string
  labelWidth?: string
  labelCenter?: boolean
  disabled?: boolean
  labelColor?: string
  disabledLabelColor?: string
}

const useStyles = makeStyles((theme: PPCTheme & Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: { flexDirection: 'column', alignItems: 'center' },

    flexGrow: 1,

    '& .MuiTextField-root': {
      flexGrow: 1,
    },

    width: ({ autoWidth, width }: WithSideLabelStyleInput) => width || (autoWidth ? 'auto' : '100%'),
  },
  sideLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    textAlign: 'right',
    width: ({ autoWidth, labelWidth }: WithSideLabelStyleInput) => labelWidth || (autoWidth ? 'auto' : '36%'),
    [theme.breakpoints.down('xs')]: { width: 'auto' },

    alignItems: ({ labelCenter }) => (labelCenter ? 'center' : undefined),
  },
  labelStyle: {
    padding: 6,
    paddingRight: 17,
    fontSize: '16px',
    fontWeight: 500,
    color: ({ disabled, labelColor, disabledLabelColor }) =>
      disabled
        ? disabledLabelColor
          ? getColorFromTheme(theme, disabledLabelColor)
          : theme.palette.grey.P300
        : labelColor
        ? getColorFromTheme(theme, labelColor)
        : theme.palette.primary.main,
  },
  sideIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
}))

declare interface WrappedCompProps {
  compact?: boolean
  icon?: ReactElement
  optional?: boolean
  label?: string
  title?: string
  disabled?: boolean
  width?: string
  autoWidth?: boolean
  labelCenter?: boolean
  labelWidth?: string
  labelColor?: string
  disabledLabelColor?: string
  sideLabelStyle?: any
  labelStyle?: any
}

const withSideLabel = <P extends object>(InputComponent: React.ComponentType<P>) => {
  const WrappedComp: React.FC<Omit<P, 'labelWidth'> & WrappedCompProps> = ({
    compact,
    icon,
    optional,
    label,
    title,
    disabled,
    autoWidth,
    width,
    labelWidth,
    labelCenter,
    labelColor,
    disabledLabelColor,
    sideLabelStyle,
    labelStyle,
    ...rest
  }) => {
    const { t } = useTranslation()

    const inputProps = { ...rest, disabled }

    const classes = useStyles({ disabled, autoWidth, labelWidth, width, labelCenter, labelColor, disabledLabelColor })
    const labelText = `${title || label || ''}${optional ? ` (${t('i18n.optional')})` : ''}`
    if (compact && !icon) {
      return <InputComponent {...(inputProps as P)} />
    }

    return (
      <Box className={classes.root} style={sideLabelStyle}>
        {!compact && (
          <Box className={classes.sideLabel}>
            <Typography className={classes.labelStyle} style={labelStyle}>
              {labelText}
            </Typography>
          </Box>
        )}
        {icon && <Box className={classes.sideIcon}>{icon}</Box>}

        <InputComponent {...(inputProps as P)} optional={optional} label={compact ? labelText : ''} />
      </Box>
    )
  }

  return WrappedComp
}

export default withSideLabel
