import * as M from 'models'

export interface IZoomAction {
  setLayout(layout: M.EScreenLayout): any
  setPanoramaZoom(zoom: boolean): any
  // setMeasureLength(measureLength: number|undefined) :any
  setTrayConfigDialog(trayConfigOpen: boolean): any
  setPanelOpen(panelName: string, state: boolean): any
  setProgressState(
    loadingProgressVisible?: boolean,
    loadingProgressText?: string,
    loadingProgressPcnt?: string | number
  ): any
}

export const impl: IZoomAction = {
  /*  setMeasureLength: (measureLength: number|undefined) :any=> (dispatch: any) => {
        dispatch({ type: M.EDITOR_SETMEASURE, data: { measureLength, disableUndo: true } })
    },*/
  setProgressState:
    (loadingProgressVisible?: boolean, loadingProgressText?: string, loadingProgressPcnt?: string | number): any =>
    (dispatch: any) => {
      dispatch({
        type: M.EDITOR_SETPROGRESSSTATE,
        data: {
          loadingProgressVisible,
          loadingProgressText: loadingProgressText ? String(loadingProgressText) : undefined,
          loadingProgressPcnt: loadingProgressPcnt ? String(loadingProgressPcnt) : undefined,
          disableUndo: true,
        },
      })
    },
  setPanelOpen:
    (panelName: string, state: boolean): any =>
    (dispatch: any) => {
      dispatch({ type: M.EDITOR_SETPANELOPEN, data: { panelName, state, disableUndo: true } })
    },
  setLayout:
    (layout: M.EScreenLayout): any =>
    (dispatch: any) => {
      dispatch({ type: M.EDITOR_SETLAYOUT, data: { layout, disableUndo: true } })
    },
  setPanoramaZoom:
    (zoom: boolean): any =>
    (dispatch: any) => {
      dispatch({ type: M.EDITOR_SETPANORAMAZOOM, data: { zoom, disableUndo: true } })
    },
  setTrayConfigDialog:
    (trayConfigOpen: boolean): any =>
    (dispatch: any) => {
      dispatch({ type: M.TRAYCONFIG_OPEN, data: { trayConfigOpen, disableUndo: true } })
    },
}
