/** * Imports ***/
const PAKO = require('pako');
import CoreUtils from '../core/core.utils';
import ModelsSeries from '../models/models.series';
import ModelsStack from '../models/models.stack';
import ModelsFrame from '../models/models.frame';
/*
import LoadersBase from './loaders.base';
import ParsersDicom from '../parsers/parsers.dicom';
import ParsersMhd from '../parsers/parsers.mhd';
import ParsersNifti from '../parsers/parsers.nifti';
import ParsersNrrd from '../parsers/parsers.nrrd';
import ParsersMgh from '../parsers/parsers.mgh';
*/
import ParsersDicom from '../parsers/parsers.dicom';
/**
 *
 * It is typically used to load a DICOM image. Use loading manager for
 * advanced usage, such as multiple files handling.
 *
 * Demo: {@link https://fnndsc.github.io/vjs#loader_dicom}
 *
 * @module loaders/volumes
 * @example
 * var files = ['/data/dcm/fruit'];
 *
 * // Instantiate a dicom loader
 * var lDicomoader = new dicom();
 *
 * // load a resource
 * loader.load(
 *   // resource URL
 *   files[0],
 *   // Function when resource is loaded
 *   function(object) {
 *     //scene.add( object );
 *     console.log(object);
 *   }
 * );
 */

export default class LoadersVolumes {
    public _loaded: number;
    public _totalLoaded: number;
    public _parsed: number;
    public _totalParsed: number;
    public _data = [];
    public _ignoreData: boolean = false;

    constructor() {
        this._loaded = -1;
        this._totalLoaded = -1;
        this._parsed = -1;
        this._totalParsed = -1;
        this._data = [];


    }
    /**
     * Parse response.
     * response is formated as:
     *    {
     *      url: 'resource url',
     *      buffer: xmlresponse,
     *    }
     * @param {object} response - response
     * @return {promise} promise
     */

    emit(event: string, obj: any) {

    }

    async parse(response, _ignoreData, normalizeOrientation): Promise<ModelsSeries> {
        // emit 'parse-start' event
        this.emit('parse-start', {
            file: response.url,
            time: new Date(),
        });
        this._ignoreData = _ignoreData

        //const parsed = await new Promise((resolve, reject) => {
        let data = response;

        if (!Array.isArray(data)) {
            data = [data];
        }

        data.forEach(dataset => {
            this._preprocess(dataset);
        });

        if (data.length === 1) {
            data = data[0];
        } else {
            // if raw/mhd pair
            let mhdFile = data.filter(this._filterByExtension.bind(null, 'MHD'));
            let rawFile = data.filter(this._filterByExtension.bind(null, 'RAW'));
            if (data.length === 2 && mhdFile.length === 1 && rawFile.length === 1) {
                data.url = mhdFile[0].url;
                data.extension = mhdFile[0].extension;
                data.mhdBuffer = mhdFile[0].buffer;
                data.rawBuffer = rawFile[0].buffer;
            }
        }

        let Parser = this._parser('DICOM');
        if (!Parser) {
            // emit 'parse-error' event
            this.emit('parse-error', {
                file: response.url,
                time: new Date(),
                error: data.filename + 'can not be parsed.',
            });
            throw new Error(data.filename + ' can not be parsed.');
        }

        // check extension
        let volumeParser: ParsersDicom | null = null;
        try {
            volumeParser = new Parser(data, 0);
        } catch (e: any) {
            // console.warn(e);
            // emit 'parse-error' event
            this.emit('parse-error', {
                file: response.url,
                time: new Date(),
                error: e,
            });
            throw new Error(e);
            //return;
        }

        // create a series
        let series = new ModelsSeries();
        series.rawHeader = volumeParser.rawHeader();
        // global information
        series.seriesInstanceUID = volumeParser.seriesInstanceUID();
        series.transferSyntaxUID = volumeParser.transferSyntaxUID();
        series.seriesDate = volumeParser.seriesDate();
        series.seriesDescription = volumeParser.seriesDescription();
        series.studyDate = volumeParser.studyDate();
        series.studyDescription = volumeParser.studyDescription();
        series.numberOfFrames = volumeParser.numberOfFrames();
        if (!series.numberOfFrames) {
            series.numberOfFrames = 1;
        }
        series.numberOfChannels = volumeParser.numberOfChannels();
        series.modality = volumeParser.modality();
        const compressionString = volumeParser._dataSet.string('x00082111', undefined);
        if (compressionString) {
            let q = compressionString.match(/Quality=(\d+(\.\d+)?)%/);
            if (q && q[1]) {
                series.compressionRate = Number(q[1]);
            }
        }
        // if it is a segmentation, attach extra information
        if (series.modality === 'SEG') {
            // colors
            // labels
            // etc.
            series.segmentationType = volumeParser.segmentationType();
            series.segmentationSegments = volumeParser.segmentationSegments();
        }
        // patient information
        series.patientID = volumeParser.patientID();
        series.patientName = volumeParser.patientName();
        series.patientAge = volumeParser.patientAge();
        series.patientBirthdate = volumeParser.patientBirthdate();
        series.patientSex = volumeParser.patientSex();

        // just create 1 dummy stack for now
        let stack = new ModelsStack();
        stack.numberOfChannels = volumeParser.numberOfChannels();
        stack.pixelRepresentation = volumeParser.pixelRepresentation();
        stack.pixelType = volumeParser.pixelType();
        stack.invert = volumeParser.invert();
        stack.spacingBetweenSlices = volumeParser.spacingBetweenSlices();
        stack.modality = series.modality;
        // if it is a segmentation, attach extra information
        if (stack.modality === 'SEG') {
            // colors
            // labels
            // etc.
            stack.segmentationType = series.segmentationType;
            stack.segmentationSegments = series.segmentationSegments;
        }
        series.stack.push(stack);


        await this.parseFrame(series, stack, response.url, 0, volumeParser);

        //const series = parsed as ModelsSeries
        if (normalizeOrientation && !_ignoreData) {
            for (let s = 0; s < series.stack.length; s++) {
                let stack = series.stack[s];
                for (let fidx = 0; fidx < stack.frame.length; fidx++) {
                    let f = stack.frame[fidx] as ModelsFrame;
                    if (f.imageOrientation[0] === -1) {
                        //flip x
                        let xMiddle = Math.floor(f.columns / 2);
                        for (let x = 0; x < xMiddle; x++) {
                            let xd = f.columns - 1 - x;
                            let xOffset = x
                            let xdOffset = xd
                            for (let y = 0; y < f.rows; y++) {
                                let v = f.pixelData[y*f.columns + xOffset];
                                f.pixelData[y*f.columns + xOffset] = f.pixelData[y*f.columns + xdOffset];
                                f.pixelData[y*f.columns + xdOffset] = v;
                            }
                        }
                        f._imagePosition[0] -= (f.columns -1 )* (f._pixelSpacing[0])
                        // f._rightHanded might need to be negated
                        f._imageOrientation[0] = 1;
                    }
                    if (f.imageOrientation[4] === -1) {
                        //flip y
                        let yMiddle = Math.floor(f.rows / 2);
                        for (let y = 0; y < yMiddle; y++) {
                            let yd = f.rows - 1 - y;
                            let yOffset = y * f.columns
                            let ydOffset = yd * f.columns
                            for (let x = 0; x < f.columns; x++) {
                                let v = f.pixelData[x + yOffset];
                                f.pixelData[x + yOffset] = f.pixelData[x + ydOffset]
                                f.pixelData[x + ydOffset] = v;
                            }
                        }
                        f._imagePosition[1] -= (f.rows -1 )* f._pixelSpacing[1]
                        // f._rightHanded might need to be negated
                        f._imageOrientation[4] = 1;
                    }
                }
            }
        }
        return series
    }

    /**
     * recursive parse frame
     * @param {ModelsSeries} series - data series
     * @param {ModelsStack} stack - data stack
     * @param {string} url - resource url
     * @param {number} i - frame index
     * @param {parser} dataParser - selected parser
     * @param {promise.resolve} resolve - promise resolve args
     * @param {promise.reject} reject - promise reject args
     */
    async parseFrame(series :ModelsSeries, stack, url, i, dataParser) {
        let frame = new ModelsFrame();
        frame.sopInstanceUID = dataParser.sopInstanceUID(i);
        frame.url = url;
        frame.index = i;
        frame.invert = stack.invert;
        frame.frameTime = dataParser.frameTime(i);
        frame.ultrasoundRegions = dataParser.ultrasoundRegions(i);
        frame.rows = dataParser.rows(i);
        frame.columns = dataParser.columns(i);
        frame.numberOfChannels = stack.numberOfChannels;
        frame.pixelPaddingValue = dataParser.pixelPaddingValue(i);
        frame.pixelRepresentation = stack.pixelRepresentation;
        frame.pixelType = stack.pixelType;
        frame.imagePosition = dataParser.imagePosition(i);

        if (frame.imagePosition !== null) {
            const compressionMethod = dataParser.getCompressionMethod(i);
            switch (compressionMethod) {
                case 'JPEG2000Lossy':
                case 'JPEGBaseLine':
                    series.hasCompressed = true;
                    series.hasLossy = true;
                    break;

                case 'JPEGLossless':
                case 'RLE':
                    series.hasCompressed = true;
                    break

                case 'Uncompressed':
                case 'UncompressedSwap':
                    break;

                case 'Unknown':
                default:

            }
            (frame as any)._compressionMethod = compressionMethod

            if (!this._ignoreData) {
                //unless explicityl required load data
                frame.pixelData = await dataParser.extractPixelData(i)
            }
            frame.pixelSpacing = dataParser.pixelSpacing(i);
            frame.spacingBetweenSlices = dataParser.spacingBetweenSlices(i);
            frame.sliceThickness = dataParser.sliceThickness(i);
            frame.imageOrientation = dataParser.imageOrientation(i);
            frame.rightHanded = dataParser.rightHanded();
            stack.rightHanded = frame.rightHanded;
            if (frame.imageOrientation === null) {
                frame.imageOrientation = [1, 0, 0, 0, 1, 0];
            }
            frame.dimensionIndexValues = dataParser.dimensionIndexValues(i);
            frame.bitsAllocated = dataParser.bitsAllocated(i);
            frame.instanceNumber = dataParser.instanceNumber(i);
            frame.windowCenter = dataParser.windowCenter(i);
            frame.windowWidth = dataParser.windowWidth(i);
            frame.rescaleSlope = dataParser.rescaleSlope(i);
            frame.rescaleIntercept = dataParser.rescaleIntercept(i);
            // should pass frame index for consistency...
            if (frame.pixelData) {
                frame.minMax = dataParser.minMaxPixelData(frame.pixelData);
            }

            // if series.mo
            if (series.modality === 'SEG') {
                frame.referencedSegmentNumber = dataParser.referencedSegmentNumber(i);
            }
            stack.frame.push(frame);
        }

        // update status
        this._parsed = i + 1;
        this._totalParsed = series.numberOfFrames;

        // emit 'parsing' event
        this.emit('parsing', {
            file: url,
            total: this._totalParsed,
            parsed: this._parsed,
            time: new Date(),
        });

        if (this._parsed === this._totalParsed) {
            // emit 'parse-success' event
            this.emit('parse-success', {
                file: url,
                total: this._totalParsed,
                parsed: this._parsed,
                time: new Date(),
            });

            return series;
        } else {
            await this.parseFrame(series, stack, url, this._parsed, dataParser)
        }
    }

    /**
     * Return parser given an extension
     * @param {string} extension - extension
     * @return {parser} selected parser
     */
    _parser(extension) {
        return ParsersDicom;
    }

    /**
     * Pre-process data to be parsed (find data type and de-compress)
     * @param {*} data
     */
    _preprocess(data) {
        const parsedUrl = CoreUtils.parseUrl(data.url);
        // update data
        data.filename = parsedUrl.filename;
        data.extension = parsedUrl.extension;
        data.pathname = parsedUrl.pathname;
        data.query = parsedUrl.query;

        // unzip if extension is '.gz'
        if (data.extension === 'gz') {
            data.gzcompressed = true;
            data.extension = data.filename
                .split('.gz')
                .shift()
                .split('.')
                .pop();
        } else if (data.extension === 'mgz') {
            data.gzcompressed = true;
            data.extension = 'mgh';
        } else if (data.extension === 'zraw') {
            data.gzcompressed = true;
            data.extension = 'raw';
        } else {
            data.gzcompressed = false;
        }

        if (data.gzcompressed) {
            let decompressedData = PAKO.inflate(data.buffer);
            data.buffer = decompressedData.buffer;
        }
    }

    /**
     * Filter data by extension
     * @param {*} extension
     * @param {*} item
     * @returns Boolean
     */
    _filterByExtension(extension, item) {
        if (item.extension.toUpperCase() === extension.toUpperCase()) {
            return true;
        }
        return false;
    }
}
