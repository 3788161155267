export class CompleteGeometry {
  constructor(
    public visual: THREE.BufferGeometry,
    public collider?: THREE.BufferGeometry,
    public intersection?: THREE.BufferGeometry,
    public colliderIntersection?: THREE.BufferGeometry,
    public uiCollider?: THREE.BufferGeometry,
    public rawDrc?: ArrayBuffer
  ) {}
}
