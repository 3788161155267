import * as M from 'models'

const initialState: M.VolumePanel = {
  lastViewDirection: M.ViewDirection.NONE,
}

export default (state = initialState, action: any): M.VolumePanel => {
  switch (action.type) {
    case M.VOLUMEPANEL_SETVIEWDIRECTION: {
      return { ...state, lastViewDirection: action.data.direction }
    }
    default:
      return state
  }
}
