/* eslint-disable react/prop-types */
import {
  Box,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import NotificationIcon from '@mui/icons-material/NotificationsOutlined'
import WarningIcon from '@mui/icons-material/ReportProblemOutlined'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/CloseOutlined'

import Button from './Button'
import Text from './Text'
import { PAGE_MIN_WIDTH, PAGE_MAX_WIDTH } from './constants'
import { PPCTheme } from './colorUtils'
import ConfirmData from './ConfirmDialogData'

export type DialogSize = 'large' | 'normal' | 'smedium' | 'medium' | 'small'

export const useDialogStyles = makeStyles<
  Theme & PPCTheme,
  { uppercase?: boolean; actionButtonMinWidth?: number | string; size?: DialogSize }
>((theme) => ({
  dialogTitleText: {
    fontFamily: 'Roboto',
    fontStyle: 'regular',
    fontVariant: 'normal',
    lineHeight: '22px',
    lineSpacing: '29px',
    fontSize: '24px',
    textTransform: ({ uppercase }) => (uppercase ? 'uppercase' : 'none'),
    padding: 20,
  },
  popupTitleText: {
    fontFamily: 'Roboto',
    fontStyle: 'regular',
    fontVariant: 'normal',
    lineHeight: '22px',
    lineSpacing: '22px',
    fontSize: '20px',
    fontWeight: 'bold',
    textTransform: ({ uppercase }) => (uppercase ? 'uppercase' : 'none'),
  },
  infoText: {
    font: 'Roboto',
    fontStyle: 'regular',
    fontSize: '16px',
    fontWeight: 'normal',
    lineSpacing: '22',
    letterSpacing: '0',
    textAlign: 'justify',
  },
  dialogContent: {
    padding: 0,
    marginLeft: 32,
    marginRight: 32,
    marginBottom: 32,
    // overflow: 'hidden',
    height: 'max-content',
    width: ({ size }) => (size === undefined ? 'initial' : size === 'large' ? 920 : 420),
  },
  dialogTitle: {
    paddingTop: 16,
    paddingBottom: 0,
    marginBottom: 14,
    overflow: 'hidden',
    fontSize: 24,
    fontStyle: 'regular',
    textTransform: 'uppercase',
  },
  dialogActions: {
    backgroundColor: theme.palette.grey.P200,
    height: 64,
    padding: 0,
    '& button': {
      minWidth: ({ actionButtonMinWidth }) => actionButtonMinWidth || 100,
      marginLeft: 20,
      marginRight: 20,
    },
  },
  smallPaper: {
    borderRadius: '16px',
    width: 420,
    minWidth: 420,
    margin: 0,
  },
  normalPaper: {
    borderRadius: '16px',
    width: 640,
    minWidth: 640,
    margin: 0,
  },
  smediumPaper: {
    borderRadius: '16px',
    width: 800,
    minWidth: 800,
    margin: 0,
  },
  mediumPaper: {
    borderRadius: '16px',
    width: 920,
    minWidth: 920,
    margin: 0,
  },
  largePaper: {
    borderRadius: '16px',
    width: 1024,
    minWidth: 1024,
    margin: 0,
  },

  titleDivider: {
    height: 3,
    width: 60,
    marginTop: 10,
    backgroundColor: theme.palette.primary.main,
  },
  dialogIcon: {
    fontSize: 72,
    paddingTop: 16,
    marginBottom: -6,
  },
  dialogNormalIcon: {
    fontSize: 24,
    paddingTop: 2,
    paddingLeft: 5,
    lineHeight: 60,
    cursor: 'pointer',
  },
  closeButton: {
    right: 0,
    top: 0,
    margin: 4,
    position: 'absolute',
    padding: 0,
    color: theme.palette.common.black,
  },
  closeIcon: {
    fontSize: 24,
  },
}))

const useBackdropStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,0.75)',
    backdropFilter: 'blur(6px)',
  },
})

declare interface DialogAction {
  title: string
  color?: string
  callback?: () => void
  disabled?: boolean
}

declare interface DialogBaseProps extends Omit<MuiDialogProps, 'title'> {
  size?: DialogSize
  onClose?: () => void
  title?: string | JSX.Element
  actions?: DialogAction[]
  actionButtonMinWidth?: string | number
}

const DialogBase: React.FC<DialogBaseProps> = (props) => {
  const backdropClasses = useBackdropStyles()
  const { size, open, onClose, title, children, actions, actionButtonMinWidth, ...rest } = props
  let paperClass

  const classes = useDialogStyles({ actionButtonMinWidth })

  switch (size) {
    case 'small':
      paperClass = classes.smallPaper
      break
    case 'medium':
      paperClass = classes.mediumPaper
      break
    case 'smedium':
      paperClass = classes.smediumPaper
      break
    case 'normal':
      paperClass = classes.normalPaper
      break
    case 'large':
      paperClass = classes.largePaper
      break
    default:
  }

  return (
    <MuiDialog
      classes={{ root: classes.root, container: classes.container, paper: paperClass }}
      {...rest}
      scroll='paper'
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && onClose) {
          onClose()
        }
      }}
      BackdropProps={{ classes: backdropClasses }}
      maxWidth={false}
    >
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box display='flex' flexDirection='column' style={{ height: '100%', width: '100%' }}>
          <Divider style={{ width: '100%', top: 0, height: 1, backgroundColor: '#dcdcdc' }} />
          <Grid
            component='div'
            container
            item
            alignContent='center'
            justifyContent='center'
            style={{ display: 'flex', width: '100%', height: '100%' }}
          >
            {actions?.map((action) => (
              <Grid item key={action.title}>
                <Button
                  upperCase
                  size='small'
                  variant='contained'
                  color={action.color === 'secondary' ? action.color : 'primary'}
                  onClick={() => action?.callback && action.callback()}
                  disabled={!!action?.disabled}
                  text={action.title}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogActions>
    </MuiDialog>
  )
}

declare interface DialogProps extends DialogBaseProps {
  titleText?: string
  infoId?: string
}

// export declare interface DialogProps = Omit<DialogBaseProps, 'title'> & AdditionalDialogProps

export const Dialog: React.FC<DialogProps> = (props) => {
  const { onClose, children, titleText, title } = props
  const classes = useDialogStyles({})

  return (
    <DialogBase
      {...props}
      disableEscapeKeyDown
      title={
        <>
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' position='relative'>
            <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
              {title && title}
              {titleText && (
                <Box>
                  <Text size='large' weight='bold'>
                    {titleText.toUpperCase()}
                  </Text>
                </Box>
              )}
            </Box>
            {onClose ? (
              <IconButton aria-label='close' onClick={onClose} className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            ) : null}
            <Divider className={classes.titleDivider} />
          </Box>
        </>
      }
    >
      {children}
    </DialogBase>
  )
}

export declare interface PopupProps extends Omit<DialogBaseProps, 'title'> {
  title: string
  text: string
  icon: JSX.Element
  translateData: any
}

export const Popup: React.FC<PopupProps> = (props) => {
  const classes = useDialogStyles({})
  const { t } = useTranslation()
  const { title, text, icon, onClose, actions, translateData, children, ...rest } = props
  const upperCaseTitle = title ? t(title, translateData).toUpperCase() : undefined
  const infoText = text ? t(text, translateData) : undefined

  return (
    <DialogBase
      {...rest}
      actions={actions || [{ title: 'OK', callback: () => onClose?.() }]}
      title={
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <Box>{icon}</Box>
          <Box>
            <Text size='big' weight='bold'>
              {upperCaseTitle}
            </Text>
          </Box>
        </Box>
      }
    >
      <>
        {children || (
          <Box display='flex' justifyContent='center'>
            <Text size='normal' align='center' className={classes.infoText} style={{ textAlign: 'center' }}>
              {infoText}
            </Text>
          </Box>
        )}
      </>
    </DialogBase>
  )
}

export declare interface ContentDataType {
  title?: string
  body?: JSX.Element
  content?: string
  icon?: React.FC<{ color: 'primary' | 'secondary'; className: string }>
  iconColor?: 'primary' | 'secondary'
}

export declare interface InfoDialogProps extends DialogBaseProps {
  infoId: string
  contentData?: ContentDataType
  translateData?: any
}

export declare interface AlertDialogProps extends DialogBaseProps {
  contentData?: ContentDataType
}

export const AlertDialog: React.FC<AlertDialogProps> = (props) => {
  const { onClose, contentData, ...rest } = props
  const classes = useDialogStyles({})

  return (
    <DialogBase
      {...rest}
      actions={[{ title: 'OK', color: 'primary', callback: () => onClose?.() }]}
      size='small'
      actionButtonMinWidth={40}
      title={
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <Box>
            {contentData?.icon ? (
              <contentData.icon
                color={contentData?.iconColor ? contentData.iconColor : 'primary'}
                className={classes.dialogIcon}
              />
            ) : (
              <NotificationIcon
                color={contentData?.iconColor ? contentData.iconColor : 'primary'}
                className={classes.dialogIcon}
              />
            )}
          </Box>
          <Box>
            <Text size='big' weight='bold'>
              {contentData?.title && contentData.title.toUpperCase()}
            </Text>
          </Box>
        </Box>
      }
    >
      <Box display='flex' justifyContent='center'>
        <Text component='div' size='normal' align='center' className={classes.infoText} style={{ textAlign: 'center' }}>
          {contentData?.body && contentData.body}
        </Text>
      </Box>
    </DialogBase>
  )
}

export declare interface ConfirmDialogProps extends PopupProps {
  confirmId: keyof typeof ConfirmData
  onAccept: () => void
  acceptDisabled: boolean
  Icon: React.FC<{ color: 'primary' | 'secondary'; className: string }>
  IconColor: 'primary' | 'secondary'
  yes: boolean
  yesTitle: string
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const classes = useDialogStyles({})
  const { confirmId, onClose, onAccept, acceptDisabled, Icon, IconColor, yes, yesTitle } = props
  const { t } = useTranslation()
  const confirm = ConfirmData[confirmId]
  const title = t(confirm.title).toUpperCase()

  return (
    <Popup
      {...props}
      size='small'
      actions={[
        { title: t`i18n.cancel`, color: 'normal', callback: () => onClose?.() },
        {
          title: t(yes ? yesTitle || 'i18n.yes' : 'i18n.ok'),
          color: 'primary',
          callback: () => onAccept(),
          disabled: acceptDisabled,
        },
      ]}
      disableEscapeKeyDown
      icon={
        Icon ? (
          <Icon color={IconColor || 'primary'} className={classes.dialogIcon} />
        ) : (
          <WarningIcon color='error' className={classes.dialogIcon} />
        )
      }
      title={title}
      text={t(confirm.text)}
    />
  )
}

const useBackdropDialogStyles = makeStyles(() => ({
  paper: {
    top: '64px',
    left: '64px',
    right: '64px',
    bottom: 1,
    position: 'fixed',
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    minWidth: `${PAGE_MIN_WIDTH}px`,
    maxWidth: `${PAGE_MAX_WIDTH}px`,
  },
  scrollPaper: {
    width: '100%',
    height: '100%',
  },
  root: {
    width: '100%',
    height: '100%',
  },
}))

export const BackdropDialog: React.FC<MuiDialogProps> = (props) => {
  const classes = useBackdropDialogStyles()
  const backdropClasses = useBackdropStyles()
  return <MuiDialog BackdropProps={{ classes: backdropClasses }} classes={classes} {...props} />
}
