import PropTypes from 'prop-types'

import { Card, CardHeader, CardContent, CardActions, Paper, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Divider from './Divider'
import Text from './Text'

const useStyles = makeStyles((theme) => ({
  card: {
    background: 'none',
    boxShadow: 'none',
    borderRadius: 6,
    flexGrow: 1,
    flexShrink: 0,
    position: 'relative',
    marginBottom: ({ gutterBottom }) => (gutterBottom ? 16 : 0),
    marginTop: ({ gutterTop }) => (gutterTop ? 16 : 0),
    alignSelf: 'stretch',
  },

  headerRoot: {
    cursor: 'default',
    background: 'rgba(255,255,255,0.25)',
    boxShadow: '0px 1px 4px #00000026',
    padding: 0,
    textAlign: 'center',
    borderTopLeftRadius: ({ header }) => (header ? 0 : 6),
    borderTopRightRadius: ({ header }) => (header ? 0 : 6),
  },
  title: {
    color: 'white',
    font: 'normal normal 300 24px/26px Roboto',
    padding: '9px',
    textTransform: 'uppercase',
    height: '50px',
  },
  titleBottomShadow: {
    position: 'absolute',
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.06) 100%)',
    height: '14px',
    width: '100%',
    left: 0,
    top: '33px', // title.height - bottomLine - bottomShadow
    pointerEvents: 'none',
  },
  titleBottomLine: {
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    height: '3px',
    width: '100%',
    left: 0,
    top: '47px',
    pointerEvents: 'none',
  },
  subheader: {
    background: ({ success }) => (success ? theme.palette.success.main : theme.palette.background.paper),
    padding: '16px',
    paddingBottom: 0,
    fontSize: '24px',
    borderTopLeftRadius: ({ header }) => (header ? 0 : 6),
    borderTopRightRadius: ({ header }) => (header ? 0 : 6),
  },
  content: {
    background: ({ success }) => (success ? theme.palette.success.main : theme.palette.background.paper),
    boxShadow: '0px 1px 4px #00000026',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    paddingTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    overflow: 'hidden',

    color: ({ success }) => (success ? `${theme.palette.common.white} !important` : 'inherit'),
  },
  actions: {
    padding: 32,
    justifyContent: ({ singleAction }) => (singleAction ? 'center' : 'space-between'),
  },
  info: {
    padding: 4,
    marginBottom: 3,
  },
  cornerText: {
    position: 'absolute',
    top: ({ header }) => (header ? 80 : 20),
    right: 30,
  },
}))

const CardActionsLayout = ({ children }) => {
  const classes = useStyles({
    singleAction: !children || children.length === 1,
  })
  return <CardActions classes={{ spacing: classes.actions }}>{children}</CardActions>
}

CardActionsLayout.propTypes = { children: PropTypes.array }

const CardLayout = ({
  children: content,
  headerText,
  headerAction,
  title,
  simpleTitle,
  subTitle,
  cornerText,
  actions,
  ContentProps,
  success,
  divided,
  additionalContent,
  gutterBottom,
  gutterTop,
  hideWithoutContent,
  color,
  ...rest
}) => {
  const classes = useStyles({
    success,
    header: Boolean(headerText),
    singleAction: !actions || actions.length === 1,
    gutterBottom,
    gutterTop,
  })
  return !content && hideWithoutContent ? null : (
    <Card raised={false} classes={{ root: classes.card }} {...rest}>
      <CardHeader
        classes={{ root: classes.headerRoot, subheader: classes.subheader, title: classes.title }}
        title={
          (headerText || headerAction) && (
            <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
              {headerText}
              {headerAction && (
                <Box style={{ position: 'absolute', right: 0, top: 0, padding: 16 }}>{headerAction}</Box>
              )}
              <Box classes={{ root: classes.titleBottomShadow }} />
              <Box classes={{ root: classes.titleBottomLine }} />
            </Box>
          )
        }
        subheaderTypographyProps={{ color: 'primary' }}
        subheader={
          <Box display='flex' flexDirection='row' justifyContent='center'>
            {typeof title === 'string' ? (
              <Text
                color={success ? 'white' : color || 'primary'}
                size={simpleTitle ? 24 : 'title'}
                weight={simpleTitle ? 'regular' : 'bold'}
                uppercase={!simpleTitle}
              >
                {title}
              </Text>
            ) : (
              title
            )}
            {subTitle && (
              <Text color={success ? 'white' : 'black'} size='title' weight='regular'>
                : {subTitle}
              </Text>
            )}
          </Box>
        }
      />
      <CardContent component={Paper} classes={{ root: classes.content }} {...ContentProps}>
        {divided && <Divider alternative={success} />}
        {cornerText && <Box classes={{ root: classes.cornerText }}>{cornerText}</Box>}
        {content}
      </CardContent>
      {additionalContent}
      {actions && <CardActionsLayout>{actions}</CardActionsLayout>}
    </Card>
  )
}

CardLayout.propTypes = {
  children: PropTypes.any,
  gutterBottom: PropTypes.bool,
  gutterTop: PropTypes.bool,
  hideWithoutContent: PropTypes.bool,
  additionalContent: PropTypes.any,
  headerText: PropTypes.string,
  headerAction: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  simpleTitle: PropTypes.bool,
  subTitle: PropTypes.string,
  infoId: PropTypes.string,
  success: PropTypes.bool,
  divided: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.element),
  ContentProps: PropTypes.object,
  cornerText: PropTypes.object,
  color: PropTypes.string,
}

// eslint-disable-next-line react/display-name
const withCardLayout = (Comp) => (cardLayoutProps) => (props) =>
  (
    <CardLayout {...cardLayoutProps}>
      <Comp {...props} />
    </CardLayout>
  )

export { CardActionsLayout, withCardLayout }
export default CardLayout
