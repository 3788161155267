export class BackAndFrontPassShader {
  public static uniforms = {}

  public static vertexShader = `    
    varying highp vec3 vCoord;
    void main() {
        vCoord = position;
    	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
    `

  public static frontPosFragmentShader = `        
        varying highp vec3 vCoord;
        void main() {
        	gl_FragColor = vec4(vCoord,1.0);
        }
    `

  public static backAndFrontBlackShapeFragmentShader = `            
    void main() {
        gl_FragColor = vec4(1.0,0.0,1.0,0.0);     //fontos az alpha is 0!
    }
`

  public static backDirFragmentShader = ` 
        uniform vec2 ScreenSizeReciproc;    
        uniform sampler2D tEntryPoints;   
        varying vec3 vCoord;
        void main() {
            vec2 screen = (gl_FragCoord.xy * ScreenSizeReciproc);
            vec3 back = texture2D(tEntryPoints, screen).rgb;
            vec3 front = vCoord;
            vec3 v = front - back;
            float l = max(length(v.xyz),0.01);
            gl_FragColor = vec4(l,l,l,l);
        }
    `

  public static defines = undefined
}
