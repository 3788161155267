import Validators from './core.validators';

/**
 * General purpose functions.
 *
 * @module core/utils
 */
export default class CoreUtils {
  /**
   * Parse url and find out the extension of the exam file.
   *
   * @param {*} url - The url to be parsed.
   * The query string can contain some "special" parameters that can be used to ease the parsing process
   * when the url doesn't match the exam file name on the filesystem:
   * - filename: the name of the exam file
   * - contentType: the mime type of the exam file. Currently only "application/dicom" is recognized, nifti files don't have a standard mime type.
   * For  example:
   * http://<hostname>/getExam?id=100&filename=myexam%2Enii%2Egz
   * http://<hostname>/getExam?id=100&contentType=application%2Fdicom
   *
   * @return {Object}
   */
  static parseUrl(url) {
    const parsedUrl = new URL(url, 'http://fix.me');
    const data = {
      filename: parsedUrl.searchParams.get('filename'),
      extension: '',
      pathname: parsedUrl.pathname,
      query: parsedUrl.search,
    };

    // get file name
    if (!data.filename) {
      data.filename = data.pathname.split('/').pop();
    }

    // find extension
    const splittedName = data.filename.split('.');

    data.extension = splittedName.length > 1 ? splittedName.pop() : 'dicom';

    const skipExt = [
      'asp',
      'aspx',
      'go',
      'gs',
      'hs',
      'jsp',
      'js',
      'php',
      'pl',
      'py',
      'rb',
      'htm',
      'html',
    ];

    if (
      !isNaN(data.extension) ||
      skipExt.indexOf(data.extension) !== -1 ||
      (data.query && data.query.includes('contentType=application%2Fdicom'))
    ) {
      data.extension = 'dicom';
    }

    return data;
  }



  static stringToNumber(numberAsString) {
    let number = Number(numberAsString);

    // returns true is number is NaN
    if (number !== number) {
      const dots = (numberAsString.match(/\./g)||[]).length;
      const commas = (numberAsString.match(/\,/g)||[]).length;

      if (commas === 1 && dots < 2) {
        // convert 1,45 to 1.45
        // convert 1,456.78 to 1456.78
        const replaceBy = dots === 0 ? '.' : '';
        const stringWithoutComma = numberAsString.replace(/,/g, replaceBy);
        number = Number(stringWithoutComma);
      }

      // if that didn't help
      // weird stuff happenning
      // should throw an error instead of setting value to 1.0
      if (number !== number) {
        console.error(`String could not be converted to number (${numberAsString}). Setting value to "1.0".`);
        number = 1.0;
      }
    }

    return number;
  }
}
