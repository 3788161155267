import PropTypes from 'prop-types'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Box, IconButton, TextField, FormControl } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useSpinEditStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Roboto',
    fontStyle: 'regular',
    fontVariant: 'normal',
    lineHeight: '16px',
  },
  box: {
    width: 'max-content',
    minHeight: 32,
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  icon: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontSize: '24px',
    transform: 'scale(1.1)',
    cursor: 'pointer',
    padding: 5,
    lineHeight: 20,

    '&:disabled': {
      color: theme.palette.grey[400],
    },
  },
  textField: {
    lineHeight: 12,
    height: 15,
    margin: '0 5px',
  },
  numberInput: {
    textAlign: 'center',
    width: 30,

    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
      margin: 0,
    },
  },
}))

const safeNumber = (value, min, max) => {
  let res = value

  if (typeof res !== 'number') {
    res = parseInt(value, 10)
    if (typeof res !== 'number') {
      res = min
    }
  }

  return Math.min(max, Math.max(min, res))
}

const SpinEdit = ({ id, value: givenValue, min = 0, max = 9999999999999, onChange, containerProps }) => {
  const classes = useSpinEditStyles()

  const value = safeNumber(givenValue, min, max)

  const valueInc = () => {
    onChange(Math.max(min, value + 1))
  }

  const valueDec = () => {
    onChange(Math.max(min, value - 1))
  }

  const onChangeInt = ({ target: { value: newValue } }) => {
    onChange(Math.min(max, Math.max(min, safeNumber(newValue, min, max))))
  }

  return (
    <Box key={id} textAlign='center' className={classes.box} {...containerProps}>
      <IconButton onClick={valueDec} disabled={value <= min} className={classes.icon}>
        <RemoveCircleOutlineIcon />
      </IconButton>
      <FormControl noValidate autoComplete='off'>
        <TextField
          label=''
          size='small'
          type='number'
          value={value}
          inputProps={{
            onClick: (e) => {
              e.stopPropagation()
              e.target.select()
            },
            min: min || 0,
            className: classes.numberInput,
          }}
          onChange={onChangeInt}
          className={classes.textField}
        />
      </FormControl>
      <IconButton onClick={valueInc} disabled={value >= max} className={classes.icon}>
        <AddCircleOutlineIcon />
      </IconButton>
    </Box>
  )
}

SpinEdit.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  containerProps: PropTypes.object,
}

export default SpinEdit
