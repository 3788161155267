/* eslint-disable react/prop-types */

import { Button as Base, ButtonProps as BaseProps, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles<Theme, { upperCase?: boolean; hasContent?: boolean }>((theme) => ({
  root: {
    height: 50,
    borderRadius: 60,
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '36px',
    letterSpacing: 0,
    textTransform: ({ upperCase }) => (upperCase ? 'uppercase' : 'none'),
    padding: ({ hasContent }) => (hasContent !== undefined ? '8px 22px ' : 0),
  },
  label: {},
  text: {
    alignSelf: 'center',
  },
  outlined: {
    color: theme.palette.common.white,
    border: `2px solid ${theme.palette.common.white}`,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: '2px solid transparent',
    },
  },
  contained: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#868686',
      color: theme.palette.common.white,
    },
  },
  containedPrimary: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  containedSecondary: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  outlinedSecondary: {
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      border: '2px solid transparent',
    },
  },
  sizeSmall: {
    height: 32,
    borderRadius: 16,

    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: 0,
  },
  sizeLarge: {
    height: 60,
    borderRadius: 30,

    fontSize: '18px',
    fontWeight: 400,
    letterSpacing: 0,
  },
  iconSizeSmall: {
    width: 18,
    minWidth: 18,
    height: 18,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  iconSizeMedium: {
    width: 26,
    minWidth: 26,
    height: 26,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  iconSizeLarge: {
    width: 36,
    minWidth: 36,
    height: 36,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  startIcon: {
    // margin: 0,
    // padding: 0,
    // left: 0,
  },
  endIcon: {
    // margin: 0,
    // padding: 0,
  },
}))

declare interface AdditionalButtonProps {
  text?: string
  upperCase?: boolean
  next?: boolean
  prev?: boolean
}

export declare type ButtonProps = AdditionalButtonProps & BaseProps

const Button: React.FC<ButtonProps> = (props) => {
  const { children, text, upperCase, classes, ...rest } = props
  const baseClasses = useStyles({ upperCase, hasContent: Boolean(children || text) })

  return (
    <Base {...rest} classes={{ ...baseClasses, ...classes }}>
      {text}
      {children}
    </Base>
  )
}

export default Button
