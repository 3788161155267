import { Divider as DividerBase, DividerProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 60,
    height: 2,
    margin: '17px auto',
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
  },
  light: {
    backgroundColor: theme.palette.common.white,
  },
}))

export interface DividerProperties {
  alternative?: boolean
}

const Divider = ({ alternative: light, ...rest }: DividerProperties & DividerProps) => {
  const classes = useStyles({ light })

  return <DividerBase classes={classes} light={light} {...rest} />
}

export default Divider
