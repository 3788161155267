import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { VBox, Text, NormalButton } from '../designSystem'
import { useDropzone } from 'react-dropzone'
import { readWholeFile } from './util'

const FileSelector = ({ uploadStatus, onFilesChanged, readBlobs, readUrl, children }) => {
  const { t } = useTranslation()

  const { getRootProps, getInputProps /* isDragActive */ } = useDropzone({
    onDrop: (files) => {
      if (readBlobs || readUrl) {
        const FileReadPromises = files.map((file) => readWholeFile(file, readUrl))

        Promise.all(FileReadPromises).then((res) => {
          onFilesChanged(
            res.map((data, index) => ({
              file: files[index],
              blob: readBlobs && data,
              url: readUrl && data,
            }))
          )
        })
      } else {
        onFilesChanged(files)
      }
    },
  })

  return uploadStatus !== 'SELECTING_FILES' ? null : (
    <>
      <input
        {...getInputProps({
          multiple: true,
          type: 'file',
          style: { display: 'none' },
        })}
      />
      <Box
        {...getRootProps()}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px dashed grey',
          borderRadius: 12,
          margin: '20px 0px',
          height: 200,
          // backgroundColor: isDragActive ? 'rgba(108, 173, 223, 0.3)' : 'transparent',
        }}
      >
        {children || (
          <VBox center maxWidth={undefined} spread={undefined}>
            <Text size='medium' weight='medium'>
              {t`i18n.dragAndDropFilesHere`}
            </Text>
            <Text size='normal' color='#aaa'>
              {t`i18n.orClick`}
            </Text>
            <NormalButton color='primary' upperCase label={t`i18n.selectFile_plural`} />
          </VBox>
        )}
      </Box>
    </>
  )
}

export default FileSelector
