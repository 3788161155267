import axios from 'axios'
//import Loader from 'third-party/amimini/src/loaders/loaders.volume'

function nts(k, l) {
  let rv = ''
  let d = 1
  for (let i = 1; i < l; i++) {
    d *= 10
  }
  for (let i = 0; i < l; i++) {
    rv += String.fromCharCode(48 + Math.trunc(k / d))
    k = k - Math.trunc(k / d) * d
    d /= 10
  }
  return rv
}
export async function loadSimulatedSTL() {
  let buf: ArrayBuffer[] = []

  const tasks: number[] = []
  for (let k = 0; k < 1; k++) {
    tasks.push(k)
  }
  /*const taskPromises1 = tasks.map(async (t) => {
        const d = (await axios.request<ArrayBuffer>({
            url: `${window.location.origin}/api/stlviewer/teapot.stl`,
            //url: `${window.location.origin}/api/stlviewer/dcs.ply`,
            method: 'get',
            responseType: 'arraybuffer'
        })
        ).data
        return d;

    })
*/
  const taskPromises1 = tasks.map(async (_t) => {
    const d = (
      await axios.request<ArrayBuffer>({
        url: `${window.location.origin}/api/stlviewer/1.stl`,
        //url: `${window.location.origin}/api/stlviewer/dcs.ply`,
        method: 'get',
        responseType: 'arraybuffer',
      })
    ).data
    return d
  })
  const taskPromises2 = tasks.map(async (_t) => {
    const d = (
      await axios.request<ArrayBuffer>({
        url: `${window.location.origin}/api/stlviewer/2.stl`,
        //url: `${window.location.origin}/api/stlviewer/dcs.ply`,
        method: 'get',
        responseType: 'arraybuffer',
      })
    ).data
    return d
  })

  buf = await Promise.all([...taskPromises1, ...taskPromises2])
  const str = JSON.stringify({
    type: 'stl',
    count: buf.length,
    options: {
      showViewerPanel: true,
    },
    material: [
      { c: 0xd4645a, m: 0.3, roughness: 0.6, n: 'arch', intensity: 1.3 },
      { c: 0xffffff, m: 0.2, roughness: 0.2, n: 'tooth', intensity: 1 },
    ],
  })
  const requestJSON = Uint8Array.from(Buffer.from(str))
  const msg = {
    msg: 'SGJS_LoadScene',
    req: requestJSON.buffer,
  }
  const requests = [requestJSON.buffer]
  for (let k = 0; k < buf.length; k++) {
    msg['fileArray' + k] = buf[k]
    requests.push(buf[k])
  }
  if (window) {
    window.postMessage(msg, '*', requests)
  }
}
export async function loadSimulatedDCM() {
  let buf: Uint8Array[] = []

  const tasks: number[] = []
  /*for (let k = 300; k < 400; k++) {//367
        tasks.push(k);
    }*/
  for (let k = 0; k < 299; k++) {
    //367
    tasks.push(k)
  }
  const taskPromises = tasks.map(async (t): Promise<Uint8Array> => {
    const d: ArrayBuffer = (
      await axios.request<ArrayBuffer>({
        //url: `${window.location.origin}/api/viewer/${nts(t, 4)}`,
        url: `${window.location.origin}/api/viewer/2k/I${nts(t + 604, 7)}.dcm`,
        method: 'get',
        responseType: 'arraybuffer',
      })
    ).data
    return new Uint8Array(d)
  })

  buf = await Promise.all(taskPromises)
  const str = JSON.stringify({ type: 'dicom', count: tasks.length })
  const requestJSON = Uint8Array.from(Buffer.from(str))
  const msg = {
    msg: 'SGJS_LoadScene',
    req: requestJSON.buffer,
  }
  const requests = [requestJSON.buffer]
  for (let k = 0; k < buf.length; k++) {
    msg['fileArray' + k] = buf[k]
    requests.push(buf[k].buffer)
  }
  if (window) {
    window.postMessage(msg, '*', requests)
  }
}
