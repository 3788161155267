import React from 'react'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { Grid, List, Link } from '@mui/material'
import * as A from 'actions'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { DialogLayout } from 'components/UI/Layouts/DialogLayout'
import { withTranslation, WithTranslation } from 'react-i18next'
import DicomLabLogo from 'static/images/logo/dicomLAB_logo_final_R.jpg'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(3),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fieldText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
    },
  })

interface RowTextProps extends WithStyles<typeof styles> {
  fieldName: string
  fieldValue: string | string[]
}

const RowText = withStyles(styles)((props: RowTextProps) => {
  return (
    <ListItemText>
      <Grid container wrap='nowrap' direction='row' justifyContent='space-between' spacing={2}>
        <Grid item min-width='160'>
          <Typography className={props.classes.fieldText} noWrap>
            {props.fieldName}
          </Typography>
        </Grid>
        <Grid item min-width='160'>
          <Typography align='right' className={props.classes.fieldText}>
            {props.fieldValue}
          </Typography>
        </Grid>
      </Grid>
    </ListItemText>
  )
})

export interface IAboutComponentProperties extends WithStyles<typeof styles> {
  isOpen: boolean
  onClose: () => any
}

class AboutComponent extends React.Component<
  IAboutComponentProperties & WithTranslation & A.ICaseAction,
  { currentTime: number }
> {
  timerId

  constructor(props: any) {
    super(props)
  }

  columnData: ([string, string] | [string, string[]])[] = []

  listGenerator(rows: ([string, string] | [string, string[]])[]) {
    return rows.map((v) => (
      <ListItem divider style={{ paddingTop: 3, paddingBottom: 3 }} key={v[0]}>
        <RowText fieldName={v[0]} fieldValue={v[1] === undefined ? '-' : v[1]} />
      </ListItem>
    ))
  }

  getTranslatedText(key: string): string {
    return this.props.t(`sgjs.${key}`)
  }

  formatNumberArray(numbers: number[]): string[] {
    if (numbers.length === 0) {
      return [this.getTranslatedText('none')]
    }
    return numbers.map((t, index) => (index === 0 ? '' : '; ') + t)
  }
  formatStringArray(numbers: string[]): string[] {
    if (numbers.length === 0) {
      return [this.getTranslatedText('none')]
    }
    return numbers.map((t, index) => (index === 0 ? '' : '; ') + t)
  }
  renderActionButton(): JSX.Element {
    return (
      <Button autoFocus onClick={this.props.onClose} color='primary' variant='contained'>
        {this.getTranslatedText('ok')}
      </Button>
    )
  }

  softwareInformation: ([string, string] | [string, string[]])[] = []
  companyInformation: ([string, string] | [string, string[]])[] = []

  componentDidUpdate(): void {}

  render() {
    if (!this.props.isOpen) return null
    const t0 = (v) => this.props.t(`sgjs.${v}`)
    const getVersion = () => {
      const prefix = 'SGS-'
      if (process.env.REACT_APP_SOURCE_TAG) {
        return `${prefix}${process.env.REACT_APP_SOURCE_TAG}`
      }
      return `${prefix}${process.env.REACT_APP_SOURCE_NAME} (${process.env.REACT_APP_SOURCE_SHA})`
    }

    const languages = ['HU', 'EN', 'IT', 'RO', 'NL', 'FR', 'DK', 'ES', 'DE', 'FI'].sort()

    this.softwareInformation = [
      [t0('name'), 'XView'],
      [t0('about.version'), getVersion()],
      [t0('about.description'), [t0('about.smartGuideSoftwareDescription') as string]],
      ['', t0('about.exclusivlyForMedicalUsage')],
      [
        t0('about.releaseDate'),
        process.env.REACT_APP_RELEASE_DATE ? process.env.REACT_APP_RELEASE_DATE.substr(0, 7) : '-',
      ],
    ]
    this.companyInformation = [
      [t0('name'), t0('about.dicomlabName') as string],
      [t0('address'), t0('about.dicomlabAddress') as string],
      [t0('contact'), 'info@dicomlab.com'],
    ]

    return (
      <DialogLayout
        fullScreen={false}
        // title={t0('about.title')}
        title=''
        onClose={this.props.onClose}
        open={this.props.isOpen}
        onClick={(event) => {
          event.stopPropagation()
          this.props.onClose()
        }}
        actions={this.renderActionButton()}
      >
        <Typography align='center' variant='h5'>
          {t0('about.companyInformation')}
        </Typography>
        <DicomLabLogo
          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', width: '30%', height: 'auto' }}
        />
        <List>{this.listGenerator(this.companyInformation)}</List>
        <Typography align='center' variant='h5'>
          {t0('about.softwareInformation')}
        </Typography>
        <List>
          {this.listGenerator(this.softwareInformation)}
          <ListItem divider style={{ paddingTop: 3, paddingBottom: 3 }} key='userGuide'>
            <ListItemText>
              <Grid container wrap='nowrap' direction='row' justifyContent='space-between' spacing={2}>
                <Grid item min-width='160'>
                  <Typography className={this.props.classes.fieldText} noWrap>
                    {t0('about.userGuide')}
                  </Typography>
                </Grid>
                <Grid item min-width='160'>
                  <Typography align='right' className={this.props.classes.fieldText}>
                    {languages.map((code) => {
                      const href = `https://static.dicomlab.com/user_manual/User_Manual_SMART_Guide-${
                        process.env.REACT_APP_SOURCE_TAG || '-'
                      }-${code}.pdf`
                      return (
                        <>
                          [{' '}
                          <Link href={href} target='_blank'>
                            {code}
                          </Link>{' '}
                          ]&nbsp;&nbsp;
                        </>
                      )
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        </List>
        {/* <Typography>{t0('about.exclusivlyForMedicalUsage')}</Typography> */}
      </DialogLayout>
    )
  }
}

export const AboutDialog = withStyles(styles)(withTranslation()(AboutComponent))
