import { boolFromString } from 'utils/XMLJs'
import * as M from 'models'

const initialState: M.EditorConfig = {
  // by default we will start in the 'prepare for prostetics' app mode
  // appMode: M.EAppMode.PROTHETICS_PREPARE,
  appMode: M.EAppMode.VIEWER,

  /*    screenLayout: M.EScreenLayout.E3DFOCUS,
    panoramaZoomed: false, */
  croppedVisible: true,
  showViewerPanel: true,
  toothVisible: true,
}

export default (state = initialState, action: any): M.EditorConfig => {
  switch (action.type) {
    case M.CASE_LOAD_VIEW_BASIC: {
      const rv = { ...initialState, appMode: M.EAppMode.VIEWER }
      if (action.data?.options?.showViewerPanel) {
        return { ...rv, showViewerPanel: true }
      } else {
        return rv
      }
    }

    case M.CASE_CLEAR: {
      return { ...initialState }
    }
    /* case M.EDITOR_SETLAYOUT: {
            return {...state, screenLayout: action.data.layout}
        }
        case M.EDITOR_SETPANORAMAZOOM: {
            return {...state, panoramaZoomed: action.data.zoom}
        } */
    case M.OBJ_SETOBJECTGROUPVISIBLE:
      switch (action.data.group) {
        case M.EObjectGroup.CROPPEDVISIBLE:
          return { ...state, croppedVisible: action.data.visible }
        case M.EObjectGroup.TOOTH:
          return { ...state, toothVisible: action.data.visible }
        default:
          return state
      }

    // todo handle load and custom events
    case M.CASE_LOAD_BASIC: {
      const rv = { ...initialState }
      const modeMap = {
        [M.SGRoles.PROTPREPARE]: M.EAppMode.PROTHETICS_PREPARE,
        [M.SGRoles.PROTPOSTSTEP]: M.EAppMode.PROTHETICS_POSTPROCESS,
        [M.SGRoles.VIEWER]: M.EAppMode.CASE_VIEWER,
      }
      rv.appMode = modeMap[action.data.master.role] || M.EAppMode.SGJS

      rv.croppedVisible = action.data.header.jDentModel.images[0].croppedVisible
        ? boolFromString(action.data.header.jDentModel.images[0].croppedVisible[0])
        : rv.croppedVisible
      return rv
    }

    default:
      return state
  }
}
