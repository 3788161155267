import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    maxWidth: ({ maxWidth }) => maxWidth || undefined,
    justifyContent: ({ spread, center }) => (center ? 'center' : spread ? 'space-between' : 'flex-start'),
    alignSelf: ({ center }) => (center ? 'center' : 'flex-start'),
    alignItems: ({ center }) => (center ? 'center' : 'flex-start'),
  },
})

const VBox = ({ center, maxWidth, spread, children, ...rest }) => {
  const classes = useStyles({ center, maxWidth, spread, ...rest })

  return (
    <Box classes={classes} {...rest}>
      {children}
    </Box>
  )
}

VBox.propTypes = {
  center: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  spread: PropTypes.bool,
  children: PropTypes.any,
}

export default VBox
