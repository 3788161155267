import React from 'react'
//const kForceViewMode = true//true
//const urlParams = new URLSearchParams(window.location.search)
//const isViewer = urlParams.get('viewer') || kForceViewMode
//console.log('alma' )
/*window['SGJS_noPhysics'] = true//isViewer
window['SGJS_noDRC'] = true//isViewer
window['SGJS_viewer'] = isViewer
*/

import i18n from './utils/i18n'
i18n.init()

const urlParams = new URLSearchParams(window.location.search)
const viewerSession = urlParams.get('viewerSession')
window['viewerSession'] = viewerSession

import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { loadableReady } from '@loadable/component'

import { ConnectedThemeProvider } from 'themes'
import Main from './components'

import { Renderer } from './renderer'
import configureStore from './utils/ConfigureStore'
import { AppState } from 'models'

// Promise.prototype.finally =
//   Promise.prototype.finally ||
//   {
//     finally(fn) {
//       const onFinally = (value) => Promise.resolve(fn()).then(() => value)
//       return this.then(
//         (result) => onFinally(result),
//         (reason) => onFinally(Promise.reject(reason))
//       )
//     },
//   }.finally

// Get the initial state from server-side rendering
const initialState = new AppState()
const { store } = configureStore({ initialState })
// create wgl2 renderer first

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderer = Renderer.get()

const render = (Component) => {
  const appElement = document.getElementById('app')
  if (appElement) {
    const root = createRoot(appElement)
    root.render(
      <Provider store={store}>
        <ConnectedThemeProvider>
          <Component viewerSession={viewerSession} />
        </ConnectedThemeProvider>
      </Provider>
    )
  }
}

// loadable-component setup
loadableReady(() => {
  render(Main)
})

// console.log('client rendering...', { 'zurl': window.location });
// logger.info({ zurl: window.location }, 'Client rendering...')
if ((module as any).hot) {
  // Enable webpack hot module replacement for routes
  ;(module as any).hot.accept('./components/index', () => {
    try {
      const nextApp = require('./components/index').default
      render(nextApp)
    } catch (error) {
      //   logger.error({ error }, 'Maind component construted.')
      // console.error(`==> 😭  Routes hot reloading error ${error}`)
    }
  })
}
