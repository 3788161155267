import * as THREE from 'three'
import { CylinderBufferGeometryZ } from 'utils/CylinderGeometryZ'
const robotoFont = require('static/fnt/Roboto-msdf.json')
const robotoPng = require('static/fnt/Roboto-msdf.png')

const pxJpg = require('static/images/env/px.jpg')
const nxJpg = require('static/images/env/nx.jpg')
const pyJpg = require('static/images/env/py.jpg')
const nyJpg = require('static/images/env/ny.jpg')
const pzJpg = require('static/images/env/pz.jpg')
const nzJpg = require('static/images/env/nz.jpg')
const envImages = [pxJpg, nxJpg, pyJpg, nyJpg, pzJpg, nzJpg]
const volumeBg = require('static/images/env/volumebg.png')

// async function loadMyFont(desc: any) {
//   const url = API.staticURL(desc.font)
//   const font = new Promise((res, rej) =>
//     loadFont(url, (err, _font) => {
//       if (err) {
//         rej(err)
//       } else {
//         res(_font)
//       }
//     })
//   )

//   const txtLoader = new THREE.TextureLoader()
//   const textureUrl = API.staticURL(desc.image)
//   const texture: Promise<THREE.Texture> = new Promise((resolve, reject) => {
//     txtLoader.load(
//       textureUrl,
//       (image) => resolve(image),
//       undefined,
//       (e) => reject(e)
//     )
//   })

//   const res = await Promise.all([font, texture])
//   return { font: robotoFont, texture: res[1] }
// }

export const loadDefaultTextures = async (props, loadEditorContent) => {
  const tasks: (() => Promise<void>)[] = []
  const txtLoader = new THREE.TextureLoader()

  if (loadEditorContent) {
    const loader = new THREE.ImageLoader()
    tasks.push(async () => {
      const font = { font: robotoFont, texture: robotoPng }
      //   await loadMyFont({
      //     font: 'fnt/Roboto-msdf.json',
      //     image: 'fnt/Roboto-msdf.png',
      //   })
      props.addCustom('FONT_ROBOTO', font)
    })

    for (let k = 0; k < 6; k++) {
      tasks.push(async () => {
        const image = await new Promise((resolve, reject) => {
          loader.load(
            envImages[k],
            (image) => resolve(image),
            undefined,
            (e) => reject(e)
          )
        })
        props.addCustom(`TXT_ENV_${k}`, image)
      })
    }
  }
  props.addGeometry('PLACEHOLDER_SPHERE3', new THREE.SphereBufferGeometry(3))
  props.addGeometry('PLACEHOLDER_BOX2', new THREE.BoxBufferGeometry(2, 2, 2))
  tasks.push(async () => {
    const image = await new Promise((resolve, reject) => {
      txtLoader.load(
        volumeBg,
        (image) => resolve(image),
        undefined,
        (e) => reject(e)
      )
    })
    props.addCustom('TXT_VOLUMEBG', image)
  })
  await Promise.all(tasks.map((f) => f()))
  const d1 = 0.75
  const d2 = 1.5
  const l1 = 25
  const l2 = 20
  props.addGeometry(
    'AXIS_THIN',
    new CylinderBufferGeometryZ(d1 * 0.5, d1 * 0.5, l1, 20, 1, undefined, undefined, undefined, l1 / 2)
  )
  props.addGeometry(
    'AXIS_THICK',
    new CylinderBufferGeometryZ(d2 * 0.5, d2 * 0.5, l2, 20, 1, undefined, undefined, undefined, -l2 / 2)
  )
}
