import * as M from 'models'
import * as THREE from 'three'
import { IObjectsAction } from './IObjectsAction'

export const impl: IObjectsAction = {
  setMesh:
    (group: M.EObjectGroup, geometry?: THREE.BufferGeometry): any =>
    (dispatch: any) => {
      dispatch({ type: M.OBJ_SETMESH, data: { group, geometry } })
    },
  addCustom:
    (name: string, geometry: M.CompleteGeometry, color: THREE.Color): any =>
    (dispatch: any) => {
      dispatch({ type: M.OBJ_ADDCUSTOM, data: { name, geometry, color } })
    },
  removeCustom:
    (uuid: string): any =>
    (dispatch: any) => {
      dispatch({ type: M.OBJ_REMOVECUSTOM, data: { uuid } })
    },
  setVolumeISOLevel:
    (disableUndo: boolean, data: number): any =>
    (dispatch: any) => {
      // /let center = new THREE.Vector3().copy(implant.location).addScaledVector(implant.directionZ,  length/2);
      dispatch({ type: M.OBJ_SETISO, data: { iso: data, disableUndo } })
    },

  delObject:
    (uuid: string): any =>
    (dispatch: any) => {
      dispatch({ type: M.OBJ_OBJECT_DEL, uuid })
    },
  /* updateImplant: (data: M.Implant): any => (dispatch: any) => {
        dispatch({ type: M.OBJ_IMPLANT_UPDATE, uuid: data.uuid, data });
    }, */
  setObjectVisible:
    (uuid: string, visible: boolean): any =>
    (dispatch: any) => {
      dispatch({ type: M.OBJ_SETOBJECTVISIBLE, data: { uuid, visible, disableUndo: false } })
    },
  setObjectGroupVisible:
    (group: M.EObjectGroup, visible: boolean): any =>
    (dispatch: any) => {
      dispatch({ type: M.OBJ_SETOBJECTGROUPVISIBLE, data: { group, visible, disableUndo: false } })
    },
}
