import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { Scrollbars } from 'rc-scrollbars'

const useStyles = makeStyles(() => ({
  scrollContent: {
    '& .rc-scrollbars-track': {
      visibility: ({ hideScrollBar }) => (hideScrollBar ? 'hidden' : 'visible'),
    },
    width: '100%',
    height: ({ height }) => height || 460,
  },
}))

const ScrollContent = ({ scrollToTopData, hideScrollBar = false, height, ...rest }) => {
  const scrollBarRef = useRef()

  const classes = useStyles({ hideScrollBar, height })

  useEffect(() => {
    if (scrollBarRef) {
      scrollBarRef.current.scrollTop(scrollBarRef.current.getScrollHeight())
    }
  }, [scrollToTopData, scrollBarRef])

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Scrollbars className={classes.scrollContent} ref={scrollBarRef} {...rest} />
    </Box>
  )
}

ScrollContent.propTypes = {
  children: PropTypes.any,
  scrollToTopData: PropTypes.any,
  hideScrollBar: PropTypes.bool,
  height: PropTypes.any,
}

export default ScrollContent
