import PropTypes from 'prop-types'
import { Checkbox as Base, FormControlLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColorFromTheme } from './colorUtils'

const useCheckboxStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 10,
    marginRight: 0,
    padding: 0,
    width: 'max-content',
    height: 20,
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    lineHeight: '16px',
    letterSpacing: 0,
    color: theme.palette.primary.dark,
    textTransform: ({ upperCase }) => (upperCase ? 'uppercase' : 'none'),
  },
  label: {
    color: ({ textColor }) => (textColor ? getColorFromTheme(theme, textColor) : theme.palette.grey.P700),
  },
  checkbox: {
    color: ({ checkboxColor }) => (checkboxColor ? getColorFromTheme(theme, checkboxColor) : theme.palette.grey.P700),
    '&.Mui-checked': {
      color: ({ checkboxColor }) => (checkboxColor ? getColorFromTheme(theme, checkboxColor) : theme.palette.grey.P700),
    },
  },
}))

const Checkbox = ({ children, text, upperCase, classes, FormControlProps, textColor, checkboxColor, ...rest }) => {
  const baseClasses = useCheckboxStyles({ upperCase, hasContent: children || text, textColor, checkboxColor })

  return (
    <FormControlLabel
      classes={{ root: baseClasses.root, label: baseClasses.label, ...classes }}
      {...FormControlProps}
      control={
        <Base className={baseClasses.checkbox} {...rest}>
          {text}
          {children}
        </Base>
      }
      label={text}
    />
  )
}

Checkbox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.func, PropTypes.element]),
  text: PropTypes.string,
  upperCase: PropTypes.bool,
  classes: PropTypes.object,
  FormControlProps: PropTypes.object,
  textColor: PropTypes.string,
  checkboxColor: PropTypes.string,
}

export default Checkbox
