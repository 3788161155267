import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Box,
  IconButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import Text from './Text'

const TABLE_BORDER_RADIUS = '10px'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontStyle: 'medium',
    fontFamily: 'Roboto',
    letterSpacing: 0,
    padding: 0,
  },

  closeUpButton: {
    fontSize: 20,
    color: theme.palette.common.white,
    widht: 30,
    height: 30,
    transform: 'scale(1.2)',
  },

  closeUpTitle: {
    '& thead th': {
      height: 45,
      fontSize: 20,
      color: theme.palette.common.white,
      cursor: 'pointer',
      userSelect: 'none',
    },
  },

  noExternalBorder: {
    '& tbody td': {
      borderLeft: 'none !important',
      borderRight: 'none !important',
      borderBottom: 'none !important',
    },
  },

  closeUpTitleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },

  body: {
    // transition: 'opacity 1s, display 1s ease-out',
    // opacity: 1,
  },

  closedUp: {
    '& tbody ': {
      display: 'none',
      opacity: 0,
    },
    '& thead th': {
      borderRadius: `${TABLE_BORDER_RADIUS} !important`,
    },
  },

  table: {
    width: '100%',

    '& tr:not(:last-child) td': {
      borderBottom: 'none !important',
    },

    borderCollapse: 'separate',

    '& th': {
      borderBottom: '0px solid black !important',
    },

    '& th:first-child': {
      borderTopLeftRadius: ({ topCornerStraight }) => (topCornerStraight ? 0 : TABLE_BORDER_RADIUS),
    },

    '& th:last-child': {
      borderTopRightRadius: ({ topCornerStraight }) => (topCornerStraight ? 0 : TABLE_BORDER_RADIUS),
    },

    '& td:first-child': {
      borderLeft: `2px solid ${theme.palette.primary.main}59`,
    },

    '& td:last-child': {
      borderRight: `2px solid ${theme.palette.primary.main}59`,
    },

    '& tbody tr:first-child td:first-child': {
      borderTopLeftRadius: ({ topCornerStraight, noHeader }) =>
        !topCornerStraight && noHeader ? TABLE_BORDER_RADIUS : 0,
    },

    '& tbody tr:first-child td:last-child': {
      borderTopRightRadius: ({ topCornerStraight, noHeader }) =>
        !topCornerStraight && noHeader ? TABLE_BORDER_RADIUS : 0,
    },

    '& tbody tr:last-child td:first-child': {
      borderBottomLeftRadius: TABLE_BORDER_RADIUS,
    },

    '& tbody tr:last-child td:last-child': {
      borderBottomRightRadius: TABLE_BORDER_RADIUS,
    },

    '& tbody tr:first-child td': {
      borderTop: ({ topCornerStraight, noHeader, noExternalBorder }) =>
        !topCornerStraight && noHeader && !noExternalBorder
          ? `2px solid ${theme.palette.primary.main}59 !important`
          : 'none',
    },

    '& tbody tr:last-child td': {
      borderBottom: `2px solid ${theme.palette.primary.main}59 `,
    },

    '& tbody tr:hover': {
      backgroundColor: ({ hover, background }) =>
        hover ? `${theme.palette.primary.main}50` : background || theme.palette.primary.light,
      cursor: ({ hover }) => (hover ? 'pointer' : 'auto'),
    },
  },

  headRow: {
    height: 34,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: `${TABLE_BORDER_RADIUS} !important`,
    display: ({ noHeader }) => (noHeader ? 'none' : 'table-row'),
  },

  headCell: {
    fontWeight: 'medium',
    lineHeight: '16px',
    fontSize: '16px',
    color: theme.palette.common.white,
  },

  groupCell: {
    backgroundColor: 'white',
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,

    '& p': {
      display: 'inline-block',

      '&:first-child': {
        marginRight: 5,
      },
    },
  },

  bodyRow: {
    height: 46,
    backgroundColor: ({ background }) => background || theme.palette.primary.light,
    borderCollapse: 'collapse',
    borderBottom: '0px !important',
    borderTop: '0px !important',
    backgroundImage: ({ background }) =>
      `linear-gradient(to right, ${theme.palette.primary.main}59 45%, ${background || theme.palette.primary.light} 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x',
  },

  lastRow: {
    backgroundSize: '0px 0px',
  },

  bodyCell: {
    fontWeight: 'medium',
    lineHeight: '16px',
    fontSize: '16px',
    color: theme.palette.primary.dark,
    borderTop: '0px !important',
    padding: 0,
    paddingLeft: 10,
  },
}))

const TableRow = ({ row, index, header, onClick }) => {
  const baseClasses = useStyles()

  return (
    <MuiTableRow
      key={row?.id || index}
      {...(index === 0 ? { component: 'tr', scope: 'row' } : {})}
      className={`${baseClasses.bodyRow}`}
      onClick={() => {
        if (onClick) {
          onClick(row)
        }
      }}
    >
      {header.map((item) => {
        const Comp = typeof row[item.id] === 'function' ? row[item.id] : null

        return (
          <MuiTableCell
            key={row.id + item.id}
            align={item.align ? item.align : 'left'}
            className={baseClasses.bodyCell}
            style={row[item.id]?.style}
          >
            {Comp ? <Comp /> : row[item.id]}
          </MuiTableCell>
        )
      })}
    </MuiTableRow>
  )
}

TableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  header: PropTypes.object,
  onClick: PropTypes.func,
}

const Table = ({
  data,
  grouped,
  header,
  hideEmpty = false,
  hover = false,
  background,
  classes,
  onRowClick,
  topCornerStraight,
  noHeader = false,
  noExternalBorder = false,
  closeUpTitle,
  additionalContent,
  ...rest
}) => {
  const baseClasses = useStyles({ topCornerStraight, hover, background, noHeader, closeUpTitle, noExternalBorder })
  const { t } = useTranslation()
  const [closeUp, setCloseUp] = useState(false)

  const onCloseUpClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCloseUp(!closeUp)
  }

  return hideEmpty && !data?.length ? (
    <></>
  ) : (
    <>
      <MuiTableContainer className={baseClasses.root}>
        <MuiTable
          {...rest}
          classes={{ ...classes }}
          className={
            baseClasses.table +
            (closeUpTitle ? ` ${baseClasses.closeUpTitle}` : '') +
            (noExternalBorder ? ` ${baseClasses.noExternalBorder}` : '') +
            (closeUp ? ` ${baseClasses.closedUp}` : '')
          }
          size='small'
        >
          <MuiTableHead>
            {closeUpTitle ? (
              <MuiTableRow className={baseClasses.headRow} key='header'>
                <MuiTableCell colSpan={header.length} onClick={onCloseUpClick}>
                  <Box className={baseClasses.closeUpTitleBox}>
                    {closeUpTitle}
                    <IconButton aria-label='close' onClick={onCloseUpClick} className={baseClasses.closeUpButton}>
                      {closeUp ? <ExpandMoreOutlinedIcon /> : <ExpandLessOutlinedIcon />}
                    </IconButton>
                  </Box>
                </MuiTableCell>
              </MuiTableRow>
            ) : (
              <MuiTableRow className={baseClasses.headRow} key='header'>
                {header.map((item) => (
                  <MuiTableCell
                    key={item.id}
                    align={item?.align ? item.align : 'left'}
                    width={item?.width ? item.width : ''}
                    className={baseClasses.headCell}
                    dangerouslySetInnerHTML={{ __html: t(item.label) }}
                  />
                ))}
              </MuiTableRow>
            )}
          </MuiTableHead>

          <MuiTableBody className={baseClasses.body}>
            {grouped
              ? data.map(({ name, desc, items }, i) => (
                  <Fragment key={`${name}-${i}`}>
                    <MuiTableRow className={`${baseClasses.bodyRow}`}>
                      <MuiTableCell className={baseClasses.groupCell} colSpan={header.length}>
                        <Text uppercase size='medium' weight='bold'>
                          {name}
                        </Text>
                        <Text>{desc}</Text>
                      </MuiTableCell>
                    </MuiTableRow>
                    {items.map((row, index) => (
                      <TableRow key={row?.id || index} row={row} index={index} header={header} onClick={onRowClick} />
                    ))}
                  </Fragment>
                ))
              : data.map((row, index) => (
                  <TableRow key={row?.id || index} row={row} index={index} header={header} onClick={onRowClick} />
                ))}
          </MuiTableBody>
        </MuiTable>
      </MuiTableContainer>
      {!closeUp && additionalContent}
    </>
  )
}

Table.propTypes = {
  data: PropTypes.array,
  grouped: PropTypes.bool,
  header: PropTypes.array,
  hideEmpty: PropTypes.bool,
  hover: PropTypes.bool,
  classes: PropTypes.object,
  onRowClick: PropTypes.func,
  topCornerStraight: PropTypes.bool,
  background: PropTypes.string,
  noExternalBorder: PropTypes.bool,
  noHeader: PropTypes.bool,
  closeUpTitle: PropTypes.object,
  additionalContent: PropTypes.any,
}

export default Table
