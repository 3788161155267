import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColorFromTheme } from './colorUtils'

const useStyles = makeStyles((theme) => ({
  box: {
    color: ({ color }) => (color ? getColorFromTheme(theme, color) || 'inherit' : 'inherit'),
    backgroundColor: ({ backgroundColor }) =>
      backgroundColor ? getColorFromTheme(theme, backgroundColor) || 'inherit' : 'inherit',
    fontSize: ({ large }) => (large ? '120%' : 'inherit'),
    width: ({ fill }) => (fill ? '100%' : null),
    height: ({ fill }) => (fill ? '100%' : null),
  },
  text: {
    color: ({ color }) => (color ? getColorFromTheme(theme, color) || 'inherit' : 'inherit'),
    fontSize: ({ large }) => (large ? '120%' : 'inherit'),
    fontWeight: ({ strong }) => (strong ? 600 : 'inherit'),
  },
}))

const Highlight = ({ children, fill, primary, success, error, strong, color, backgroundColor, large, style }) => {
  const isText = typeof children === 'string'
  const found = Object.entries({ primary, success, error }).find(([_, isTrue]) => isTrue)
  const selectedColor = (found && `${found[0]}Light`) || color
  const selectedBackgroundColor = (found && `${found[0]}Contrast`) || backgroundColor

  const classes = useStyles({ color: selectedColor, strong, large, fill, backgroundColor: selectedBackgroundColor })

  return isText ? (
    <span className={`${'hl '}${classes.text}`} style={style}>
      {children}
    </span>
  ) : (
    <Box className={classes.box} style={style}>
      {children}
    </Box>
  )
}

Highlight.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  primary: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  strong: PropTypes.bool,
  large: PropTypes.bool,
  fill: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default Highlight
