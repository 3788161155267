/* eslint-disable react/prop-types */
import { forwardRef } from 'react'

import { Typography, Theme, TypographyProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColorFromTheme, PPCTheme } from './colorUtils'

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

export type TextFontSize =
  | 'tiny'
  | 'small'
  | 'little'
  | 'normal'
  | 'medium'
  | 'title'
  | 'big'
  | 'bigBig' // Should be big or large?
  | 'large'
  | 'giant'
  | number
  | string

const fontSize = ({ size }: TextStyleInput): string | number => {
  switch (size) {
    case 'tiny':
      return '8px'
    case 'small':
      return '12px'
    case 'little':
      return '14px'
    case 'normal':
      return '16px'
    case 'medium':
      return '18px'
    case 'title':
      return '20px'
    case 'big':
      return '24px'
    case 'bigBig': // Should be big or large?
      return '28px'
    case 'large':
      return '32px'
    case 'giant':
      return '40px'
    default:
      return size || '16px'
  }
}

export type TextFontWeight = 'thin' | 'light' | 'regular' | 'medium' | 'bold'
export type TextAlignPropType = 'center' | 'inherit' | 'justify' | 'left' | 'right'

export declare interface TextProps extends Omit<TypographyProps, 'color'> {
  color?: string
  center?: boolean
  textAlign?: TextAlignPropType
  uppercase?: boolean
  capitalize?: boolean
  lh1?: boolean
  weight?: TextFontWeight
  size?: TextFontSize
  component?: string
}

type TextStyleInput = {
  // = TextProps
  weight?: TextFontWeight
  size?: TextFontSize
  uppercase?: boolean
  capitalize?: boolean
  center?: boolean
  textAlign?: TextAlignPropType
  lh1?: boolean
  color?: string
}

const fontWeight = ({ weight }: TextStyleInput): number => {
  switch (weight) {
    case 'thin':
      return 100
    case 'light':
      return 300
    case 'regular':
      return 400
    case 'medium':
      return 500
    case 'bold':
      return 700
    default:
      return 400
  }
}

const textAlign = ({ center, textAlign }: TextStyleInput) => (center ? 'center' : textAlign || 'inherit')

const textTransform = ({ uppercase, capitalize }: TextStyleInput) =>
  uppercase ? 'uppercase' : capitalize ? 'capitalize' : 'none'

const useStyles = makeStyles<Theme & PPCTheme, TextProps>((theme) => ({
  root: {
    fontFamily: 'Roboto',
    lineHeight: ({ lh1 }) => (lh1 ? 1 : 1.5),
    fontWeight,
    fontSize,
    textTransform,
    textAlign,
    whiteSpace: 'pre-wrap',
    color: ({ color }: TextStyleInput) => {
      return getColorFromTheme(theme, color || 'normal')
    },
  },
}))

const Text: React.FC<TextProps> = (
  { center, textAlign, uppercase, capitalize, children, color, lh1, ...rest },
  ref
) => {
  const classes = useStyles({ color, center, textAlign, uppercase, capitalize, lh1, ...rest })

  return (
    <Typography ref={ref} classes={classes} {...rest}>
      {children}
    </Typography>
  )
}

export default forwardRef<HTMLSpanElement, TextProps>(Text)
