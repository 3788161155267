import { IObjMap } from 'utils/IObjMap'
import * as THREE from 'three'
import * as M from 'models'

import { CompleteGeometry } from 'models/case/CompleteGeometry'

//import { updateQualifiedName } from 'typescript'

import { v4 as uuidv4 } from 'uuid'

export default (state = {} as IObjMap<M.Custom>, action: any): IObjMap<M.Custom> => {
  switch (action.type) {
    case M.CASE_CLEAR:
      return {}
    case M.CASE_LOAD_VIEW_BASIC: {
      if (!action.data.stlParams) return state
      const rv = { ...state }
      for (const o of action.data.stlParams) {
        const customGeometry = new CompleteGeometry(o.geometry)
        const uuid = uuidv4()
        const i = new M.Custom(
          uuid,
          o?.material?.n ? o?.material?.n : `n_${uuid}`,
          o.color,
          o.matrix,
          true,
          customGeometry,
          o.hasVertexColor,
          o.material
        )
        rv[uuid] = i
      }
      //const rv = { ...state, [uuid]: i }
      return rv
    }

    case M.OBJ_ADDCUSTOM: {
      // TODO  dispatch({ type: M.OBJ_ADDTOOTH, data: { name, geometry } });
      const customGeometry = action.data.geometry
      const uuid = uuidv4()
      const i = new M.Custom(
        uuid,
        action.data.name,
        action.data.color,
        new THREE.Matrix4().identity(),
        true,
        customGeometry,
        action.data.hasVertexColor,
        action.data.material
      )
      const rv = { ...state, [uuid]: i }
      return rv
    }
    case M.OBJ_REMOVECUSTOM: {
      const { ...rv } = state
      return rv
    }
    case M.OBJ_SETOBJECTVISIBLE: {
      const v = state[action.data.uuid]
      if (v) {
        return { ...state, [action.data.uuid]: { ...v, visible: action.data.visible } }
      } else {
        return state
      }
    }
    case M.OBJ_SETOBJECTGROUPVISIBLE:
      if (action.data.group === M.EObjectGroup.CUSTOM) {
        const rv = {}
        for (const [k, v] of Object.entries(state)) {
          rv[k] = { ...v, visible: action.data.visible }
        }
        return rv
      }
      return state

    default:
      return state
  }
}
