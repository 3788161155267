import * as M from 'models'
import { m4FromString, v3FromString, boolFromString, minRangeFromXMLJS, rangeFromXMLJS } from 'utils/XMLJs'
import { MinRange } from 'utils/MinRange'
import { Matrix4 } from 'three'
import { Range } from 'utils/Range'
import * as THREE from 'three'

export default (state = {} as M.Volume, action: any): M.Volume => {
  switch (action.type) {
    case M.OBJ_SETISO: {
      const rv = { ...state } as M.Volume
      rv.mRange = new MinRange(action.data.iso, rv.mRange.dataMin, rv.mRange.dataMax, rv.mRange.moving)
      return rv
    }
    case M.CASE_CLEAR:
      return {} as M.Volume
    case M.OBJ_SETOBJECTGROUPVISIBLE:
      if (action.data.group === M.EObjectGroup.VOLUME) {
        const rv = { ...state } as M.Volume
        rv.visible = action.data.visible
        return rv
      }
      return state
    case M.CASE_LOAD_VIEW_BASIC: {
      const p = action.data.volumeParams
      if (p) {
        const rv = new M.Volume(
          new THREE.Matrix4().identity(),
          true,
          1,
          1,
          1,
          new Range(p.min, p.max, p.dataMin, p.dataMax, false),
          new MinRange(p.iso, p.min, p.max, false),
          new THREE.Vector3(0, 0, 0),
          new THREE.Vector3(p.w * p.voxelSize, p.h * p.voxelSize, p.c * p.voxelSize),
          new Matrix4().identity()
        )
        return rv
      } else {
        return state
      }
    }
    case M.CASE_LOAD_BASIC: {
      const raw = action.data.header.jDentModel.images[0]
      const trd = action.data.header.jDentModel.textureRenderingData[0]
      const rv = new M.Volume(
        m4FromString(raw.transform[0]),
        boolFromString(raw.visible[0]),
        Number(trd.colorTableIndex[0]),
        Number(trd.resolution[0]),
        Number(trd.transparency[0]),
        rangeFromXMLJS(trd.range[0]),
        minRangeFromXMLJS(trd.mRange[0]),
        v3FromString(trd.cBoxLower[0]),
        v3FromString(trd.cBoxUpper[0]),
        raw.roiTransform ? m4FromString(raw.roiTransform[0]) : new Matrix4().identity()
      )
      return rv
    }
    default:
      return state
  }
}
