import { ActionCreators } from 'redux-undo'

export interface IRedoAction {
  undo(): any
  redo(): any
}
export const impl: IRedoAction = {
  undo: (): any => (dispatch: any) => {
    dispatch(ActionCreators.undo())
  },

  redo: (): any => (dispatch: any) => {
    dispatch(ActionCreators.redo())
  },
}
