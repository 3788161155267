import { useSnackbar } from 'notistack'
import { makeStyles } from '@mui/styles'

const useContentStyles = makeStyles({
  root: {
    fontSize: '14px',
  },
  '.SnackbarItem-variantSuccess': {
    backgroundColor: 'purple',
  },
})

const useContainerStyles = makeStyles({
  root: {
    padding: '100px',
    border: '2px solid black',
  },
  anchorOriginBottomCenter: {
    marginBottom: '100px',
  },
})

const useSnack = () => {
  const containerClasses = useContainerStyles()
  const contentClasses = useContentStyles()

  const { enqueueSnackbar } = useSnackbar()

  const enqueue = (title = '', desc, variant) =>
    enqueueSnackbar(`${title.toUpperCase()}${desc ? ` - ${desc}` : ''}`, {
      variant: variant || 'info',
      classes: containerClasses,
      ContentProps: { classes: contentClasses },
    })

  return {
    infoSnack: (desc, title) => enqueue(title, desc, 'info'),
    successSnack: (desc, title) => enqueue(title, desc, 'success'),
    warningSnack: (desc, title) => enqueue(title, desc, 'warning'),
    errorSnack: (desc, title) => enqueue(title, desc, 'error'),
  }
}

export default useSnack
