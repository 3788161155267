import * as M from 'models'

const initialState: M.Zoom = {
  screenLayout: M.EScreenLayout.LEGACY,
  panoramaZoomed: false,
  settings: false,
  menu: false,
  loadingProgressVisible: false,
  loadingProgressPcnt: 0,
  loadingProgressText: '',
  //measureLength: undefined
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    /* case M.EDITOR_SETMEASURE: {
        return { ...state, measureLength: action.data.measureLength }
      }*/
    case M.EDITOR_SETPANELOPEN: {
      // console.log('zoom setlayout!' + action?.data?.layout);
      const rv = { ...state }
      rv[action.data.panelName] = action.data.state
      return rv
    }
    case M.EDITOR_SETLAYOUT: {
      // console.log('zoom setlayout!' + action?.data?.layout);
      return { ...state, screenLayout: action.data.layout }
    }
    case M.EDITOR_SETPANORAMAZOOM: {
      // console.log('panorama zoom !' + action?.data?.zoom);
      return { ...state, panoramaZoomed: action.data.zoom }
    }
    case M.TRAYCONFIG_OPEN: {
      // console.log('trayConfigOpen: ' + action?.data?.trayConfigOpen);
      return { ...state, trayConfigOpen: action.data.trayConfigOpen }
    }
    case M.EDITOR_SETPROGRESSSTATE: {
      return {
        ...state,
        loadingProgressVisible:
          action.data.loadingProgressVisible === undefined
            ? state.loadingProgressVisible
            : action.data.loadingProgressVisible,
        loadingProgressText: action.data.loadingProgressText || state.loadingProgressText,
        loadingProgressPcnt: action.data.loadingProgressPcnt || state.loadingProgressPcnt,
      }
    }
    default:
      return state
  }
}
