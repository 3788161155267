import React from 'react'
import { Button, Grid, Fab } from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import * as A from 'actions'

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props
  const dispatch = useDispatch()

  const handleClose = () => {
    onClose(selectedValue)
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
      <DialogTitle id='simple-dialog-title'>Save Preview image</DialogTitle>
      <img src={props.img[0]} width={500} height={500} />
      <Grid container>
        <Grid item xs={6}>
          <Button variant='contained' color='secondary' onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant='contained'
            color='primary'
            onClick={async () => {
              const response750 = await axios.get(props.img[0], { responseType: 'arraybuffer' })
              const response300 = await axios.get(props.img[1], { responseType: 'arraybuffer' })
              dispatch(A.Case.setPreview(response750.data, response300.data))
              handleClose()
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export const ScreenShotButton = () => {
  const [open, setOpen] = React.useState(false)
  const [imgUrl, setImgUrl] = React.useState(['', ''])
  const crop = function (canvas, offsetX, offsetY, width, height) {
    // create an in-memory canvas
    const url: string[] = []
    for (const b of [750, 300]) {
      const buffer = document.createElement('canvas')
      const b_ctx = buffer.getContext('2d')
      buffer.width = b //width;
      buffer.height = b //height;
      if (b_ctx) {
        b_ctx.drawImage(canvas, offsetX, offsetY, width, height, 0, 0, buffer.width, buffer.height)
        url.push(buffer.toDataURL('image/jpeg'))
      }
    }
    return url
  }
  const createScreenshot = async () => {
    const canvas = document.getElementById('volumePanel') as HTMLCanvasElement
    const sx = Math.min(canvas.width, canvas.height)

    const [imgURL750, imgURL300] = crop(canvas, (canvas.width - sx) / 2, (canvas.height - sx) / 2, sx, sx)
    setImgUrl([imgURL750 as string, imgURL300 as string])
    handleClickOpen()
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (_value) => {
    setOpen(false)
  }
  return (
    <>
      <Fab
        color='primary'
        aria-controls='Screensgot'
        aria-haspopup='true'
        size='small'
        onClick={() => {
          createScreenshot()
        }}
      >
        <CameraAltIcon />
      </Fab>
      <SimpleDialog open={open} img={imgUrl} onClose={handleClose} />
    </>
  )
}
