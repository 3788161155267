/* eslint-disable react/prop-types */
import { Box, Select, MenuItem, useTheme, SelectProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PPCTheme } from './colorUtils'
import Text, { TextProps } from './Text'
import withSideLabel from './WithSideLabel'

const useStyles = makeStyles(() => ({
  root: {
    color: 'black',
  },
  select: {
    color: 'black',
    borderRadius: '0px 0px 6px 6px',
    anchorOrigin: 'bottom',
  },
  nativeInput: {},
}))

declare interface SelectInputItem {
  value: string
  text: string
  disabled?: boolean
}

declare interface SelectInputProps extends Omit<SelectProps, 'onChange' | 'onBlur'> {
  items: SelectInputItem[]
  value?: string
  onChange?: (value: string) => void
  onBlur?: (value: string) => void
  placeHolder?: string
  noEmptyOption?: boolean
  disabled?: boolean
  staticDisable?: boolean
  menuItemTextProps?: TextProps
}

const SelectInput: React.FC<SelectInputProps> = ({
  items,
  onChange,
  onBlur,
  placeHolder,
  noEmptyOption,
  staticDisable,
  disabled,
  value,
  menuItemTextProps,
  ...rest
}) => {
  const classes = useStyles()
  // const itemClasses = useCustomStyles()
  const res = items.find((item) => item.value === value)

  const theme = useTheme() as PPCTheme

  return (
    <Box display='flex' padding='6px'>
      {disabled && staticDisable ? (
        <>
          <Text weight='regular' color={theme.palette.grey.P500}>
            {res?.text || placeHolder}
          </Text>
        </>
      ) : (
        <Select
          classes={classes}
          {...rest}
          value={value}
          disabled={disabled}
          displayEmpty
          onChange={(e) => {
            return onChange && onChange((e as any).target.value)
          }}
          onBlur={(e) => {
            return onBlur && onBlur(e.target.value)
          }}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          renderValue={(current) => {
            const match = items.find((item) => item.value === current)
            return (
              <Text weight='regular' color={res?.text ? 'black' : theme.palette.grey.P500}>
                {match?.text || placeHolder}
              </Text>
            )
          }}
        >
          {placeHolder !== undefined && !noEmptyOption && (
            <MenuItem value=''>
              <Text color='black'>{placeHolder}</Text>
            </MenuItem>
          )}
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
              {typeof item.text === 'string' ? (
                <Text weight='regular' {...menuItemTextProps}>
                  {item.text}
                </Text>
              ) : (
                item.text
              )}
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  )
}

export default withSideLabel<SelectInputProps>(SelectInput)
