import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BackdropDialog } from './Dialog'
import { ReactComponent as ProgressIcon } from 'static/assets/Ripple-1.2s-200px.svg'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 40,
    fontStyle: 'bold',
    fontFamily: 'Roboto',
    textAlign: 'center',
    marginBottom: 0,
    paddingBottom: 0,
  },
  text: {
    color: theme.palette.common.white,
  },
}))

const LoadingIndicator = ({ loading, title, text, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
      <BackdropDialog open={loading} {...props}>
        <Box classes={{ root: classes.box }}>
          <Typography classes={{ root: classes.title }} dangerouslySetInnerHTML={{ __html: t(title) }} />
          <ProgressIcon
            style={{
              margin: 0,
              marginTop: -33,
              marginBottom: -33,
            }}
          />
          <Typography classes={{ root: classes.text }} dangerouslySetInnerHTML={{ __html: t(text) }} />
        </Box>
      </BackdropDialog>
    </>
  )
}

LoadingIndicator.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default LoadingIndicator
