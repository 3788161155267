import * as M from 'models'

export default (state: M.Preview | any = {}, action: any): M.Preview | any => {
  switch (action.type) {
    case M.CASE_CLEAR:
      return {}

    case M.SET_PREVIEW: {
      return {
        jpeg300: action.data.jpeg300,
        jpeg750: action.data.jpeg750,
      }
    }
    default:
      return state
  }
}
