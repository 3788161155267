import * as M from 'models'

export default (st: M.Loading = new M.Loading(M.ELoadingState.NOT_LOADED), action: any) => {
  switch (action.type) {
    case M.CASE_CLEAR:
      return new M.Loading(M.ELoadingState.NOT_LOADED)
    case M.CASE_LOAD_STARTED:
      return { ...st, state: M.ELoadingState.LOAD_STARTED }
    case M.CASE_LOAD_BASIC:
      return { ...st, state: M.ELoadingState.BASIC_LOADED }
    case M.CASE_LOAD_VOLUME:
      return { ...st, state: M.ELoadingState.FULLY_LOADED }
    case M.CASE_SET_LOAD_PROGRESS:
      // console.log(JSON.stringify({ ...st, [action.data.phase]: action.data.n }))
      return { ...st, [action.data.phase]: action.data.n }
    default:
      return st
  }
}
