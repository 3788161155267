// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInstrumentsAction {
  // loadImplantFamily (data: any): any;
  // loadTray (data: M.Tray): any;
}

export const impl: IInstrumentsAction = {
  // load implant from xml
  /* loadImplantFamily: (data: any): any => (dispatch: any) => {
    dispatch({ type: M.INSTRUMENTS_LOAD_IMPLANTFAMILY, data });
  }, */
  // teszt ply load
  /* loadTray: (tray: M.Tray): any => (dispatch: any) => {
    dispatch({ type: M.INSTRUMENTS_LOAD_TRAY, data: {tray} });
  } */
}
