export const NO_FILES = []

export const readWholeFile = (f, asDataUrl) => {
  return new Promise((res) => {
    const reader = new FileReader()

    if (asDataUrl) {
      reader.readAsDataURL(f)
    } else {
      reader.readAsArrayBuffer(f)
    }
    reader.addEventListener('load', (event) => {
      const { result } = event.target
      res(result)
    })
  })
}

export const formatSize = (size) => {
  if (size > 1024 * 1024 * 100) {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`
  }
  if (size > 1024 * 100) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`
  }
  if (size > 1024) {
    return `${(size / (1024 * 100)).toFixed(2)} kB`
  }
  return size
}

export const allFilesSize = (files, format) => {
  const size = files.reduce((p, { file: f }) => p + f.size, 0)
  return format ? formatSize(size) : size
}
