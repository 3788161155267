import { Vector3, Color, Matrix4 } from 'three'

export enum EObjectGroup {
  NONE = 0,
  PIN,
  IMPLANT,
  SLICE,
  NERVE,
  VOLUME,

  IMPRESSION,
  GIMPRESSION,
  ANTAGONIST,
  BITE,

  TOOTH,
  IMPLANTAXIS,
  SLEEVE,
  IMPLANTCOLLIDER,
  CROPPEDVISIBLE,
  DRILL,
  CUSTOM,
}

export class CObject {
  public zoomOverride = 0
  constructor(
    public name: string,
    public uuid: string,
    public color: Color,
    // transform matrix of the implant, pointing to the CENTER of the implant, not the origo
    public transform: Matrix4,
    public transparency: number,
    public visible: boolean,
    public filled: boolean,
    public objectState: number,
    public intersect: boolean,
    // normal of the slice
    public directionX: Vector3,
    public directionY: Vector3,
    // axis of the implant
    public directionZ: Vector3,
    // location of the impant's origo (normally the outer part of the implant, where it connects with the protethics)
    public location: Vector3,
    public angleW: number,
    public angleH: number,
    public valid: boolean,
    public drillLength: number,
    public center: Vector3,
    public length: number,
    public diameter: number,
    public sleeveLength: number,
    public sleeveInnerDiameter: number,
    public sleeveOuterDiameter: number,
    // public sleeveTr: Matrix4,
    public axisVisibility: boolean,
    public propVisibility: boolean,
    public sleeveVisibility: boolean,
    public sliceTR: Matrix4,
    public type: EObjectGroup
  ) {}
}
