import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'

import createRootReducer from '../reducers'

declare const __DEV__: boolean

interface Argv {
  initialState?: object
  url?: string
}

export default ({ initialState }: Argv) => {
  // Create a history depending on the environment
  const history = createBrowserHistory()
  const middlewares = [
    routerMiddleware(history),
    thunk,
    // Add other middlewares here
  ]
  // Use Redux DevTools Extension in development
  const composeEnhancers = compose
  const enhancers = composeEnhancers(
    applyMiddleware(...middlewares)
    // Add other enhancers here
  )
  const store = createStore(createRootReducer(history), initialState || {}, enhancers)

  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    ;(module as any).hot.accept('../reducers', () => {
      try {
        const createNextReducer = require('../reducers').default

        store.replaceReducer(createNextReducer(history))
      } catch (error) {
        //console.error(`==> 😭  Reducer hot reloading error ${error}`)
        // logger.error({error: error}, 'Reducer hot reloading error' );
      }
    })
  }

  return { store, history }
}
