/* Generated from Java with JSweet 3.0.0-SNAPSHOT - http://www.jsweet.org */
import { DicomVolume } from './DicomVolume'

// namespace com.dicomlab.jdent.data {
export class BrickedVolume {
  static vTable: number[][]

  // eslint-disable-next-line camelcase
  public static vTable_$LI$(): number[][] {
    if (BrickedVolume.vTable == null) {
      BrickedVolume.vTable = [
        [0, 0, 0],
        [0, 0, 1],
        [0, 1, 0],
        [0, 1, 1],
        [1, 0, 0],
        [1, 0, 1],
        [1, 1, 0],
        [1, 1, 1],
      ]
    }
    return BrickedVolume.vTable
  }

  static tTable: number[][]

  // eslint-disable-next-line camelcase
  public static tTable_$LI$(): number[][] {
    if (BrickedVolume.tTable == null) {
      BrickedVolume.tTable = [
        [0, 1, 3],
        [3, 2, 0],
        [3, 1, 0],
        [0, 2, 3],
        [1, 0, 4],
        [4, 5, 1],
        [4, 0, 1],
        [1, 5, 4],
        [0, 2, 6],
        [6, 4, 0],
        [6, 2, 0],
        [0, 4, 6],
      ]
    }
    return BrickedVolume.tTable
  }

  /* private */ vertices: number[]

  /* private */ croppedVertices: Float32Array = new Float32Array()

  /* private */ indices: Uint32Array[]

  /* private */ brickMinMax: number[][][][] = [[[[]]]]

  /* private */ toXIndex: number[] = []

  /* private */ toYIndex: number[] = []

  /* private */ toZIndex: number[] = []

  /* private */ vSize = 0

  /* private */ iSize = 0

  /* private */ hVCount = 0

  /* private */ wVCount = 0

  /* private */ dVCount = 0

  /* private */ croppedHVCount = 0

  /* private */ croppedWVCount = 0

  /* private */ croppedDVCount = 0

  public constructor(stack: DicomVolume) {
    const original: number[][][][] = stack.getBrickMinMax()
    const wBCount: number = stack.getWBCount()
    const hBCount: number = stack.getHBCount()
    const dBCount: number = stack.getDBCount()
    const w: number = stack.getWidth()
    const h: number = stack.getHeight()
    const d: number = stack.getDepth()

    this.wVCount = wBCount + 1
    this.hVCount = hBCount + 1
    this.dVCount = dBCount + 1
    this.vSize = this.dVCount * this.hVCount * this.wVCount * 3
    this.vertices = ((): number[] => {
      const a: number[] = []
      let s = this.vSize
      while (s-- > 0) {
        a.push(0)
      }
      return a
    })()
    let vIndex = 0
    for (let i = 0; i < this.dVCount; i++) {
      {
        for (let j = 0; j < this.hVCount; j++) {
          {
            for (let k = 0; k < this.wVCount; k++) {
              {
                const x: number = (<any>Math).fround((<any>Math).fround(<number>k * DicomVolume.BRICK_LENGTH) / w)
                const y: number = (<any>Math).fround((<any>Math).fround(<number>j * DicomVolume.BRICK_LENGTH) / h)
                const z: number = (<any>Math).fround((<any>Math).fround(<number>i * DicomVolume.BRICK_LENGTH) / d)
                this.vertices[vIndex++] = x < 1 ? x : 1.0
                this.vertices[vIndex++] = y < 1 ? y : 1.0
                this.vertices[vIndex++] = z < 1 ? z : 1.0
                if (i < dBCount && j < hBCount && k < wBCount) {
                  this.brickMinMax[i][j][k][0] = original[i][j][k][0]
                  this.brickMinMax[i][j][k][1] = original[i][j][k][1]
                }
              }
            }
          }
        }
      }
    }
    this.computeVertices(0.0, 1.0, 0.0, 1.0, 0.0, 1.0)
    this.indices = []
  }

  public computeTriangles(min: number, max: number, mode: number) {
    const __in: boolean[][][] = <any>(function (dims) {
      const allocate = function (dims) {
        if (dims.length === 0) {
          return false
        }
        const array: any[] = []
        for (let i = 0; i < dims[0]; i++) {
          array.push(allocate(dims.slice(1)))
        }
        return array
      }
      return allocate(dims)
    })([this.croppedDVCount + 1, this.croppedHVCount + 1, this.croppedWVCount + 1])
    for (let i = 1; i < this.croppedDVCount; i++) {
      {
        for (let j = 1; j < this.croppedHVCount; j++) {
          {
            for (let k = 1; k < this.croppedWVCount; k++) {
              {
                __in[i][j][k] =
                  this.brickMinMax[this.toZIndex[i - 1]][this.toYIndex[j - 1]][this.toXIndex[k - 1]][1] >= min
              }
            }
          }
        }
      }
    }
    const vLayer: number = this.croppedHVCount * this.croppedWVCount
    let indicesArray: Array<number> = <any>[]
    const faces: number[] = [0, 0]
    for (let i = 0; i < this.croppedDVCount; i++) {
      {
        for (let j = 0; j < this.croppedHVCount; j++) {
          {
            for (let k = 0; k < this.croppedWVCount; k++) {
              {
                const curr: boolean = __in[i + 1][j + 1][k + 1]
                let nb: boolean = __in[i + 1][j + 1][k]
                if (curr !== nb) {
                  if (curr) {
                    faces[0] = 0
                    faces[1] = 1
                  } else {
                    faces[0] = 2
                    faces[1] = 3
                  }
                  for (let t = 0; t < 2; t++) {
                    {
                      for (let v = 0; v < 3; v++) {
                        {
                          const trans: number[] = BrickedVolume.vTable_$LI$()[BrickedVolume.tTable_$LI$()[faces[t]][v]]
                          const idx: number =
                            (i + trans[2]) * vLayer + (j + trans[1]) * this.croppedWVCount + (k + trans[0])
                          /* add */ indicesArray.push(idx)
                        }
                      }
                    }
                  }
                }
                nb = __in[i + 1][j][k + 1]
                if (curr !== nb) {
                  if (curr) {
                    faces[0] = 4
                    faces[1] = 5
                  } else {
                    faces[0] = 6
                    faces[1] = 7
                  }
                  for (let t = 0; t < 2; t++) {
                    {
                      for (let v = 0; v < 3; v++) {
                        {
                          const trans: number[] = BrickedVolume.vTable_$LI$()[BrickedVolume.tTable_$LI$()[faces[t]][v]]
                          const idx: number =
                            (i + trans[2]) * vLayer + (j + trans[1]) * this.croppedWVCount + (k + trans[0])
                          /* add */ indicesArray.push(idx)
                        }
                      }
                    }
                  }
                }
                nb = __in[i][j + 1][k + 1]
                if (curr !== nb) {
                  if (curr) {
                    faces[0] = 8
                    faces[1] = 9
                  } else {
                    faces[0] = 10
                    faces[1] = 11
                  }
                  for (let t = 0; t < 2; t++) {
                    {
                      for (let v = 0; v < 3; v++) {
                        {
                          const trans: number[] = BrickedVolume.vTable_$LI$()[BrickedVolume.tTable_$LI$()[faces[t]][v]]
                          const idx: number =
                            (i + trans[2]) * vLayer + (j + trans[1]) * this.croppedWVCount + (k + trans[0])
                          /* add */ indicesArray.push(idx)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    this.iSize = /* size */ <number>indicesArray.length
    this.indices[mode] = new Uint32Array(this.iSize)
    // (s => { let a=[]; while(s-->0) a.push(0); return a; })(this.iSize);
    let index = 0
    for (let index445 = 0; index445 < indicesArray.length; index445++) {
      const i = indicesArray[index445]
      {
        this.indices[mode][index++] = i
      }
    }
    /* clear */ indicesArray.length = 0
    indicesArray = []
  }

  public getVertices(): Float32Array {
    return this.croppedVertices
  }

  public getIndices(mode: number): Uint32Array {
    return this.indices[mode]
  }

  /* private */ computeVertices(x0: number, x1: number, y0: number, y1: number, z0: number, z1: number): void {
    const tempX: Array<number> = <any>[]
    const tempXIndex: Array<number> = <any>[]
    for (let i = 0; i < this.wVCount - 1; i++) {
      {
        const xs: number = this.vertices[i * 3]
        const xe: number = this.vertices[(i + 1) * 3]
        if (xs >= x0 && xs <= x1) {
          /* add */ tempX.push(xs)
          /* add */ tempXIndex.push(i)
        }
        if (x0 !== 0.0 && x0 > xs && x0 < xe) {
          /* add */ tempX.push(x0)
          /* add */ tempXIndex.push(i)
        }
        if (x1 !== 1.0 && x1 > xs && x1 < xe) {
          /* add */ tempX.push(x1)
          /* add */ tempXIndex.push(i + 1)
          break
        }
        if (i === this.wVCount - 2 && xe >= x0 && xe <= x1) {
          /* add */ tempX.push(xe)
          /* add */ tempXIndex.push(i + 1)
        }
      }
    }
    const tempY: Array<number> = <any>[]
    const tempYIndex: Array<number> = <any>[]
    for (let i = 0; i < this.hVCount - 1; i++) {
      {
        const ys: number = this.vertices[this.wVCount * i * 3 + 1]
        const ye: number = this.vertices[this.wVCount * (i + 1) * 3 + 1]
        if (ys >= y0 && ys <= y1) {
          /* add */ tempY.push(ys)
          /* add */ tempYIndex.push(i)
        }
        if (y0 !== 0.0 && y0 > ys && y0 < ye) {
          /* add */ tempY.push(y0)
          /* add */ tempYIndex.push(i)
        }
        if (y1 !== 1.0 && y1 > ys && y1 < ye) {
          /* add */ tempY.push(y1)
          /* add */ tempYIndex.push(i + 1)
          break
        }
        if (i === this.hVCount - 2 && ye >= y0 && ye <= y1) {
          /* add */ tempY.push(ye)
          /* add */ tempYIndex.push(i + 1)
        }
      }
    }
    const tempZ: Array<number> = <any>[]
    const tempZIndex: Array<number> = <any>[]
    for (let i = 0; i < this.dVCount - 1; i++) {
      {
        const zs: number = this.vertices[this.hVCount * this.wVCount * i * 3 + 2]
        const ze: number = this.vertices[this.hVCount * this.wVCount * (i + 1) * 3 + 2]
        if (zs >= z0 && zs <= z1) {
          /* add */ tempZ.push(zs)
          /* add */ tempZIndex.push(i)
        }
        if (z0 !== 0.0 && z0 > zs && z0 < ze) {
          /* add */ tempZ.push(z0)
          /* add */ tempZIndex.push(i)
        }
        if (z1 !== 1.0 && z1 > zs && z1 < ze) {
          /* add */ tempZ.push(z1)
          /* add */ tempZIndex.push(i + 1)
          break
        }
        if (i === this.dVCount - 2 && ze >= z0 && ze <= z1) {
          /* add */ tempZ.push(ze)
          /* add */ tempZIndex.push(i + 1)
        }
      }
    }

    this.croppedVertices = new Float32Array(tempX.length * tempY.length * tempZ.length * 3)
    let index = 0
    for (let index446 = 0; index446 < tempZ.length; index446++) {
      const z = tempZ[index446]
      for (let index447 = 0; index447 < tempY.length; index447++) {
        const y = tempY[index447]
        for (let index448 = 0; index448 < tempX.length; index448++) {
          const x = tempX[index448]
          {
            this.croppedVertices[index++] = x
            this.croppedVertices[index++] = y
            this.croppedVertices[index++] = z
          }
        }
      }
    }
    this.croppedWVCount = /* size */ <number>tempX.length
    this.croppedHVCount = /* size */ <number>tempY.length
    this.croppedDVCount = /* size */ <number>tempZ.length
    this.toXIndex = (() => {
      const a: number[] = []
      let s = <number>tempXIndex.length
      while (s-- > 0) {
        a.push(0)
      }
      return a
    })()
    index = 0
    for (let index449 = 0; index449 < tempXIndex.length; index449++) {
      const i = tempXIndex[index449]
      this.toXIndex[index++] = i
    }
    this.toYIndex = (() => {
      const a: number[] = []
      let s = <number>tempYIndex.length
      while (s-- > 0) {
        a.push(0)
      }
      return a
    })()
    index = 0
    for (let index450 = 0; index450 < tempYIndex.length; index450++) {
      const i = tempYIndex[index450]
      this.toYIndex[index++] = i
    }
    this.toZIndex = (() => {
      const a: number[] = []
      let s = <number>tempZIndex.length
      while (s-- > 0) {
        a.push(0)
      }
      return a
    })()
    index = 0
    for (let index451 = 0; index451 < tempZIndex.length; index451++) {
      const i = tempZIndex[index451]
      this.toZIndex[index++] = i
    }
    /* clear */ tempX.length = 0
    /* clear */ tempY.length = 0
    /* clear */ tempZ.length = 0
    /* clear */ tempXIndex.length = 0
    /* clear */ tempYIndex.length = 0
    /* clear */ tempZIndex.length = 0
  }

  /* public refreshBrickCoordinates(data : com.dicomlab.jdent.data.VolumeRendererData) {
            this.computeVertices(data.getUnitBBLower()[0], data.getUnitBBUpper()[0], data.getUnitBBLower()[1], data.getUnitBBUpper()[1], data.getUnitBBLower()[2], data.getUnitBBUpper()[2]);
        } */

  public clear(): void {
    this.vertices = []
    this.croppedVertices = new Float32Array()
    this.indices = []
    this.brickMinMax = [[[[]]]]
    this.toXIndex = []
    this.toYIndex = []
    this.toZIndex = []
  }
}

BrickedVolume.tTable_$LI$()
BrickedVolume.vTable_$LI$()
