import * as M from 'models/case'
import * as A from 'models/case/Actions'

export interface ICaseAction {
  clearCase(): any
  clearVolume(): any
  loadVolumeData(volume: M.DicomVolume | null): any
  loadStarted(): any
  setLoadingProgress(phase: string, n: number): any
  roleChanged(role: M.SGRoles): any
  loadDCMForView(volumeParams: any): any
  loadSTLForView(stlParams: any, options: any): any
  setPreview(jpeg750: ArrayBuffer, jpeg300: ArrayBuffer): any
}

export const impl: ICaseAction = {
  setPreview:
    (jpeg750: ArrayBuffer, jpeg300: ArrayBuffer): any =>
    (dispatch: any) => {
      dispatch({ type: A.SET_PREVIEW, data: { jpeg300, jpeg750, disableUndo: true } })
    },
  loadDCMForView:
    (volumeParams: any): any =>
    (dispatch: any) => {
      dispatch({ type: A.CASE_LOAD_VIEW_BASIC, data: { volumeParams, disableUndo: true } })
    },
  loadSTLForView:
    (stlParams: any, options: any): any =>
    (dispatch: any) => {
      dispatch({ type: A.CASE_LOAD_VIEW_BASIC, data: { stlParams, options, disableUndo: true } })
    },
  setLoadingProgress:
    (phase: string, n: number): any =>
    (dispatch: any) => {
      dispatch({ type: A.CASE_SET_LOAD_PROGRESS, data: { phase, n, disableUndo: true } })
    },
  loadStarted: (): any => (dispatch: any) => {
    dispatch({ type: A.CASE_LOAD_STARTED, data: { disableUndo: true } })
  },
  loadVolumeData:
    (volume: M.DicomVolume | null): any =>
    (dispatch: any) => {
      dispatch({ type: A.CASE_LOAD_VOLUME, data: { volume, disableUndo: true } })
    },
  clearCase: (): any => (dispatch: any) => {
    dispatch({ type: A.CASE_CLEAR, data: {} })
  },
  clearVolume: (): any => (dispatch: any) => {
    dispatch({ type: A.CASE_CLEAR_VOLUME, data: {} })
  },
  roleChanged:
    (role: M.SGRoles): any =>
    (dispatch: any) => {
      dispatch({ type: A.CASE_ROLE_CHANGED, data: { role, disableUndo: true } })
    },
}
