import PropTypes from 'prop-types'

import { Fade, Box, CircularProgress } from '@mui/material'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    border: '2px solid rgba(255,255,255,0.2)',

    borderRadius: 'inherit',
    cursor: 'progress',
    backgroundColor: 'rgba(0,0,0,0.1)',

    '& > .MuiCircularProgress-root': {
      color: 'white',
      position: 'absolute',
      left: '50%',
      top: '50%',
      width: 40,
      height: 40,
    },
  },
})

const FadeLoader = ({ loading }) => {
  const classes = useStyles()
  return (
    <Fade in={loading}>
      <Box className={classes.root}>
        <CircularProgress />
      </Box>
    </Fade>
  )
}

FadeLoader.propTypes = {
  loading: PropTypes.bool,
}

export default FadeLoader
