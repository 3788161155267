import { Color } from 'three'
import { Range } from 'utils/Range'
import { MinRange } from 'utils/MinRange'
import { IObjMap } from 'utils/IObjMap'
import * as THREE from 'three'
import { BrickedVolume } from './BrickedVolume'
import { DicomVolume } from './DicomVolume'
import { EObjectGroup } from './CObject'
import { CompleteGeometry } from './CompleteGeometry'

// const kEpsilon = 0.0001;

// import { ToothModelStaticLoader_0 } from 'data/StaticToothModels'

export enum ELoadingState {
  NOT_LOADED = 1,
  LOAD_STARTED = 2,
  BASIC_LOADED = 3,
  VOLUME_LOADING = 4,
  FULLY_LOADED = 5,
}

export class Loading {
  public tray: number

  public dipExternal: number

  public volume: number

  public static: number

  constructor(public state: ELoadingState) {
    this.volume = 0
    this.tray = 0
    this.dipExternal = 0
    this.static = 0
  }

  static getProgress(l: Loading): number {
    const wTray = 0.15
    const wDipExternal = 0.15
    const wVolume = 0.6
    const wStatic = 0.1

    const rv = l.tray * wTray + l.dipExternal * wDipExternal + l.volume * wVolume + l.static * wStatic
    return rv
  }
}

export enum SGRoles {
  DOCTOR_LIMITED = 'DL',
  DOCTOR_FULL = 'DF',
  DOCTOR_VIEWER = 'DV',
  MENTOR_EDITOR = 'ME',
  MENTOR_VIEWER = 'MV',
  MENTOR_READONLY = 'MRO',
  PROTPREPARE = 'PROTPREPARE',
  PROTPOSTSTEP = 'PROTPOSTSTEP',
  VIEWER = 'VIEWER', //scene viewer&downloader
  UNKNOWN = 'UNKNOWN',
}

export function SGRolesFromString(val: string): SGRoles {
  switch (val) {
    case 'DL':
      return SGRoles.DOCTOR_LIMITED
    case 'DF':
      return SGRoles.DOCTOR_FULL
    case 'DV':
      return SGRoles.DOCTOR_VIEWER
    case 'ME':
      return SGRoles.MENTOR_EDITOR
    case 'MV':
      return SGRoles.MENTOR_VIEWER
    case 'MRO':
      return SGRoles.MENTOR_READONLY
    case 'PROTPREPARE':
      return SGRoles.PROTPREPARE
    case 'PROTPOSTSTEP':
      return SGRoles.PROTPOSTSTEP
    case 'VIEWER':
      return SGRoles.VIEWER
    case 'UNKNOWN': // Fall through
    default:
      return SGRoles.UNKNOWN
  }
}
export class Volume {
  constructor(
    public transform: THREE.Matrix4,
    public visible: boolean,
    public colorTableIndex: number,
    public resolution: number,
    public transparency: number,
    public range: Range,
    public mRange: MinRange,
    public cropLower: THREE.Vector3,
    public cropUpper: THREE.Vector3, // eRange: Range
    public roiTransform: THREE.Matrix4 //inverse model->world matrix of the original CT image
  ) {}
}
export class VolumeStatic {
  constructor(
    public volume?: DicomVolume // public brickedVolume?: BrickedVolume
  ) {}
}

export enum ESliceLock {
  UNLOCKED = 1,
  LOCKED_MD = 2,
  LOCKED_OV = 3,
  LOCKED_SELECTED = 4,
}
export enum EMeasureState {
  NO = 1,
  DISTANCE = 2,
  ANGLE = 3,
}
export enum EMeasureSubState {
  NO = 1,
  FIRST = 2,
  SECOND = 3,
}
export class Slice {
  public visualCenter: THREE.Vector3
  public measure: EMeasureState = EMeasureState.NO
  public measureSubState?: EMeasureSubState
  public measuredValue?: number
  constructor(
    public color: Color,
    public width: number,
    public height: number,
    public visible: boolean,

    public qCoordinates: number[],
    //  public lCoordinate: number[],
    public normals: number[],
    public texCoords: number[],

    public lockedOnObject: string,
    public objectType: EObjectGroup.PIN | EObjectGroup.IMPLANT | undefined,
    public center: THREE.Vector3,
    public normal: THREE.Vector3,
    public up: THREE.Vector3,
    // public rotationDir: THREE.Vector3,
    public modeToView: THREE.Matrix4,
    // szurkearnyalatos valami
    public range: Range,
    public gamma: number,
    public lockState: ESliceLock = ESliceLock.UNLOCKED,
    public posInArch: number,
    public zoom: number
  ) {
    this.visualCenter = center
  }

  public static getColorByLockState(slice: Slice, visual: VisualConfig): THREE.Color {
    let color
    switch (slice.lockState) {
      case ESliceLock.UNLOCKED:
        color = visual.sliceFRColor
        break
      case ESliceLock.LOCKED_MD:
        color = visual.sliceMDColor
        break
      case ESliceLock.LOCKED_OV:
        color = visual.sliceOVColor
        break
      default:
        color = visual.sliceFRColor
    }
    return color
  }
}

export class Custom {
  constructor(
    public uuid: string,
    public name: string,
    public color: Color,
    public transform: THREE.Matrix4,
    public visible: boolean,
    //      public type: number,
    public customGeometry: CompleteGeometry,
    public hasVertexColor: boolean,
    public material: any
  ) {}
}

export class VisualConfig {
  sliceMDColor: THREE.Color | undefined

  sliceOVColor: THREE.Color | undefined

  sliceFRColor: THREE.Color | undefined

  sliceSelectedColor: THREE.Color | undefined

  sliceMarkerOnPanorama: number | undefined

  sliceScreenBGColor: THREE.Color | undefined

  panoramaScreenBGColor: THREE.Color | undefined

  implantCollider: THREE.Color | undefined

  impressionColor: THREE.Color | undefined

  antagonistIntersectionColor: THREE.Color | undefined

  biteIntersectionColor: THREE.Color | undefined

  implantAxisColor: THREE.Color | undefined

  selectedImplantColor: THREE.Color | undefined

  selectedImplantAxisColor: THREE.Color | undefined

  sleeveColor: THREE.Color | undefined

  hooveredImplantColor: THREE.Color | undefined

  planWarningColor: THREE.Color | undefined

  planErrorColor: THREE.Color | undefined
}

/* export enum EScreenLayout { // goes to Zoom
    LEGACY = 1,
    E3DFOCUS = 2
} */
// editor settings
export enum EAppMode {
  SGJS,
  PROTHETICS_PREPARE,
  PROTHETICS_POSTPROCESS,
  CASE_VIEWER,
  VIEWER,
}
export class EditorConfig {
  appMode: EAppMode = EAppMode.VIEWER

  /*  screenLayout: EScreenLayout
      panoramaZoomed: boolean */
  croppedVisible = false
  showViewerPanel = true
  toothVisible = true
}
export class Preview {
  jpeg750?: ArrayBuffer
  jpeg300?: ArrayBuffer
}

export class CaseState {
  loading: Loading = new Loading(ELoadingState.NOT_LOADED)

  slice?: Slice

  customObjects: IObjMap<Custom> = {}

  volume?: Volume

  volumeStatic: VolumeStatic = new VolumeStatic()

  visualConfig: VisualConfig = new VisualConfig() // color configs etc

  editorConfig: EditorConfig = new EditorConfig()

  preview: Preview = new Preview()
}

export { BrickedVolume, DicomVolume }
export * from './Actions'
export * from './CObject'
export * from './CompleteGeometry'
