import { combineReducers } from 'redux'
import { History } from 'history'
import { connectRouter } from 'connected-react-router'

import Case from './case'
import Assets from './Assets'
import Zoom from './Zoom'
import VolumePanel from './VolumePanel'
import themingReducer from './ThemingReducer'
import InternalWindowMessageReducer from './InternalWindowMessageReducer'

export default (history: History) =>
  combineReducers({
    // Register reducers here
    case: Case,
    assets: Assets,
    volumePanel: VolumePanel,
    zoom: Zoom,
    themingReducer,
    lastInternalWindowMessage: InternalWindowMessageReducer,
    router: connectRouter(history),
  })
