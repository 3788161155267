import PropTypes from 'prop-types'

import { RadioGroup as Grp, Radio as R, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import withSideLabel from './WithSideLabel'

const RadioGroup = ({ onChange, value, items, FormControlProps, disabled, ...rest }) => {
  const { t } = useTranslation()

  return (
    <Grp row value={value} onChange={(e) => onChange(e.target.value)} {...rest}>
      {items.map(({ value: optionValue, text: optionLabel }) => (
        <FormControlLabel
          key={optionValue}
          value={optionValue}
          control={<R color='primary' />}
          label={t(optionLabel)}
          disabled={disabled}
          {...FormControlProps}
        />
      ))}
    </Grp>
  )
}

RadioGroup.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  FormControlProps: PropTypes.object,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
}

const RadioGroupWithSideLabel = withSideLabel(RadioGroup)

export { RadioGroupWithSideLabel }
export default RadioGroup
