import { VolumeStatic, CASE_LOAD_VOLUME, CASE_CLEAR_VOLUME } from 'models'

export default (state = {} as VolumeStatic, action: any) => {
  switch (action.type) {
    case CASE_CLEAR_VOLUME:
      return {}
    case CASE_LOAD_VOLUME: {
      const rv = new VolumeStatic(
        action.data.volume
        // action.data.brickedVolume
      )
      return rv
    }
    default:
      return state
  }
}
