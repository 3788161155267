import * as THREE from 'three'
import { STLLoader } from './STLLoader.js'
import { PLYLoader } from './PLYLoader.js'
import { OBJLoader2 } from './OBJLoader2.js'

const draco = require('./DRACOLoader.js')
const DRACOLoader = draco.DRACOLoader

const taskConfig = {
  attributeIDs: {
    position: 'POSITION',
    color: 'COLOR',
    // uv: 'TEX_COORD'
  },
  attributeTypes: {
    position: 'Float32Array',
    color: 'Float32Array',
    // uv: 'Float32Array'
  },
  useUniqueIDs: false,
}

export async function parseMesh(buffer: ArrayBuffer, formats: string[], progressCB?: (number) => void): Promise<THREE.BufferGeometry> {
  let rv

  for (let k of formats) {
    try {
      switch (k) {
        case 'stl':
          rv = new STLLoader().parse(buffer)
          break
        case 'ply':
          rv = (new PLYLoader() as any).parse(buffer)
          break
        case 'obj':
          rv = (new OBJLoader2() as any).parse(buffer)
          break
        case 'drc':
          const dracoLoader = new DRACOLoader() as any
          dracoLoader.setDecoderPath('/scripts/')
          dracoLoader.setDecoderConfig({ type: 'wasm' }) // .js
          await dracoLoader._initDecoder()
          rv = await dracoLoader.decodeGeometry(buffer, taskConfig)
          rv.computeVertexNormals()

          break
      }

      if (rv?.getAttribute('position')?.count === 0) {
        rv = undefined
      }
      if (rv){
        break
      }
    } catch (e: any) {
      console.log('exc:' + e.stack)
    }
  }
  return rv
}
