/* eslint-disable react/prop-types */
import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { IconButton, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import hexToRgba from 'hex-to-rgba'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import Button from './Button'
import Text from './Text'

export const usePPCButtonStyles = makeStyles(
  (theme) => ({
    nextText: {},
    processSecondaryButton: {
      color: theme.palette.common.white,
      border: `2px solid ${theme.palette.common.white}`,
      '&.Mui-disabled': {
        color: 'rgba(255,255,255,0.7)',
        '& svg': {
          color: 'rgba(255,255,255,0.7)',
        },
        border: `2px solid ${theme.palette.common.white}`,
      },
      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.common.white,
        border: '2px solid transparent',
      },
      '&:hover svg': {
        color: theme.palette.primary.dark,
        fill: theme.palette.primary.dark,
      },
      '& svg': {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
      },
    },
    processPrimaryButton: {
      '&.Mui-disabled': {
        color: 'rgba(255,255,255,0.7)',
        '& svg': {
          color: 'rgba(255,255,255,0.7)',
        },
      },
      '& svg': {
        color: theme.palette.common.white,
      },
    },
    normalPrimaryButton: {
      boxShadow: 'none',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    normalSecondaryButton: {
      color: ({ blend }) => (blend ? theme.palette.common.white : theme.palette.primary.main),
      border: ({ blend }) => `2px solid ${blend ? theme.palette.common.white : theme.palette.primary.main}`,
      '&:hover': {
        color: ({ blend }) => (blend ? theme.palette.primary.main : theme.palette.common.white),
        backgroundColor: ({ blend }) => (blend ? theme.palette.common.white : theme.palette.primary.main),
        border: '2px solid transparent',
      },
    },
    normalPrimaryAlertButton: {
      boxShadow: 'none',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    normalSecondaryAlertButton: {
      color: ({ blend }) => (blend ? theme.palette.common.white : theme.palette.error.main),
      border: ({ blend }) => `2px solid ${blend ? theme.palette.common.white : theme.palette.error.main}`,
      '&:hover': {
        color: ({ blend }) => (blend ? theme.palette.error.main : theme.palette.common.white),
        backgroundColor: ({ blend }) => (blend ? theme.palette.common.white : theme.palette.error.main),
        border: '2px solid transparent',
      },
    },
    normalPrimaryBackButton: {
      boxShadow: 'none',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    normalSecondaryBackButton: {
      color: theme.palette.common.black,
      border: '2px solid #d5d5d5',
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: '#868686',
        border: '2px solid transparent',
      },
    },
    SGJSButton: {
      '&:hover svg': {
        color: theme.palette.common.white,
      },
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
    textButton: {
      backgroundColor: 'transparent',
      color: theme.palette.black,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      '&:hover svg': {
        color: theme.palette.common.white,
      },
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
    textAlertButton: {
      backgroundColor: 'transparent',
      color: theme.palette.black,
      '&:hover svg': {
        color: theme.palette.common.white,
      },
      '& svg': {
        color: theme.palette.error.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
      },
    },
    tabButton: {
      color: theme.palette.grey[400],
      backgroundColor: 'transparent',
      borderRadius: 0,
      borderBottom: '3px solid transparent',
      fontSize: 14,
      minWidth: 200,

      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: hexToRgba(theme.palette.primary.light, 0.5),
      },

      '& .MuiButton-startIcon': {
        padding: 6,
      },

      '&:disabled': {
        color: 'black',
        backgroundColor: 'transparent',
        borderRadius: 0,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }),
  { link: true }
)

export const ProcessButton = (props) => {
  const { t } = useTranslation()
  const classes = usePPCButtonStyles()
  const { prev, next, prevIcon, nextIcon, showNextText, secondary, label, sub, ...rest } = props
  const text = label && typeof label === 'string' ? label.toUpperCase() : ''
  const nextText = (
    <Box display='flex' flexDirection='row'>
      <Text weight='bold' size='medium' style={{ marginRight: 7 }}>
        {`${t('i18n.next').toUpperCase()}:`}
      </Text>
      <Text weight='regular' size='medium'>
        {text}
      </Text>
    </Box>
  )
  return (
    <Button
      className={secondary ? classes.processSecondaryButton : classes.processPrimaryButton}
      startIcon={(prev || prevIcon) && (prevIcon || <NavigateBeforeIcon />)}
      endIcon={(next || nextIcon) && (nextIcon || <NavigateNextIcon />)}
      text=''
      size='large'
      variant={secondary ? 'outlined' : 'contained'}
      color={!secondary ? 'primary' : undefined}
      {...rest}
    >
      <Box display='flex' flexDirection='column' style={{ lineHeight: 1.3 }}>
        <span>
          {showNextText && nextText}
          {!showNextText && text}
        </span>
        {sub && <span style={{ fontSize: 14 }}>{sub}</span>}
      </Box>
    </Button>
  )
}

ProcessButton.propTypes = {
  label: PropTypes.string,
  sub: PropTypes.string,
  prev: PropTypes.bool,
  next: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  showNextText: PropTypes.bool,
  prevIcon: PropTypes.object,
  nextIcon: PropTypes.object,
}

export const TextButton = (props) => {
  const { alert, label, icon, ...rest } = props
  const classes = usePPCButtonStyles()
  return (
    <Button
      className={alert ? classes.textAlertButton : classes.textButton}
      size='small'
      text={label}
      startIcon={icon}
      {...rest}
    />
  )
}

TextButton.propTypes = {
  label: PropTypes.string.isRequired,
  alert: PropTypes.bool,
  icon: PropTypes.object,
}

export const SGJSButton = (props) => {
  const { size, label, icon, ...rest } = props
  const classes = usePPCButtonStyles()
  const upperCaseTitle = label?.toUpperCase()
  return (
    <Button
      className={classes.SGJSButton}
      size={size}
      text={upperCaseTitle}
      startIcon={icon}
      variant='contained'
      color='primary'
      style={{ marginBottom: 14 }}
      {...rest}
    />
  )
}

SGJSButton.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.object,
}

const useMenuButtonStyles = makeStyles((theme) => ({
  root: {
    border: '2px solid transparent',
    borderRadius: 60,
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    fontWeight: 100,
    fontVariant: 'normal',
    fondStyle: 'normal',
    fontSize: 17,
    '&:hover': {
      border: `2px solid ${theme.palette.common.white}`,
    },
    textTransform: 'none',
    height: 56,
  },
  startIcon: {
    height: 56,
    width: 56,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  label: {
    marginRight: '13px',
    marginLeft: '13px',
    textTransform: 'capitalize',
  },
}))

const useCloseIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: 6,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,

    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  },
}))

const MenuButtonBase = (props, ref) => {
  const { title, icon, ...rest } = props
  const classes = useMenuButtonStyles()
  return <Button ref={ref} startIcon={icon} text={title} classes={classes} {...rest} />
}

const useNavBarStyles = makeStyles((theme) => ({
  button: {
    border: '2px solid transparent',
    '&:hover': {
      border: `2px solid ${theme.palette.common.white}`,
    },
  },
}))

const NavBarButtonBase = ({ icon, ...rest }, ref) => {
  const classes = useNavBarStyles()
  return (
    <IconButton ref={ref} color='inherit' classes={{ root: classes.button }} {...rest}>
      {icon}
    </IconButton>
  )
}

const CloseIconButtonBase = (props, ref) => {
  const classes = useCloseIconButtonStyles()
  return (
    <IconButton classes={classes} ref={ref} {...props}>
      <CloseIcon style={{ fontSize: 20 }} />
    </IconButton>
  )
}

export const TabButton = (props) => {
  const classes = usePPCButtonStyles()
  const { icon, label, ...rest } = props
  const text = label?.toUpperCase()
  return (
    <Button className={classes.tabButton} startIcon={icon} text='' size='normal' variant='test' color='black' {...rest}>
      {text}
    </Button>
  )
}

TabButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
}

const NavBarButton = forwardRef(NavBarButtonBase)
const MenuButton = forwardRef(MenuButtonBase)
const CloseIconButton = forwardRef(CloseIconButtonBase)

import { NormalButton } from './PPCButtonTyped'

export { NavBarButton, MenuButton, CloseIconButton, NormalButton }
