/* Generated from Java with JSweet 3.0.0-SNAPSHOT - http://www.jsweet.org */

/**
 * Class represents a range of data with a minimum and maximum values. Model
 * used in RangeSlider The class also has dataMin, dataMax fields
 *
 * @author erdohelyi
 *
 * @param {number} min
 * @param {number} max
 * @param {number} datamin
 * @param {number} datamax
 * @param {boolean} moving
 * @class
 */
export class Range {
  /* private */ min = 0

  /* private */ max = 0

  /* private */ dataMin = 0

  /* private */ dataMax = 0

  /* private */ moving = false

  public constructor(min?: any, max?: any, datamin?: any, datamax?: any, moving?: any) {
    if (
      (typeof min === 'number' || min === null) &&
      (typeof max === 'number' || max === null) &&
      (typeof datamin === 'number' || datamin === null) &&
      (typeof datamax === 'number' || datamax === null) &&
      (typeof moving === 'boolean' || moving === null)
    ) {
      ;(() => {
        this.dataMin = datamin
        this.dataMax = datamax
        this.setMin(min)
        this.setMax(max)
        this.setMoving(moving)
      })()
    } else if (
      ((min != null && min instanceof Range) || min === null) &&
      max === undefined &&
      datamin === undefined &&
      datamax === undefined &&
      moving === undefined
    ) {
      ;(() => {
        this.set$com_dicomlab_jdent_data_Range(min)
      })()
    } else if (
      min === undefined &&
      max === undefined &&
      datamin === undefined &&
      datamax === undefined &&
      moving === undefined
    ) {
      ;(() => {
        this.dataMin = 0.0
        this.dataMax = 100.0
        this.min = 30.0
        this.max = 70.0
        this.moving = false
      })()
    } else {
      throw new Error('invalid overload')
    }
  }

  public getDataRange(): number {
    return (<any>Math).fround(this.dataMax - this.dataMin)
  }

  public getMin(): number {
    return this.min
  }

  public getMax(): number {
    return this.max
  }

  public setMin(min: number) {
    this.min = Math.min(Math.max(this.dataMin, min), this.dataMax)
    if ((<any>Math).fround(this.max - this.min) < 0) {
      this.max = (<any>Math).fround(this.min + 0)
      this.max = Math.max(Math.min(this.dataMax, this.max), this.dataMin)
    }
  }

  public setMax(max: number) {
    this.max = Math.max(Math.min(this.dataMax, max), this.dataMin)
    if ((<any>Math).fround(this.max - this.min) < 0) {
      this.min = (<any>Math).fround(this.max - 0)
      this.min = Math.min(Math.max(this.dataMin, this.min), this.dataMax)
    }
  }

  public getDataMin(): number {
    return this.dataMin
  }

  public getDataMax(): number {
    return this.dataMax
  }

  /**
   * @return {boolean} the moving
   */
  public getMoving(): boolean {
    return this.moving
  }

  /**
   * @param {boolean} moving
   * the moving to set
   */
  public setMoving(moving: boolean) {
    this.moving = moving
  }

  public set$com_dicomlab_jdent_data_Range(r: Range) {
    this.set$float$float$float$float$boolean(r.min, r.max, r.dataMin, r.dataMax, r.moving)
  }

  public setMinMax$float$float(min: number, max: number) {
    this.min = min < this.dataMin ? this.dataMin : min
    this.max = max > this.dataMax ? this.dataMax : max
  }

  /**
   * Sets only the min and the max values, dataMin and dataMax remain unchanged.
   * @param {number} min
   * @param {number} max
   */
  public setMinMax(min?: any, max?: any): any {
    if ((typeof min === 'number' || min === null) && (typeof max === 'number' || max === null)) {
      return <any>this.setMinMax$float$float(min, max)
    }
    if (((min != null && min instanceof Range) || min === null) && max === undefined) {
      return <any>this.setMinMax$com_dicomlab_jdent_data_Range(min)
    }
    throw new Error('invalid overload')
  }

  public setMinMax$com_dicomlab_jdent_data_Range(r: Range) {
    this.setMinMax$float$float(r.min, r.max)
  }

  public set$float$float$float$float$boolean(
    min: number,
    max: number,
    datamin: number,
    datamax: number,
    moving: boolean
  ) {
    this.min = min
    this.max = max
    this.dataMin = datamin
    this.dataMax = datamax
    this.moving = moving
  }

  public set(min?: any, max?: any, datamin?: any, datamax?: any, moving?: any): any {
    if (
      (typeof min === 'number' || min === null) &&
      (typeof max === 'number' || max === null) &&
      (typeof datamin === 'number' || datamin === null) &&
      (typeof datamax === 'number' || datamax === null) &&
      (typeof moving === 'boolean' || moving === null)
    ) {
      return <any>this.set$float$float$float$float$boolean(min, max, datamin, datamax, moving)
    }
    if (
      ((min != null && min instanceof Range) || min === null) &&
      max === undefined &&
      datamin === undefined &&
      datamax === undefined &&
      moving === undefined
    ) {
      return <any>this.set$com_dicomlab_jdent_data_Range(min)
    }
    throw new Error('invalid overload')
  }

  /**
   *
   * @param {*} obj
   * @return {boolean}
   */
  public equals(obj: any): boolean {
    const o: Range = <Range>obj
    return (
      this.min === o.min &&
      this.max === o.max &&
      this.dataMin === o.dataMin &&
      this.dataMax === o.dataMax &&
      this.moving === o.moving
    )
  }

  /**
   *
   * @return {string}
   */
  public toString(): string {
    return `( ${this.min} - ${this.max} ) ---- ( Data min ${this.dataMin} Data max ${this.dataMax})`
  }
}
//    Range["__class"] = "com.dicomlab.jdent.data.Range";
