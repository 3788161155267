export declare interface PPCTheme {
  ppcId: string
  palette: {
    common: {
      black: string
      white: string
    }
    primary: {
      light: string
      main: string
      dark: string
      contrastText: string
    }
    secondary: {
      light: string
      main: string
      dark: string
      contrastText: string
    }
    error: {
      main: string
      contrastText: string
    }
    success: {
      main: string
      contrastText: string
    }
    gradient: {
      GRD0: string
      GRD1: string
    }
    grey: {
      P100: string
      P200: string
      P300: string
      P400: string
      P500: string
      P600: string
      P700: string
    }
  }
}

export const getColorFromTheme = (theme: PPCTheme, color: string): string => {
  if (typeof color === 'string' && color.match(/^P[0-9]{3}$/)) {
    return (theme.palette.grey as any)[color]
  }

  switch (color) {
    case 'white':
      return theme.palette.common.white
    case 'black':
      return theme.palette.common.black
    case 'primary':
    case 'primaryMain':
      return theme.palette.primary.main
    case 'secondary':
    case 'secondaryMain':
      return theme.palette.secondary.main
    case 'success':
      return theme.palette.success.main
    case 'error':
      return theme.palette.error.main
    case 'primaryDark':
      return theme.palette.primary.dark
    case 'primaryLight':
      return theme.palette.primary.light
    case 'secondaryDark':
      return theme.palette.secondary.dark
    case 'secondaryLight':
      return theme.palette.secondary.light
    case 'primaryContrast':
      return theme.palette.primary.contrastText
    case 'secondaryContrast':
      return theme.palette.secondary.contrastText
    case 'successContrast':
      return theme.palette.success.contrastText
    case 'errorContrast':
      return theme.palette.error.contrastText
    default:
      return color
  }
}

export const addOpacityToColor = (color: string, opacity: number): string => {
  if (color.length === 4) {
    // example #fff
    return `${color[0]}${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}${opacity.toString(16)}`
  }
  return `${color}${opacity.toString(16)}`
}
