import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 15,

    '& .MuiGrid-grid-xs-12': {
      padding: '25px 50px',
    },
  },
}))

const InputGrid = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.root} {...rest}>
      {children}
    </Grid>
  )
}

InputGrid.propTypes = {
  children: PropTypes.any,
}

export default InputGrid
