/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import { TextField, TextFieldProps } from '@mui/material'
import * as validators from './utils/validators'
import { useTranslation } from 'react-i18next'
import withSideLabel from './WithSideLabel'

type ValidationResultType = { translatableText?: string; translationData?: Record<string, any> }

const INPUT_TYPE_MAP: Record<
  string,
  {
    type: string
    inputProps?: Record<string, any>
    validate?: (value: string, arg?: number | string) => ValidationResultType
  }
> = {
  email: {
    type: 'email',
    inputProps: { autoComplete: 'username' },
    validate: (value: string): ValidationResultType =>
      value && validators.verifyEmail(value) ? {} : { translatableText: 'i18n.validation.emailMustBeInThisFormat' },
  },
  noEmpty: {
    type: 'text',
    inputProps: { autoComplete: 'off' },
    validate: (value: string): ValidationResultType =>
      value && validators.verifyLength(value, 1) ? {} : { translatableText: 'i18n.validation.mustNotBeEmpty' },
  },
  lengthX: {
    type: 'text',
    inputProps: { autoComplete: 'off' },
    validate: (value: string, length: number | string | undefined): ValidationResultType =>
      value && typeof length === 'number' && validators.verifyLength(value, length)
        ? {}
        : { translatableText: 'i18n.validation.mustBeAtLeastXLong', translationData: { length } },
  },
  length3: {
    type: 'text',
    inputProps: { autoComplete: 'off' },
    validate: (value: string): ValidationResultType =>
      value && validators.verifyLength(value, 3)
        ? {}
        : { translatableText: 'i18n.validation.mustBeAtLeastXLong', translationData: { length: 3 } },
  },
  newpassword: {
    type: 'password',
    // inputProps: {autoComplete: 'new-password'},
    validate: (value: string): ValidationResultType =>
      value && validators.verifyPassword(value)
        ? {}
        : { translatableText: 'i18n.validation.mustBeAtLeastXLongAndContainAZaz09', translationData: { length: 8 } },
  },
  password: {
    type: 'password',
    inputProps: { autoComplete: 'current-password' },
    validate: (value: string): ValidationResultType =>
      value && validators.verifyPassword(value)
        ? {}
        : { translatableText: 'i18n.validation.mustBeAtLeastXLong', translationData: { length: 8 } },
  },
  equal: {
    type: 'password',
    inputProps: { autoComplete: 'off' },
    validate: (value: string, to: string | number | undefined): ValidationResultType =>
      value === to ? {} : { translatableText: 'i18n.validation.mustMatchWithTheFirstPassword' },
  },
  vat: {
    type: 'text',
    validate: (value: string): ValidationResultType =>
      value && validators.verifyVatNumber(value)
        ? {}
        : { translatableText: 'i18n.validation.mustBeInEUVatNumberFormat' },
  },
  number: {
    type: 'number',
    validate: (value: string): ValidationResultType =>
      value && validators.verifyNumber(value) ? {} : { translatableText: 'i18n.validation.mustBeANumber' },
  },
  url: {
    type: 'text',
    inputProps: { autoComplete: 'off' },
    validate: (value: string): ValidationResultType =>
      value && validators.verifyUrl(value) ? {} : { translatableText: 'i18n.validation.mustBeAValidUrl' },
  },
  date: {
    type: 'date',
    inputProps: { autoComplete: 'on', min: '1900-12-31', max: '3000-12-31' },
    validate: (value: string): ValidationResultType =>
      value && validators.verifyLength(value, 1) ? {} : { translatableText: 'i18n.validation.mustBeAValidDate' },
  },
  phone: {
    type: 'tel',
    inputProps: { autoComplete: 'tel' },
    validate: (value: string): ValidationResultType =>
      value && validators.verifyPhone(value) ? {} : { translatableText: 'i18n.validation.mustBeAValidPhoneNumber' },
  },
  otc: {
    type: 'text',
    inputProps: { autoComplete: 'one-time-code' },
    validate: (value: string): ValidationResultType =>
      value && validators.verifyOTC(value) ? {} : { translatableText: 'i18n.validation.mustBeValidOtc' },
  },
  cvv: {
    type: 'cvv',
    inputProps: { autoComplete: 'off' },
  },
  code: {
    type: 'text',
    inputProps: { autoComplete: 'off' },
    validate: (value: string): ValidationResultType =>
      validators.verifyCode(value) ? {} : { translatableText: 'i18n.validation.mustBeValidCode' },
  },
}

const getTypeAndValidator = (props: any) => {
  const checkedProps = Object.keys(INPUT_TYPE_MAP)
  const match = Object.entries(props).find(([key, isTrue]) => checkedProps.includes(key) && isTrue)

  const typeId = match && match[0]
  return typeId && INPUT_TYPE_MAP[typeId] ? INPUT_TYPE_MAP[typeId] : { type: 'text' }
}

type TextInputProps = Omit<TextFieldProps, 'onChange'> & {
  value: string
  disabled?: boolean
  session?: any
  directValue?: boolean
  onChange: (text: string) => void | ((directValue: any) => void)
  onBlur?: (text: string) => void
  validator?: (text: string) => string[]
  compact?: boolean
  optional?: boolean
  label?: string
  icon?: React.ElementType
  validationArgument?: string | number
  noValidate?: boolean
}

const TextInput: React.FC<TextInputProps> = ({
  value = '',
  directValue,
  onChange,
  onBlur,
  disabled,
  label,
  validationArgument,
  optional,
  noValidate,
  session,
  ...rest
}) => {
  const [touched, setTouched] = useState(false)
  const [focued, setFocused] = useState(false)
  const [currentSession, setCurrentSession] = useState(null)

  useEffect(() => {
    if (session !== undefined && !['', currentSession].includes(session)) {
      setCurrentSession(session)
      setTouched(false)
      setFocused(false)
      if (value !== '') {
        onChange('')
      }
    }
  }, [session, currentSession, onChange, value])

  const { type: inputType, validate, inputProps = { autoComplete: 'off' } } = getTypeAndValidator(rest)

  const { t } = useTranslation()

  const validationResult: ValidationResultType = validate ? validate(value, validationArgument) : {}

  const validatorErrorMessage =
    !noValidate && !focued && touched && validate && (!optional || value !== '') && validationResult.translatableText
      ? t(validationResult.translatableText, validationResult.translationData)
      : ''

  const omitKeys = Object.keys(INPUT_TYPE_MAP)
  const fwProps = Object.entries(rest).reduce((acc, [k, v]) => (omitKeys.includes(k) ? acc : { ...acc, [k]: v }), {})

  return (
    <TextField
      type={inputType}
      inputProps={inputProps}
      value={value}
      disabled={disabled}
      onChange={(e) => onChange && onChange(directValue ? (e as any) : e.target.value)}
      label={label}
      error={!disabled && Boolean(validatorErrorMessage)}
      helperText={validatorErrorMessage}
      // placeholder
      // multiline
      // maxRows
      onFocus={() => setFocused(true)}
      onBlur={(e) => {
        setFocused(false)
        setTouched(true)
        if (onBlur) {
          onBlur(e.target.value)
        }
      }}
      {...rest}
      {...fwProps}
    />
  )
}

const TextInputWithSideLabel = withSideLabel(TextInput)

export { TextInputWithSideLabel, TextInput }
export default TextInputWithSideLabel
