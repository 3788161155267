import styled from 'styled-components'
import { Box } from '@mui/material'

const CircleBracket = styled(Box)`
  width: 150px;
  height: 150px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default CircleBracket
