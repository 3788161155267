import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'block',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    lineHeight: '36px',
    letterSpacing: 0,
    padding: 0,
    marginBottom: 20,
    '& TableHead': {
      height: '10px',
    },
  },

  table: {
    display: 'table',
    width: '100%',
    backgroundColor: ({ color }) => theme.palette[color || 'primary'].light,
  },

  cellKey: {
    fontWeight: 'medium',
    lineHeight: '16px',
    backgroundColor: ({ color }) => theme.palette[color || 'primary'].main,
    color: theme.palette.common.white,
    borderBottom: '0px solid black',
  },

  cellValue: {
    width: 'auto',
    fontWeight: 'medium',
    lineHeight: '16px',
    fontSize: '16px',
    color: ({ color }) => theme.palette[color || 'primary'].dark,
    backgroundColor: ({ color }) => theme.palette[color || 'primary'].light,

    backgroundImage: ({ color }) =>
      `linear-gradient(to right, ${theme.palette[color || 'primary'].main}59 45%, ${
        theme.palette[color || 'primary'].light
      } 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x',

    borderBottom: '0px !important',
    borderTop: '0px !important',
  },

  bodyRow: {
    height: 33,
  },
}))

const DataDisplay = ({ data, keyWidth = '33%', classes, color, ...rest }) => {
  const baseClasses = useStyles({ color })

  return (
    <TableContainer className={baseClasses.root}>
      <Table {...rest} classes={{ ...classes }} className={baseClasses.table} size='small'>
        <TableBody className={baseClasses.body}>
          {data &&
            Object.entries(data).map(([key, value]) => (
              <TableRow
                key={key}
                className={baseClasses.bodyRow}
                {...(Object.entries(data).indexOf(key) === 0 ? { component: 'tr', scope: 'row' } : {})}
              >
                <TableCell width={keyWidth} className={baseClasses.cellKey}>
                  {key}
                </TableCell>
                <TableCell className={baseClasses.cellValue}>
                  {typeof value === 'object' ? value : String(value)}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DataDisplay.propTypes = {
  data: PropTypes.object,
  keyWidth: PropTypes.string,
  classes: PropTypes.object,
  depth: PropTypes.number,
  color: PropTypes.string,
}

export default DataDisplay
