import { Switch as Base, SwitchProps as BaseProps, FormControlLabel, FormControlLabelProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import withSideLabel from './WithSideLabel'
import React from 'react'

interface CustomFormControlLabelProps extends FormControlLabelProps {
  color: 'primary' | 'secondary' | 'default'
}

export interface SwitchProps extends Omit<BaseProps, 'onChange'> {
  onChange: (checked: boolean) => void
  label: string
  value: boolean
  FormControlProps?: CustomFormControlLabelProps
}

const Switch: React.FC<SwitchProps> = ({ onChange, label, value, FormControlProps, ...rest }) => {
  const { color, ...formControlLabelProps } = FormControlProps || ({ color: 'default' } as CustomFormControlLabelProps)
  const { t } = useTranslation()
  return (
    <FormControlLabel
      {...formControlLabelProps}
      label={t(label)}
      checked={value}
      onChange={(e) => onChange && onChange((e as any).target.checked)}
      control={<Base color={color || 'primary'} {...rest} />}
    />
  )
}

const SwitchWithSideLabel = withSideLabel<SwitchProps>(Switch)

export default Switch
export { SwitchWithSideLabel }
