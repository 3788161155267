import * as M from 'models'

export interface IVolumePanelAction {
  setViewDirection(direction: M.ViewDirection): any
}

export const impl: IVolumePanelAction = {
  setViewDirection:
    (direction: M.ViewDirection): any =>
    (dispatch: any) => {
      dispatch({ type: M.VOLUMEPANEL_SETVIEWDIRECTION, data: { direction, disableUndo: true } })
    },
}
