import { CompleteGeometry } from 'models/case/CompleteGeometry'
import * as A from 'models/case/Actions'

import * as THREE from 'three'

export interface IAssetsAction {
  addCustom(index: string, content: any): any
  addGeometry(index: string, geometry: THREE.BufferGeometry): any
  addGeometries(index: string, geometries: CompleteGeometry): any
  addMaterial(index: string, material: THREE.Material): any
}

export const impl: IAssetsAction = {
  addCustom:
    (index: string, content: any): any =>
    (dispatch: any) => {
      dispatch({ type: A.ASSET_ADD_CUSTOM, data: { index, content, disableUndo: true } })
    },
  addGeometry:
    (index: string, geometry: THREE.BufferGeometry): any =>
    (dispatch: any) => {
      dispatch({ type: A.ASSET_ADD_GEOMETRY, data: { index, geometry, disableUndo: true } })
    },
  addGeometries:
    (index: string, geometry: CompleteGeometry): any =>
    (dispatch: any) => {
      dispatch({ type: A.ASSET_ADD_GEOMETRIES, data: { index, geometry, disableUndo: true } })
    },
  addMaterial:
    (index: string, material: THREE.Material): any =>
    (dispatch: any) => {
      dispatch({ type: A.ASSET_ADD_MATERIAL, data: { index, material, disableUndo: true } })
    },
}
