import PropTypes from 'prop-types'

import { Switch as Base, FormControlLabel } from '@mui/material'
import withSideLabel from './WithSideLabel'

const SwitchValue = ({ value, items, onChange, FormControlProps, onBlur, ...rest }) => {
  const { color, ...formControlLabelProps } = FormControlProps || {}
  return (
    <FormControlLabel
      checked={items[0].id === value}
      {...formControlLabelProps}
      onChange={(e) => onChange && onChange(e.target.checked ? items[0].id : items[1].id)}
      control={<Base color={color || 'primary'} {...rest} />}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e.target.checked ? items[0].id : items[1].id)
        }
      }}
    />
  )
}

SwitchValue.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  items: PropTypes.array.isRequired,
  FormControlProps: PropTypes.object,
}

const SwitchValueWithSideLabel = withSideLabel(SwitchValue)

export { SwitchValueWithSideLabel }
export default SwitchValue
