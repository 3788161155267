import { InternalWindowMessage } from 'models'
import { internalWindowMessageActions } from '../actions/InternalWindowMessageActions'

const initialState: InternalWindowMessage = {
  msg: '',
  req: new Uint8Array(),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case internalWindowMessageActions.SEND_MESSAGE:
      return action.msg
    default:
      return state
  }
}
