export const SLICE_MOVE = 'SLICE_MOVE'
export const SLICE_UNLOCK = 'SLICE_UNLOCK'
export const SLICE_UPDATE_VISUAL_CENTER = 'SLICE_UPDATE_VISUAL_CENTER'
export const SLICE_CONFIGURE_GRAYSCALE_COLORS = 'SLICE_CONFIGURE_GRAYSCALE_COLORS'

export const SLICE_SET_MEASURE = 'SLICE_SET_MEASURE'

export const CASE_CLEAR = 'CASE_CLEAR'
export const CASE_ROLE_CHANGED = 'CASE_ROLE_CHANGED'
export const CASE_CLEAR_VOLUME = 'CASE_CLEAR_VOLUME'
export const CASE_LOAD_BASIC = 'CASE_LOAD_BASIC'
export const CASE_LOAD_VIEW_BASIC = 'CASE_LOAD_VIEW_BASIC'
export const CASE_LOAD_VOLUME = 'CASE_LOAD_VOLUME'
export const TRAY_CONF_VENDOR_FILTER = 'TRAY_CONF_VENDOR_FILTER'
export const TRAY_CONF_TRAY_SELECTED = 'TRAY_CONF_TRAY_SELECTED'
export const TRAY_CONF_ADD_TRAY = 'TRAY_CONF_ADD_TRAY'
export const TRAY_CONF_DEL_TRAY = 'TRAY_CONF_DEL_TRAY'

export const OBJ_IMPLANT_ROTATE_WITH_SLICE = 'OBJ_IMPLANT_ROTATE_WITH_SLICE'
export const OBJ_SETOBJECTGROUPVISIBLE = 'OBJ_SETOBJECTGROUPVISIBLE'
export const OBJ_SETOBJECTVISIBLE = 'OBJ_SETOBJECTVISIBLE'
export const OBJ_SETOBJECTCOLLISIONSTATE = 'OBJ_SETOBJECTCOLLISIONSTATE'
export const OBJ_SETOBJECTSLEEVECOLLISIONSTATE = 'OBJ_SETOBJECTSLEEVECOLLISIONSTATE'
export const OBJ_SETISO = 'OBJ_SETISO'
export const OBJ_HOOVER = 'OBJ_HOOVER'
export const OBJ_REMOVETOOTH = 'OBJ_REMOVETOOTH'
export const OBJ_REMOVECUSTOM = 'OBJ_REMOVECUSTOM'
export const OBJ_SETMESH = 'OBJ_SETMESH'
export const OBJ_SETTOOTHMESH = 'OBJ_SETTOOTHMESH'
export const OBJ_ADDTOOTH = 'OBJ_ADDTOOTH'
export const OBJ_ADDCUSTOM = 'OBJ_ADDCUSTOM'
export const OBJ_IMPLANT_SET_TYPE = 'OBJ_IMPLANT_SET_TYPE'
export const OBJ_FIXATOR_SET_TYPE = 'OBJ_FIXATOR_SET_TYPE'
export const OBJ_OBJECT_ADD = 'OBJ_OBJECT_ADD'
export const OBJ_OBJECT_DEL = 'OBJ_OBJECT_DEL'
// export const OBJ_IMPLANT_UPDATE = 'OBJ_IMPLANT_UPDATE';
export const CASE_LOAD_STARTED = 'CASE_LOAD_STARTED'
export const CASE_SET_LOAD_PROGRESS = 'CASE_SET_LOAD_PROGRESS'
export const ASSET_ADD_CUSTOM = 'ASSET_ADD_CUSTOM'
export const ASSET_ADD_GEOMETRY = 'ASSET_ADD_GEOMETRY'
export const ASSET_ADD_GEOMETRIES = 'ASSET_ADD_GEOMETRIES'
export const ASSET_ADD_MATERIAL = 'ASSET_ADD_MATERIAL'
//export const EDITOR_SETMEASURE = 'EDITOR_SETMEASURE'
export const EDITOR_SETPANELOPEN = 'EDITOR_SETPANELOPEN'
export const EDITOR_SETLAYOUT = 'EDITOR_SETLAYOUT'
export const EDITOR_SETPANORAMAGRAYSCALE = 'EDITOR_SETPANORAMAGRAYSCALE'
export const EDITOR_SETPANORAMAZOOM = 'EDITOR_SETPANORAMAZOOM'
export const EDITOR_SETPROGRESSSTATE = 'EDITOR_SETPROGRESSSTATE'
export const VOLUMEPANEL_SETVIEWDIRECTION = 'VOLUMEPANEL_SETVIEWDIRECTION'
export const TRAYCONFIG_OPEN = 'TRAYCONFIG_OPEN'
export const SET_PREVIEW = 'SET_PREVIEW'
export const SET_DEFAULTIMPLANT = 'SET_DEFAULTIMPLANT'
