import styled, { css } from 'styled-components'
import { Box } from '@mui/material'
import { PAGE_MIN_WIDTH, PAGE_MAX_WIDTH, PAGE_ADMIN_MIN_WIDTH, PAGE_ADMIN_MAX_WIDTH } from './constants'

const PageWrap = styled(Box)`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;

  ${(props) => {
    if (props.isAdmin) {
      return css`
        min-width: ${PAGE_ADMIN_MIN_WIDTH}px;
        max-width: ${PAGE_ADMIN_MAX_WIDTH}px;
      `
    }
    return css`
      min-width: ${PAGE_MIN_WIDTH}px;
      max-width: ${PAGE_MAX_WIDTH}px;
    `
  }}
`

export default PageWrap
