import React from 'react'
import Button, { ButtonProps } from './Button'
import { usePPCButtonStyles } from './PPCButton'

declare interface AdditionalNormalButtonProps {
  label?: string
  nextIcon?: React.ReactNode
  prevIcon?: React.ReactNode
  secondary?: boolean
  back?: boolean
  alert?: boolean
  blend?: boolean
  className?: string
}

export declare type NormalButtonProps = AdditionalNormalButtonProps & ButtonProps

export const NormalButton: React.FC<NormalButtonProps> = (props: NormalButtonProps) => {
  const { label, nextIcon, prevIcon, secondary, back, alert, blend, className, ...rest } = props
  const classes = usePPCButtonStyles({ blend })
  let selectedClassName = secondary ? classes.normalSecondaryButton : classes.normalPrimaryButton
  if (alert) {
    selectedClassName = secondary ? classes.normalSecondaryAlertButton : classes.normalPrimaryAlertButton
  }
  if (back) {
    selectedClassName = secondary ? classes.normalSecondaryBackButton : classes.normalPrimaryBackButton
  }
  return (
    <Button
      className={className ? className : selectedClassName}
      size='small'
      text={label}
      variant={secondary ? 'outlined' : 'contained'}
      startIcon={prevIcon}
      endIcon={nextIcon}
      {...rest}
    />
  )
}
