export const themingActions = {
  SET_TENANT_THEMES: 'SET_TENANT_THEME',
  SET_THEME: 'CHANGE_THEME',
}

export interface IThemingActions {
  setTenantThemes(themes: any[])
  setTheme(theme: any)
}

export const impl: IThemingActions = {
  setTenantThemes:
    (themes: any[]): any =>
    (dispatch: any) => {
      dispatch({
        type: themingActions.SET_TENANT_THEMES,
        themes,
        data: { disableUndo: true },
      })
    },
  setTheme:
    (theme: any[]): any =>
    (dispatch: any) => {
      dispatch({
        type: themingActions.SET_THEME,
        theme,
      })
    },
}
