// import "./home.scss";
import React from 'react'
import * as M from 'models'
import { connect } from 'react-redux'
import ContainerDimensions from 'react-container-dimensions'
import { withStyles } from '@mui/styles'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Viewer } from './Viewer'
import { Box } from '@mui/material'
import DCLDAppBar from './UI/AppBar/AppBar'

interface IMainComponentProps {
  loading?: M.Loading
  editor?: M.EditorConfig
  zoom?: M.Zoom
  viewerSession?: string
}
let webglDetection
class MainComponent extends React.Component<IMainComponentProps & WithTranslation, Record<string, unknown>> {
  constructor(props: IMainComponentProps & WithTranslation) {
    super(props)
    this.state = {}
    //    logger.setCaseId(this.props.caseid);
    // logger.info({ loadingState: this.props.loading.state }, 'Main component constructed.')
    // console.log('main up')
  }

  render(): React.ReactNode {
    const GlobalCss = withStyles({
      // @global is handled by jss-plugin-global.
      '@global': {
        // You should target [class*="MuiButton-root"] instead if you nest themes.
        '.MuiFab-root.Mui-disabled': {
          color: 'rgba(0, 0, 0, 2.2)  !important',
          boxShadow: 'none',
          backgroundColor: 'rgba(143, 143, 143, 0.7) !important',
          border: '1px solid rgba(200,200,200,0.4)  !important',
        },
        '.MuiDialog-paperWidthSm': {
          maxWidth: '950px',
          width: '60%',
        },
        '.MuiDialog-paperFullScreen': {
          width: '100% !important',
        },
      },
    })(() => null)

    if (!this.props.tReady) {
      return <div style={{ width: '100vw', height: '100vh' }}></div>
    }
    if (webglDetection === undefined) {
      const testCanvas = document.createElement('canvas') as HTMLCanvasElement
      const gl = testCanvas.getContext('webgl2')
      webglDetection = !!gl
      window['webglDetection'] = webglDetection
      testCanvas.remove()
    }
    return this.props.viewerSession ? (
      <div style={{ width: '100vw', height: '100vh', overflow: 'hidden', overflowX: 'hidden', overflowY: 'hidden' }}>
        <GlobalCss />
        <ContainerDimensions>
          {({ width, height }) => {
            return <Viewer dim={{ width, height }} />
          }}
        </ContainerDimensions>
      </div>
    ) : (
      <Box display='flex' flexDirection='row' style={{ width: '100%', height: '100%' }}>
        <div style={{ width: '100vw', height: '100vh', overflow: 'hidden', overflowX: 'hidden', overflowY: 'hidden' }}>
          <GlobalCss />
          <ContainerDimensions>
            {({ width, height }) => {
              return <Viewer dim={{ width, height }} />
            }}
          </ContainerDimensions>
        </div>
        <DCLDAppBar />
      </Box>
    )
  }
}

const mapStateToProps = (state: M.AppState): IMainComponentProps => {
  return {
    zoom: state.zoom,
  }
}

const Main = connect(mapStateToProps)(withTranslation()(MainComponent))
export default Main
