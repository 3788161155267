/* Generated from Java with JSweet 3.0.0-SNAPSHOT - http://www.jsweet.org */
import { Vector3 as Vector3f, Box3 as BoundingBox, Matrix4 as Matrix4f } from 'three'
import { Range } from 'utils/Range'
import * as THREE from 'three'
import { convertToNumber, roundToFloat16Bits } from 'utils/HalfFloat'

export class DicomVolume {
  public static BRICK_LENGTH = 12

  volumeTexture?: THREE.DataTexture3D

  volumeDirTexture: THREE.DataTexture3D[] = []

  volumeDirScale: number | undefined

  brickArray?: Uint16Array
  // set in case of v2
  // brickVolumeVertices: Float32Array;
  // brickVolumeIndices: Uint32Array;

  dataInFloat16?: boolean
  rawF16 = false
  /* private */ data?: Uint16Array

  public directionData: Uint8Array[] = []

  /* private */ columns?: number[]

  /* private */ width = 0

  /* private */ height = 0

  /* private */ imageCount = 0

  /* private */ voxelSize: Vector3f = new Vector3f(1, 1, 1)

  /* private */ pixelSpacing: Vector3f = new Vector3f(1, 1, 1)

  public size: Vector3f = new Vector3f(1, 1, 1)

  /* private */ center: Vector3f = new Vector3f(0, 0, 0)

  public templateThreshold = 1000

  public tMarkerThreshold = 2000

  public pMarkerThreshold = 2500

  /* private */ boundingBox?: BoundingBox

  /* private */ orientation?: number[]

  /* private */ reverse = false

  /* private */ visible = true

  /* private */ range: Range = new Range(0, 4000, -1000, 5000, false)

  /* private */ bBLower: Vector3f = new Vector3f()

  /* private */ bBUpper: Vector3f = new Vector3f()

  /* private */ transform: Matrix4f = new Matrix4f()

  // ez a [0..1][0..1][0..1] -t kepzi le a world coord-ra
  volumeSpaceToWorld: Matrix4f = new Matrix4f()

  // ez ugyanennek az inverse
  worldToVolumeSpace: Matrix4f = new Matrix4f()

  /* private */ diameter = 0

  /* private */ birth = ''

  /* private */ gender = ''

  /* private */ patientName = ''

  /* private */ textureIDs: number[] = [-1, -1, -1]

  /* private */ toIndexTransformMatrix: Matrix4f = new Matrix4f()

  // /*private*/ toIndexTransform_array : number[];

  /* private */ toIndexInvTransformMatrix: Matrix4f = new Matrix4f()

  // /*private*/ toIndexInvTransform_array : number[];

  /* private */ brickMinMax: number[][][][] = [[[[]]]]

  /* private */ hBCount = 0

  /* private */ wBCount = 0

  /* private */ dBCount = 0
  public floatBase?: number
  public targetBase?: number
  public floatDiv?: number
  public compressionMethod?: number
  public cubeMesh = false
  public initGL(): void {
    let accelTextureSize: THREE.Vector3
    if (this.volumeDirScale) {
      const roundUp = (x, y): number => {
        return Math.trunc((x + y - 1) / y) * y
      }
      accelTextureSize = new THREE.Vector3(
        roundUp(this.width, this.volumeDirScale) / this.volumeDirScale,
        roundUp(this.height, this.volumeDirScale) / this.volumeDirScale,
        roundUp(this.imageCount, this.volumeDirScale) / this.volumeDirScale
      )
    } else {
      accelTextureSize = new THREE.Vector3(this.width, this.height, this.imageCount)
    }

    for (let i = 0; i < this.directionData.length; i++) {
      const vdt = new THREE.DataTexture3D(
        this.directionData[i],
        accelTextureSize.x,
        accelTextureSize.y,
        accelTextureSize.z
      )
      vdt.format = THREE.RedFormat
      vdt.type = THREE.UnsignedByteType
      vdt.wrapS = THREE.ClampToEdgeWrapping
      vdt.wrapT = THREE.ClampToEdgeWrapping
      vdt.unpackAlignment = 1
      vdt.magFilter = THREE.NearestFilter
      vdt.minFilter = THREE.NearestFilter
      this.volumeDirTexture.push(vdt)
    }
    this.compressionMethod = 0
    let newArray
    if (this.data) {
      if (this.dataInFloat16) {
        if (!this.rawF16 && this.data[0] === 0x4344 && this.data[1] === 0x444c) {
          this.floatBase = this.data[2]
          this.targetBase = this.data[3]
          this.floatDiv = this.data[4]
          this.compressionMethod = 1
          newArray = new Uint16Array(this.data.buffer, 10)
          this.data = undefined
        } else {
          if (this.rawF16) {
            this.compressionMethod = 1
          }
          newArray = this.data
          // this.data = undefined
        }
      } else {
        newArray = new Uint16Array(this.data.length)

        for (let k = 0; k < this.data.length; ++k) {
          //realIsoLevel = (realIsoLevel * 65536.0- uTargetBase)/uFloatDiv + uFloatBase ;
          newArray[k] = roundToFloat16Bits((this.data[k] + 32768) / 65536.0)
        }
        this.floatBase = 0
        this.targetBase = 0
        this.floatDiv = 1
        this.compressionMethod = 0
        this.data = undefined
      }
    }

    this.volumeTexture = new THREE.DataTexture3D(newArray, this.width, this.height, this.imageCount)
    this.volumeTexture.format = THREE.RedFormat
    this.volumeTexture.type = THREE.HalfFloatType
    this.volumeTexture.unpackAlignment = 1
    this.volumeTexture.magFilter = THREE.LinearFilter
    this.volumeTexture.minFilter = THREE.LinearFilter //THREE.LinearMipMapNearestFilter
  }

  public getToIndexTransform(): Matrix4f {
    return this.toIndexTransformMatrix
  }

  public getToIndexInvTransform(): Matrix4f {
    return this.toIndexInvTransformMatrix
  }

  public getTextureID(index: number): number {
    return this.textureIDs[index]
  }

  public getRange(): Range {
    return this.range
  }

  public setRange(r: Range): void {
    this.range = r
    // this.firePropertyChanged$java_lang_String$java_lang_Object$java_lang_Object(com.dicomlab.jdent.Msg.dicommin, null, null);
  }

  public getMin(): number {
    return (<number>this.range.getMin()) | 0
  }

  public setMin(min: number): void {
    this.range.setMin(min)
    // this.firePropertyChanged$java_lang_String$java_lang_Object$java_lang_Object(com.dicomlab.jdent.Msg.dicommin, null, null);
  }

  public getMax(): number {
    return (<number>this.range.getMax()) | 0
  }

  public setMax(max: number): void {
    this.range.setMax(max)
    // this.firePropertyChanged$java_lang_String$java_lang_Object$java_lang_Object(com.dicomlab.jdent.Msg.dicommax, null, null);
  }

  public getVoxelSize(): Vector3f {
    return this.voxelSize
  }

  public setVoxelSize(voxelSize: Vector3f): void {
    this.voxelSize = voxelSize
    // this.firePropertyChanged$java_beans_PropertyChangeEvent(com.dicomlab.jdent.Msg.voxelsize, null, voxelSize);
  }
  /*
              computeVWorldConnections(images : Array<com.dicomlab.dicom.DicomImage>) {
                  let X : Vector3f = ((str, index, len) => str.substring(index, index + len))((this.orientation[0]).join(''), this.orientation[1], this.orientation[2]);
                  let Y : Vector3f = ((str, index, len) => str.substring(index, index + len))((this.orientation[3]).join(''), this.orientation[4], this.orientation[5]);
                  let S : Vector3f = ((str, index, len) => str.substring(index, index + len))((this.pixelSpacing.x).join(''), this.pixelSpacing.y, this.pixelSpacing.z);
                  let T1 : Point3f = ;
                  let TN : Point3f = ;
                  this.bBLower.x = 3.4028235E38;
                  this.bBLower.y = 3.4028235E38;
                  this.bBLower.z = 3.4028235E38;
                  this.bBUpper.x = -Infinity;
                  this.bBUpper.y = -Infinity;
                  this.bBUpper.z = -Infinity;
                  for(let index : number = 0; index < (<number>images.length); index++) {{
                      let p : Point3f = ((str, index, len) => str.substring(index, index + len))((0).join(''), 0, index);
                      this.bBLower.x = Math.min(this.bBLower.x, p.x);
                      this.bBLower.y = Math.min(this.bBLower.y, p.y);
                      this.bBLower.z = Math.min(this.bBLower.z, p.z);
                      this.bBUpper.x = Math.max(this.bBUpper.x, p.x);
                      this.bBUpper.y = Math.max(this.bBUpper.y, p.y);
                      this.bBUpper.z = Math.max(this.bBUpper.z, p.z);
                      this.bBLower.x = Math.min(this.bBLower.x, p.x);
                      this.bBLower.y = Math.min(this.bBLower.y, p.y);
                      this.bBLower.z = Math.min(this.bBLower.z, p.z);
                      this.bBUpper.x = Math.max(this.bBUpper.x, p.x);
                      this.bBUpper.y = Math.max(this.bBUpper.y, p.y);
                      this.bBUpper.z = Math.max(this.bBUpper.z, p.z);
                  };}
                  let v : Vector3f = ;
                  this.diameter = ;
                  let p0 : Point3f = ((str, index, len) => str.substring(index, index + len))((0).join(''), 0, 0);
                  let p1 : Point3f = ;
                  this.voxelSize.x = ;
                  this.voxelSize.y = ;
                  this.voxelSize.z = ;
                  this.boundingBox = new BoundingBox(this.bBLower, this.bBUpper);
              }
           */

  public resetOffsets(): void {
    // this.levels = (s => { let a = []; while (s-- > 0) a.push(0); return a; })(this.imageCount);
    // for (let i: number = 0; i < this.levels.length; i++) { this.levels[i] = i * (this.width * this.height); }
    this.columns = (() => {
      const a: number[] = []
      let s = this.height
      while (s-- > 0) {
        a.push(0)
      }
      return a
    })()
    for (let i = 0; i < this.columns.length; i++) {
      this.columns[i] = i * this.width
    }
  }

  public resetData(): void {
    this.data = new Uint16Array(this.imageCount * this.height * this.width)
    this.resetOffsets()
  }

  /**
   * Ensure values in "min max brick table" are in between data value range.
   */
  public clampBricks(): void {
    const dataMin: number = (<number>this.range.getDataMin()) | 0
    const dataMax: number = (<number>this.range.getDataMax()) | 0
    for (let i = 0; i < this.dBCount; i++) {
      {
        for (let j = 0; j < this.hBCount; j++) {
          {
            for (let k = 0; k < this.wBCount; k++) {
              {
                let min: number = this.brickMinMax[i][j][k][0]
                let max: number = this.brickMinMax[i][j][k][1]
                min = min < dataMin ? dataMin : min > dataMax ? dataMax : min
                max = max > dataMax ? dataMax : max < dataMin ? dataMin : max
                this.brickMinMax[i][j][k][0] = min
                this.brickMinMax[i][j][k][1] = max
              }
            }
          }
        }
      }
    }
  }

  /**
   * Create and calculate "min max bricks table"
   */
  public setBricks(): void {
    this.wBCount = (<number>Math.ceil((<any>Math).fround(<number>this.width / DicomVolume.BRICK_LENGTH))) | 0
    this.hBCount = (<number>Math.ceil((<any>Math).fround(<number>this.height / DicomVolume.BRICK_LENGTH))) | 0
    this.dBCount = (<number>Math.ceil((<any>Math).fround(<number>this.imageCount / DicomVolume.BRICK_LENGTH))) | 0
    this.brickMinMax = <any>(function (dims: number[]) {
      const allocate = function (dims: number[]) {
        if (dims.length === 0) {
          return 0
        }
        const array: any[] = []
        for (let i = 0; i < dims[0]; i++) {
          array.push(allocate(dims.slice(1)))
        }
        return array
      }
      return allocate(dims)
    })([this.dBCount, this.hBCount, this.wBCount, 2])
    for (let i = 0; i < this.dBCount; i++) {
      {
        for (let j = 0; j < this.hBCount; j++) {
          {
            for (let k = 0; k < this.wBCount; k++) {
              {
                this.getBrickMinMax$int$int$int(i, j, k)
              }
            }
          }
        }
      }
    }
  }

  public getBrickMinMax$int$int$int(d: number, h: number, w: number) {
    let dS: number = d * DicomVolume.BRICK_LENGTH
    let hS: number = h * DicomVolume.BRICK_LENGTH
    let wS: number = w * DicomVolume.BRICK_LENGTH
    dS = dS === 0 ? 0 : dS - 1
    hS = hS === 0 ? 0 : hS - 1
    wS = wS === 0 ? 0 : wS - 1
    let dE: number = (d + 1) * DicomVolume.BRICK_LENGTH
    dE = dE >= this.imageCount ? this.imageCount : dE + 1
    let hE: number = (h + 1) * DicomVolume.BRICK_LENGTH
    hE = hE >= this.height ? this.height : hE + 1
    let wE: number = (w + 1) * DicomVolume.BRICK_LENGTH
    wE = wE >= this.width ? this.width : wE + 1
    let min = 32767
    let max = -32768
    const layerSize = this.width * this.height
    if (this.data && this.columns) {
      for (let i: number = dS; i < dE; i++) {
        {
          for (let j: number = hS; j < hE; j++) {
            {
              for (let k: number = wS; k < wE; k++) {
                {
                  const s: number = this.data[i * layerSize + this.columns[j] + k]
                  min = min >= s ? s : min
                  max = max <= s ? s : max
                }
              }
            }
          }
        }
      }
    }
    this.brickMinMax[d][h][w][0] = min
    this.brickMinMax[d][h][w][1] = max
  }

  public getBrickMinMaxfp16(d: number, h: number, w: number) {
    let dS: number = d * DicomVolume.BRICK_LENGTH
    let hS: number = h * DicomVolume.BRICK_LENGTH
    let wS: number = w * DicomVolume.BRICK_LENGTH
    dS = dS === 0 ? 0 : dS - 1
    hS = hS === 0 ? 0 : hS - 1
    wS = wS === 0 ? 0 : wS - 1
    let dE: number = (d + 1) * DicomVolume.BRICK_LENGTH
    dE = dE >= this.imageCount ? this.imageCount : dE + 1
    let hE: number = (h + 1) * DicomVolume.BRICK_LENGTH
    hE = hE >= this.height ? this.height : hE + 1
    let wE: number = (w + 1) * DicomVolume.BRICK_LENGTH
    wE = wE >= this.width ? this.width : wE + 1
    let min = 32767
    let max = -32768
    const layerSize = this.width * this.height
    if (this.data && this.columns) {
      for (let i: number = dS; i < dE; i++) {
        {
          for (let j: number = hS; j < hE; j++) {
            {
              for (let k: number = wS; k < wE; k++) {
                {
                  let s: number = this.data[i * layerSize + this.columns[j] + k]
                  s = convertToNumber(s)
                  min = min >= s ? s : min
                  max = max <= s ? s : max
                }
              }
            }
          }
        }
      }
    }
    this.brickMinMax[d][h][w][0] = min
    this.brickMinMax[d][h][w][1] = max
  }

  /**
   * Calculate give brick minimum and maximum values
   *
   * @param {number} d
   * - Depth position of brick
   * @param {number} h
   * - Height position of brick
   * @param {number} w
   * - Width position of brick
   * @private
   */
  public getBrickMinMax(d?: any, h?: any, w?: any): any {
    if (
      (typeof d === 'number' || d === null) &&
      (typeof h === 'number' || h === null) &&
      (typeof w === 'number' || w === null)
    ) {
      return <any>this.getBrickMinMax$int$int$int(d, h, w)
    }
    if (d === undefined && h === undefined && w === undefined) {
      return <any>this.getBrickMinMax$()
    }
    throw new Error('invalid overload')
  }
  /*
        setLowerResolutionTexture3D(gl : GL2, level : number) {
            if(level === 1 || level === 2) {
                let index : number = 0;
                let levelStep : number = level * 2;
                let w : number = (<number>Math.ceil((<any>Math).fround(<number>this.getWidth() / levelStep))|0);
                let h : number = (<number>Math.ceil((<any>Math).fround(<number>this.getHeight() / levelStep))|0);
                let d : number = (<number>Math.ceil((<any>Math).fround(<number>this.getDepth() / levelStep))|0);
                let lod : number[] = (s => { let a=[]; while(s-->0) a.push(0); return a; })(w * h * d);
                for(let i : number = 0; i < this.getDepth(); i += levelStep) {for(let j : number = 0; j < this.getHeight(); j += levelStep) {for(let k : number = 0; k < this.getWidth(); k += levelStep) {lod[index++] = this.getMean(i, j, k, levelStep);};};}
                let buffer : java.nio.ShortBuffer = java.nio.ShortBuffer.wrap(lod);
            }
        } */

  /* private */ getMean(si: number, sj: number, sk: number, step: number): number {
    let value = 0
    let count = 0
    const w: number = this.getWidth()
    const h: number = this.getHeight()
    const d: number = this.getDepth()
    const layerSize = w * h
    if (this.data && this.columns) {
      for (let i: number = si; i < d && i < si + step; i++) {
        {
          for (let j: number = sj; j < h && j < sj + step; j++) {
            {
              for (let k: number = sk; k < w && k < sk + step; k++) {
                {
                  value += this.data[i * layerSize + this.columns[j] + k]
                  count++
                }
              }
            }
          }
        }
      }
    }
    value /= count
    return (<number>Math.min(Math.max(value, this.range.getDataMin()), this.range.getDataMax())) | 0
  }

  /**
   * Compute LOD (Mean value) slice from input data
   *
   * @param {short[][]} data
   * - Input data slices
   * @param {number} s
   * - Starting slice
   * @param {number} e
   * - End slice
   * @param {number} step
   * - Step size for LOD
   * @param {short[]} out
   * - Output data slice
   */
  public fillLOD(data: number[][], s: number, e: number, step: number, out: number[]): void {
    if (this.columns) {
      let index = 0
      for (let i = 0; i < this.getHeight(); i += step) {
        for (let j = 0; j < this.getWidth(); j += step) {
          {
            let value = 0
            let count = 0
            for (let ii: number = s; ii <= e; ii++) {
              {
                for (let jj: number = i; jj < this.height && jj < i + step; jj++) {
                  {
                    for (let kk: number = j; kk < this.width && kk < j + step; kk++) {
                      {
                        value += data[ii][this.columns[jj] + kk]
                        count++
                      }
                    }
                  }
                }
              }
            }
            value /= count
            out[index++] = (<number>Math.min(Math.max(value, this.range.getDataMin()), this.range.getDataMax())) | 0
          }
        }
      }
    }
  }

  /**
   * Fill "min max bricks table" with a slice of data
   *
   * @param {short[]} dataIn
   * - Input data
   * @param {number} level
   * - Input level (location of slice)
   */
  public addLevelToBricks(dataIn: Uint16Array, offset: number, level: number) {
    let dS: number =
      (<number>(
        Math.floor((<any>Math).fround(Math.max((<any>Math).fround(level - 1.0), 0.0) / DicomVolume.BRICK_LENGTH))
      )) | 0
    dS = dS < 0 ? 0 : dS
    const dE: number =
      (<number>(
        Math.floor((<any>Math).fround(Math.max((<any>Math).fround(level + 1.0), 0.0) / DicomVolume.BRICK_LENGTH))
      )) | 0
    if (this.columns) {
      for (let d: number = dS; d <= dE && d < this.dBCount; d++) {
        {
          for (let h = 0; h < this.hBCount; h++) {
            {
              for (let w = 0; w < this.wBCount; w++) {
                {
                  let hS: number = h * DicomVolume.BRICK_LENGTH
                  let wS: number = w * DicomVolume.BRICK_LENGTH
                  hS = hS === 0 ? 0 : hS - 1
                  wS = wS === 0 ? 0 : wS - 1
                  let hE: number = (h + 1) * DicomVolume.BRICK_LENGTH
                  hE = hE >= this.height ? this.height : hE + 1
                  let wE: number = (w + 1) * DicomVolume.BRICK_LENGTH
                  wE = wE >= this.width ? this.width : wE + 1
                  let min: number = this.brickMinMax[d][h][w][0]
                  let max: number = this.brickMinMax[d][h][w][1]
                  for (let j: number = hS; j < hE; j++) {
                    {
                      for (let k: number = wS; k < wE; k++) {
                        {
                          const s: number = dataIn[offset + this.columns[j] + k]
                          min = min >= s ? s : min
                          max = max <= s ? s : max
                        }
                      }
                    }
                  }
                  this.brickMinMax[d][h][w][0] = min
                  this.brickMinMax[d][h][w][1] = max
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize "min max bricks table"
   */
  public initializeBricks() {
    this.wBCount = (<number>Math.ceil((<any>Math).fround(<number>this.width / DicomVolume.BRICK_LENGTH))) | 0
    this.hBCount = (<number>Math.ceil((<any>Math).fround(<number>this.height / DicomVolume.BRICK_LENGTH))) | 0
    this.dBCount = (<number>Math.ceil((<any>Math).fround(<number>this.imageCount / DicomVolume.BRICK_LENGTH))) | 0
    this.brickMinMax = <any>(function (dims) {
      const allocate = function (dims) {
        if (dims.length === 0) {
          return 0
        }
        const array: any[] = []
        for (let i = 0; i < dims[0]; i++) {
          array.push(allocate(dims.slice(1)))
        }
        return array
      }
      return allocate(dims)
    })([this.dBCount, this.hBCount, this.wBCount, 2])
    for (let i = 0; i < this.dBCount; i++) {
      for (let j = 0; j < this.hBCount; j++) {
        for (let k = 0; k < this.wBCount; k++) {
          {
            this.brickMinMax[i][j][k][0] = 32767
            this.brickMinMax[i][j][k][1] = -32768
          }
        }
      }
    }
  }

  public getPixelSpacing(): Vector3f {
    return this.pixelSpacing
  }

  public setPixelSpacing(pixelSpacing: Vector3f): void {
    this.pixelSpacing = pixelSpacing
  }

  public getBBLower(): Vector3f {
    return this.bBLower
  }

  public setBBLower(bBLower: Vector3f): void {
    this.bBLower = bBLower
  }

  public getBBUpper(): Vector3f {
    return this.bBUpper
  }

  public setBBUpper(bBUpper: Vector3f): void {
    this.bBUpper = bBUpper
  }
  /*
          public getData(): number[] {
              return this.data;
          }

          public getLevels(): number[] {
              return this.levels;
          } */

  public getColums(): number[] {
    return this.columns || []
  }

  public getIndexToVWorld(): Matrix4f {
    return this.transform
  }

  public setTransform(transform: Matrix4f): void {
    this.transform = transform
  }

  public getDiameter(): number {
    return this.diameter
  }

  public setDiamter(diameter: number): void {
    this.diameter = diameter
  }

  public getCenter(): Vector3f {
    return this.center
  }

  public setCenter(center: Vector3f): void {
    this.center = center
  }

  public getVoxel(x: number, y: number, z: number): number | undefined {
    return this.data && this.columns ? this.data[z * this.width * this.height + this.columns[y] + x] : undefined
  }

  public getOrientation(): number[] {
    return this.orientation || []
  }

  public setOrientation(orientation: number[]) {
    this.orientation = /* clone */ orientation.slice(0)
  }

  public set(data: DicomVolume) {
    this.range.set$com_dicomlab_jdent_data_Range(data.getRange())
    this.setMin(data.getMin())
    this.setMax(data.getMax())
    this.setVoxelSize(data.getVoxelSize())
    this.imageCount = data.getDepth()
    this.width = data.getWidth()
    this.height = data.getHeight()
  }

  public getBirth(): string {
    return this.birth
  }

  public getGender(): string {
    return this.gender
  }

  public getPatientName(): string {
    return this.patientName
  }

  public getHeight(): number {
    return this.height
  }

  public setHeight(height: number) {
    this.height = height
    //    this.firePropertyChanged$java_lang_String$java_lang_Object$java_lang_Object(com.dicomlab.jdent.Msg.dicomheight, null, height);
  }

  public getWidth(): number {
    return this.width
  }

  public setWidth(width: number) {
    this.width = width
    //      this.firePropertyChanged$java_lang_String$java_lang_Object$java_lang_Object(com.dicomlab.jdent.Msg.dicomwidth, null, width);
  }

  public getImageCount(): number {
    return this.imageCount
  }

  public setImageCount(iCount: number) {
    this.imageCount = iCount
    //          this.firePropertyChanged$java_lang_String$java_lang_Object$java_lang_Object(com.dicomlab.jdent.Msg.imagecount, null, iCount);
  }

  public getDepth(): number {
    return this.imageCount
  }

  public clear() {
    this.data = undefined
    this.columns = undefined
  }

  /**
   * Get bounding box of this volume.
   *
   * @return {com.dicomlab.jdent.data.BoundingBox} The bounding box of this volume.
   */
  public getBoundingBox(): BoundingBox {
    if (this.boundingBox == null) {
      this.boundingBox = new BoundingBox(this.bBLower, this.bBUpper)
    }
    return this.boundingBox
  }

  public getBrickMinMax$(): number[][][][] {
    return this.brickMinMax
  }

  public getHBCount(): number {
    return this.hBCount
  }

  public getWBCount(): number {
    return this.wBCount
  }

  public getDBCount(): number {
    return this.dBCount
  }

  public setBirth(birth: string) {
    this.birth = birth
  }

  public setGender(gender: string) {
    this.gender = gender
  }

  public setPatientName(patientName: string) {
    this.patientName = patientName
  }

  public refreshMinMaxRange() {
    let min: number = this.range.getMin()
    let max: number = this.range.getMax()
    let dMin = 32767
    let dMax = -32768

    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        // let buf = this.data[i];
        {
          //  for (let k = 0; k < buf.length; ++k) {
          const s: number = this.data[i]
          dMin = s < dMin ? s : dMin
          dMax = s > dMax ? s : dMax
          // }
        }
      }
    }
    min = Math.min(dMax, Math.max(min, dMin))
    max = Math.max(dMin, Math.min(max, dMax))
    this.setBricks()
    this.range.set$float$float$float$float$boolean(min, max, dMin, dMax, false)
    this.clampBricks()
  }
}
