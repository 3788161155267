import * as M from 'models'
import { CompleteGeometry } from 'models/case/CompleteGeometry'

export default (state: M.Assets = { geometries: {}, materials: {}, custom: {} }, action: any) => {
  switch (action.type) {
    case M.ASSET_ADD_GEOMETRY: {
      const rv = { ...state }
      rv.geometries = { ...state.geometries }
      rv.geometries[action.data.index] = action.data.geometry
      return rv
    }
    case M.ASSET_ADD_CUSTOM: {
      const rv = { ...state }
      rv.custom = { ...state.custom }
      rv.custom[action.data.index] = action.data.content
      return rv
    }
    case M.ASSET_ADD_GEOMETRIES: {
      const rv = { ...state }
      const g = action.data.geometry as CompleteGeometry
      rv.geometries = { ...state.geometries }
      rv.geometries[action.data.index] = g.visual
      if (g.collider) {
        rv.geometries[`${action.data.index}_collider`] = g.collider
      }
      if (g.intersection) {
        rv.geometries[`${action.data.index}_intersection`] = g.intersection
      }
      if (g.colliderIntersection) {
        rv.geometries[`${action.data.index}_colliderIntersection`] = g.colliderIntersection
      }
      return rv
    }
    case M.ASSET_ADD_MATERIAL: {
      const rv = { ...state }
      rv.materials = { ...state.materials }
      rv.materials[action.data.index] = action.data.material
      return rv
    }
    default:
      return state
  }
}
