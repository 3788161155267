import * as _Case from './Case'
import * as _Objects from './Objects'
import * as _Redo from './Redo'
import * as _Instruments from './Instruments'
import * as _Assets from './Assets'
import * as _VolumePanel from './VolumePanel'
import * as _Zoom from './Zoom'
import * as _IObjectsAction from './IObjectsAction'
import * as _ThemingActions from './ThemingActions'
import * as _InternalWindowMessageActions from './InternalWindowMessageActions'

export type ICaseAction = _Case.ICaseAction
export type IObjectsAction = _IObjectsAction.IObjectsAction
export type IInstrumentsAction = _Instruments.IInstrumentsAction
export type IRedoAction = _Redo.IRedoAction
export type IVolumePanelAction = _VolumePanel.IVolumePanelAction
export type IZoomAction = _Zoom.IZoomAction
export type IAssetsAction = _Assets.IAssetsAction
export type IThemingActions = _ThemingActions.IThemingActions
export type IInternalWindowMessageActions = _InternalWindowMessageActions.IInternalWindowMessageActions

export type IAllAction = ICaseAction &
  IObjectsAction &
  IRedoAction &
  IInstrumentsAction &
  IAssetsAction &
  IZoomAction &
  IThemingActions &
  IInternalWindowMessageActions

export const All = {
  ..._Case.impl,
  ..._Objects.impl,
  ..._Redo.impl,
  ..._Instruments.impl,
  ..._Assets.impl,
  ..._VolumePanel.impl,
  ..._Zoom.impl,
  ..._ThemingActions.impl,
  ..._InternalWindowMessageActions.impl,
}
export const Case = _Case.impl
export const Objects = _Objects.impl
export const Redo = _Redo.impl
export const Instruments = _Instruments.impl
export const Assets = _Assets.impl
export const VolumePanel = _VolumePanel.impl
export const Zoom = _Zoom.impl
export const ThemingActions = _ThemingActions.impl
export const InternalWindowMessageActions = _InternalWindowMessageActions.impl

// export default actions;
