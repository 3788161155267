export enum UploadType {
  Dicom = 'dicom',
  Stl = 'stl',
  Drc = 'drc',
  Image = 'image',
}

export class InternalWindowMessage {
  type?: UploadType
  req?: ArrayBufferLike;
  [x: string]: any
}
