import { Pass, DCLDRenderPipeline, RenderTarget, ITextureInput } from './Pipeline'
// import * as THREE from 'three'

export interface IOverrides {
  scene?: any
  camera?: any
  renderer?: any
}

export class SceneDraw extends Pass {
  scene?: THREE.Scene

  camera?: THREE.Camera

  layer?: number

  overrides?: IOverrides

  constructor(_scene: THREE.Scene, _camera?: THREE.Camera, output?: string, _layer?: number, overrides?: IOverrides) {
    super()
    this.scene = _scene
    this.camera = _camera
    this.layer = _layer
    this.output = output
    this.overrides = overrides
  }

  public _internalRender(pipeLine: DCLDRenderPipeline, _input: ITextureInput, _target: RenderTarget): void {
    let oldLayerMask
    if (this.layer !== undefined && this.camera) {
      oldLayerMask = this.camera.layers.mask
      this.camera.layers.set(this.layer)
    }
    if (this.scene && this.camera) {
      pipeLine.renderer.render(this.scene, this.camera)
    }

    if (oldLayerMask !== undefined && this.camera) {
      this.camera.layers.mask = oldLayerMask
    }
  }

  public render(pipeLine: DCLDRenderPipeline, input: ITextureInput, target: RenderTarget): void {
    const originalValues: IOverrides = {}
    for (const [k, v] of Object.entries(this.overrides || {})) {
      let sourceObject
      const ov = {}
      switch (k) {
        case 'scene':
          originalValues.scene = ov
          sourceObject = this.scene
          break
        case 'camera':
          originalValues.camera = ov
          sourceObject = this.camera
          break
        case 'renderer':
          originalValues.renderer = ov
          sourceObject = pipeLine.renderer
          break
        default:
          // console.log(`unknown override ${k} skipping it`)
          continue
      }
      for (const [k2, _v2] of Object.entries(v)) {
        ov[k2] = sourceObject[k2]
        sourceObject[k2] = v[k2]
      }
    }
    this._internalRender(pipeLine, input, target)
    for (const [k, _v] of Object.entries(originalValues || {})) {
      let targetObject
      let ov
      switch (k) {
        case 'scene':
          ov = originalValues.scene
          targetObject = this.scene
          break
        case 'camera':
          ov = originalValues.camera
          targetObject = this.camera
          break
        case 'renderer':
          ov = originalValues.renderer
          targetObject = pipeLine.renderer
          break
        default:
      }
      if (!ov) {
        continue
      }
      for (const [k2, v2] of Object.entries(ov)) {
        targetObject[k2] = v2
      }
    }
  }
}
export class MultiPassSceneDraw extends SceneDraw {
  public passes?: any[]

  constructor(_scene: THREE.Scene, _camera: THREE.Camera, passes?: any[], output?: string, overrides?: IOverrides) {
    super(_scene, _camera, output, undefined, overrides)
    this.passes = passes
  }

  public _internalRender(pipeLine: DCLDRenderPipeline, _input: ITextureInput, _target: RenderTarget): void {
    if (!this.camera || !this.scene || !this.passes) {
      return
    }
    const oldLayerMask = this.camera.layers.mask
    const oldOverride = this.scene.overrideMaterial
    const acc = pipeLine.renderer.autoClearColor
    const acd = pipeLine.renderer.autoClearDepth
    const acs = pipeLine.renderer.autoClearStencil
    const ac = pipeLine.renderer.autoClear
    const acbg = this.scene.background

    for (let k = 0; k < this.passes.length; k += 3) {
      const passLayer = this.passes[k]
      const materialOverride = this.passes[k + 1]
      const prerenderCallback = this.passes[k + 2]
      this.scene.overrideMaterial = materialOverride
      this.camera.layers.set(passLayer)
      if (prerenderCallback) {
        prerenderCallback(this)
      }
      pipeLine.renderer.render(this.scene, this.camera)
      //  pipeLine.renderer.state.buffers.depth.setClear(1);
      if (k === 0) {
        pipeLine.setAutoClearProps(false, false, false, false)
        this.scene.background = null
      }
      pipeLine.renderer.state.buffers.depth.setClear(1)
    }
    this.scene.background = acbg
    pipeLine.setAutoClearProps(ac, acc, acd, acs)

    this.scene.overrideMaterial = oldOverride
    this.camera.layers.mask = oldLayerMask
  }
}
