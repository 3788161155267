interface IObjMap<T> {
  [key: string]: T
}

export class Assets {
  geometries: IObjMap<THREE.BufferGeometry> = {}

  materials: IObjMap<THREE.Material> = {}

  custom: IObjMap<any> = {}
}
