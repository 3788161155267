import PropTypes from 'prop-types'

import clsx from 'clsx'
import { Stepper as Base, Step, StepLabel, StepConnector } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const useStepperStyles = makeStyles((theme) => ({
  bar: {
    flexGrow: 1,
    backgroundColor: 'transparent',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const useStepLabelStyles = makeStyles((theme) => ({
  label: {
    cursor: 'default',
    fontSize: 16,
    color: theme.palette.common.white,

    '&$active': {
      color: theme.palette.common.white,
    },

    '&$completed': {
      color: theme.palette.common.white,
    },
  },
  active: {},
  completed: {},
}))

const useStepIconStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    width: 36,
    height: 36,
    fontSize: 18,
    border: '2px solid white',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    cursor: 'default',
    fontWeight: 300,

    transition: 'all 0.1s',
  },

  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 500,

    '&:hover': {
      transform: ({ disabled }) => (disabled ? 'none' : 'scale(1.2)'),
    },
  },

  completed: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.success.main,
    fontWeight: 500,
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),

    '&:hover': {
      transform: ({ disabled }) => (disabled ? 'none' : 'scale(1.2)'),
    },
  },
}))

const CustomStepConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 18,
    left: 'calc(-50% + 34px)',
    right: 'calc(50% + 34px)',
  },
  completed: {
    '& $line': {
      opacity: '100%',
    },
  },
  active: {
    '& $line': {
      opacity: '100%',
    },
  },
  line: {
    borderColor: theme.palette.common.white,
    opacity: '25%',
    borderTopWidth: 2,
    borderRadius: 1,
  },
}))(StepConnector)

const CustomStepIcon = ({ active, completed, icon, error, ...rest }) => {
  const classes = useStepIconStyles({ error })

  return (
    <div
      className={`selected ${clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}`}
      {...rest}
    >
      {String(icon)}
    </div>
  )
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  selected: PropTypes.bool,
  icon: PropTypes.any,
  error: PropTypes.bool,
}

const Stepper = ({ steps, currentStepIdx, onStepClick, ...rest }) => {
  const classes = useStepperStyles()
  const stepLabelClasses = useStepLabelStyles()
  const { t } = useTranslation()
  const { pathname: path } = useLocation()

  if (!steps) {
    return null
  }

  const currentIndex =
    currentStepIdx !== undefined ? currentStepIdx : steps.findIndex(({ root }) => path.includes(root))

  return (
    <Base
      alternativeLabel
      connector={<CustomStepConnector />}
      activeStep={currentIndex}
      className={classes.bar}
      {...rest}
    >
      {steps.map((step, index) => (
        <Step key={step.label} disabled={step.disabled || !onStepClick}>
          <StepLabel
            StepIconComponent={CustomStepIcon}
            icon={index + 1}
            onClick={!step.disabled && onStepClick ? () => onStepClick(index) : null}
            active={index === currentIndex}
            completed={index < currentIndex}
            {...step}
            classes={stepLabelClasses}
          >
            {[t(step.label)]}
          </StepLabel>
        </Step>
      ))}
    </Base>
  )
}

Stepper.propTypes = {
  steps: PropTypes.array,
  currentStepIdx: PropTypes.number,
  onStepClick: PropTypes.func,
}

export default Stepper
