import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Box } from '@mui/material'

const StyledBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ even, center }) => (center ? 'center' : even ? 'space-evenly' : 'space-between')};
  align-items: center;
`

const HBox = ({ children, elements = [], separator, even, center, ...rest }) => {
  const renderElements = elements.reduce((acc, element, index) => {
    const s = Array.isArray(separator) ? separator[index] : separator
    const ret = s ? [...acc, element, s] : [...acc, element]
    return ret
  }, [])

  return (
    <StyledBox even={even} center={center} {...rest}>
      {children}
      {renderElements && renderElements.map((Element, index) => <Element key={index} />)}
    </StyledBox>
  )
}

HBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.array]),
  elements: PropTypes.arrayOf(PropTypes.func),
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  even: PropTypes.bool,
  center: PropTypes.bool,
}

export default HBox
