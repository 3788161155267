import * as THREE from 'three'
import { CopyShader } from '../shaders/CopyShader'
import { Pass, DCLDRenderPipeline, RenderTarget, ITextureInput } from './Pipeline'

export class QuadDraw extends Pass {
  camera: THREE.OrthographicCamera

  geometry: THREE.PlaneBufferGeometry

  uniforms: any

  material: THREE.ShaderMaterial

  mesh: THREE.Mesh

  scene: THREE.Scene

  clearBackground: boolean

  inputTexture?: THREE.Texture

  constructor(
    inputTexture: string | THREE.Texture | undefined,
    quadPos: THREE.Vector2,
    quadSize = 0.3,
    clearBackground = false,
    renderTarget?: string,
    depthTest = false,
    depthWrite = false,
    depthPos = 0
  ) {
    super()
    this.inputMap = {}
    if (inputTexture) {
      if (typeof inputTexture === 'string') {
        this.inputMap[inputTexture] = 'QUADCOLOR' // map input target into quadcolor named input
      } else {
        this.inputTexture = inputTexture
      }
    }
    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1)
    this.geometry = new THREE.PlaneBufferGeometry(2 * quadSize, 2 * quadSize)
    const shader = CopyShader
    this.uniforms = shader.uniforms
    this.clearBackground = clearBackground
    this.output = renderTarget
    this.material = new THREE.ShaderMaterial({
      defines: {},
      uniforms: this.uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
      depthTest,
      depthWrite,
    })
    this.mesh = new THREE.Mesh(this.geometry, this.material)
    this.mesh.position.set((quadPos.x * 2 - 1 + quadSize) * 1, (quadPos.y * 2 - 1 + quadSize) * 1, depthPos)
    this.scene = new THREE.Scene()
    this.scene.add(this.mesh)
  }

  public render(pipeLine: DCLDRenderPipeline, input: ITextureInput, _target: RenderTarget) {
    let inputColor: THREE.Texture
    if (this.inputTexture) {
      inputColor = this.inputTexture
    } else {
      inputColor = input.QUADCOLOR
    }
    this.uniforms.tDiffuse.value = inputColor
    const { autoClearDepth } = pipeLine.renderer
    const { autoClearColor } = pipeLine.renderer
    const { autoClearStencil } = pipeLine.renderer
    const { autoClear } = pipeLine.renderer
    pipeLine.setAutoClearProps(this.clearBackground, this.clearBackground, this.clearBackground, this.clearBackground)
    pipeLine.renderer.render(this.scene, this.camera)
    pipeLine.setAutoClearProps(autoClear, autoClearColor, autoClearDepth, autoClearStencil)
  }
}
