import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ContentWrap from './ContentWrap'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    color: 'white',
  },
  icon: {
    display: 'flex',
    position: 'relative',
    width: '75px',
    height: '75px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
    minHeight: '150px',
    border: '2px solid white',
    borderRadius: '50%',
    margin: '7px',
  },
  title: {
    fontSize: '40px',
    textAlign: 'center',
  },
  titleStrong: {
    fontSize: '48px',
    textDecoration: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  content: {
    fontSize: '16px',
    maxWidth: '647px',
  },
  actions: {
    margin: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
  },
}))

const InfoContentLayout = ({ children: content, icon: Icon, title, titleStrong, subTitle, actions, check }) => {
  const classes = useStyles()

  return (
    <ContentWrap className={classes.root}>
      <Box className={classes.iconContainer}>
        <Icon className={classes.icon} />
      </Box>
      <Typography classes={{ root: classes.title }}>{title}</Typography>
      <Typography classes={{ root: classes.titleStrong }}>{titleStrong}</Typography>
      <Typography classes={{ root: classes.title }}>{subTitle}</Typography>
      <ContentWrap className={classes.content}>{content}</ContentWrap>
      <Box className={classes.actions}>{actions}</Box>
      {check}
    </ContentWrap>
  )
}

InfoContentLayout.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  title: PropTypes.string,
  titleStrong: PropTypes.string,
  subTitle: PropTypes.string,
  actions: PropTypes.element,
  check: PropTypes.element,
}

export default InfoContentLayout
