import * as THREE from 'three'
import { UpdateObjectFromMatrix } from 'utils/Math'
import * as M from 'models'
import { IObjMap } from 'utils/IObjMap'
import * as C from './Consts'

export class CustomGL {
  public rootObj: THREE.Object3D

  private objects = new Map<string, [M.Custom, THREE.Mesh]>()

  constructor(public parent: THREE.Object3D) {
    this.rootObj = new THREE.Object3D()
    this.parent.add(this.rootObj)
  }

  update(
    prevProps: IObjMap<M.Custom>,
    props: IObjMap<M.Custom>,
    prevEditorConfig: M.EditorConfig,
    editorConfig: M.EditorConfig
  ): boolean {
    let dirty = false

    if (prevProps === props && prevEditorConfig === editorConfig) {
      return false
    }

    for (const [k, v] of Object.entries(props)) {
      //const uuid = k as string
      const id = v as M.Custom
      if (this.objects.has(k)) {
        const cached = this.objects.get(k)
        if (!cached || id === cached[0]) {
          continue
        }
        // megvaltozott
        const object = cached[1]
        // remove all nodes
        /* while (object.children.length) {
                    object.remove(object.children[0]);
                } */
        this.rootObj.remove(object)
      }
      if (id.visible) {
        //const type = id.name
        const c = new THREE.Color(id.color)
        if (id?.material?.intensity) {
          c.multiplyScalar(id?.material?.intensity)
        }
        const params = {
          vertexColors: id.hasVertexColor ? true : false,
          color: c,
          side: THREE.DoubleSide,
        }
        if (id.material?.metalness) {
          ;(params as any).metalness = id.material.metalness
        }
        if (id.material?.roughness) {
          ;(params as any).roughness = id.material.roughness
        }
        if (id.material?.emissive) {
          ;(params as any).emissive = id.material.emissive
        }
        if (id.material?.emissiveIntensity) {
          ;(params as any).emissiveIntensity = id.material.emissiveIntensity
        }
        const material = new THREE.MeshStandardMaterial(params)

        //            let geometry = assets.geometries['PLACEHOLDER_SPHERE3'];
        const geometry = id.customGeometry
        //Tooth.getOrCreateGeometry(assets, actions, type, id)
        const tooth = new THREE.Mesh(geometry.visual, material)

        tooth.layers.set(C.LAYER_SHAPE)
        UpdateObjectFromMatrix(tooth, id.transform)
        tooth.visible = editorConfig.toothVisible
        this.rootObj.add(tooth)
        this.objects.set(k, [id, tooth])
      }
      dirty = true
    }

    for (const [k, v] of this.objects) {
      if (props[k] === undefined) {
        // megvolt, de megszunt
        this.rootObj.remove(v[1])
        this.objects.delete(k)
        dirty = true
      }
    }
    return dirty
  }

  dispose() {
    this.parent.remove(this.rootObj)
  }
}
