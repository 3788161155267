import { InternalWindowMessage } from './InternalWindowMessage'
import { Assets } from './Asset'
import { CaseState } from './case'
import { VolumePanel } from './VolumePanel'
import { Zoom } from './Zoom'

export enum ELoadingState {
  NOT_LOADED = 1,
  LOAD_STARTED = 2,
  BASIC_LOADED = 3,
  VOLUME_LOADING = 4,
  FULLY_LOADED = 5,
}

export class Loading {
  public volume: number

  public static: number

  constructor(public state: ELoadingState) {
    this.volume = 0
    this.static = 0
  }

  static getProgress(l: Loading): number {
    const wVolume = 0.75
    const wStatic = 0.25

    const rv = l.volume * wVolume + l.static * wStatic
    return rv
  }
}

export class AppState {
  case: CaseState = new CaseState()

  assets: Assets = new Assets()

  volumePanel: VolumePanel = new VolumePanel()

  zoom: Zoom = new Zoom()
  lastInternalWindowMessage: InternalWindowMessage = new InternalWindowMessage()
}

export * from './case'
export * from './Asset'
export * from './VolumePanel'
export * from './Zoom'
export * from './InternalWindowMessage'
